import React, { useEffect, useState } from 'react'
import LenderDashboardMain from './LenderDashboardMain'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BackBtn from '../admin/BackBtn'
import { ToastContainer, toast } from 'react-toastify'

const DirectQuery = () => {
    const navigate = useNavigate()
    const { _id } = useParams()
    const userId = localStorage.getItem('lenderuserid')

    const [lenderId, setlenderId] = useState(userId)
    const [comment, setcomment] = useState('')
    const [case_no, setcase_no] = useState('')
    const [getLender, setGetLender] = useState([])
    const [singleCase, setSingleCase] = useState([])

    const [case_noList, setcase_noList] = useState('')



    let items = {
        case_no, lenderId, comment
    }

    const [nameError, setNameError] = useState('');



    // ================================
    // =======create New Query=========
    // ================================

    const createNewQuery = (e) => {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (nameError) {
            toast.error('Please fix the validation errors before submitting.', {
                autoClose: 3000
            });
            return;
        }

        var raw = JSON.stringify({
            "case_no" : singleCase?.case_no,
            "lenderId" : lenderId,
            "comment" : comment
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://bizfinn.co.in/createQuery", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.success) {
                    toast.success(result.msg, {
                        autoClose: 2000
                    })
                    setTimeout(() => {
                        navigate('/lender_query')
                    }, 2000)
                } else {
                    if (result.error) {
                        toast.error(result.error.msg, {
                            autoClose: 2000
                        })
                    }
                }
            })
            .catch(error => console.log('error', error));
    }


    // ================================
    // =======get case number=========
    // ================================

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://bizfinn.co.in/getCases", requestOptions)
            .then(response => response.json())
            .then(result => {
                setcase_noList(result?.data)
            })
            .catch(error => console.log('error', error));

    }, [])



    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "lenderId": userId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://bizfinn.co.in/getSingleLender", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setGetLender(result?.data)
            })
            .catch(error => console.log('error', error));
    }, [userId])


    // =======================================
    // ========Get Single case============
    // =======================================
    const GetSingleCase = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://bizfinn.co.in/getSingleCase/${_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setSingleCase(result?.data)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetSingleCase()
    }, [_id])




    // lender name validations
    const validateName = () => {
        const namePattern = /^[A-Za-z]{3,}$/;

        if (!lenderId.match(namePattern)) {
            setNameError('Please enter a valid lender name with at least 3 alphabetic characters.');
        } else {
            setNameError('');
        }
    };

    return (
        <>
            <LenderDashboardMain>
                <section className="side_content_main_box">
                    <div className="page_heading_div">
                        <div className="back_btn_filter_main">
                            <div className="back_btn_filter_inner">
                                <BackBtn />
                                <h2>Add New Query</h2>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />

                    <section className="add_new_lender_main_section">
                        <form onSubmit={createNewQuery}>
                            <div className="row">
                                <div className="col-lg-5 col-md-8 col-sm-12">

                                    <div className=''>
                                        <input
                                            type='text'
                                            className='form_input_box'
                                            placeholder='Lender Name'
                                            autoCorrect='off'
                                            autoComplete='off'
                                            value={singleCase?.case_no} 
                                            // value="{{singleCase?.case_no}} {{singleCase?.borrowerName}}"
                                            readOnly
                                        />
                                    </div>

                                    <textarea
                                        rows="4"
                                        cols="50"
                                        className="form_input_box Enter_Comment"
                                        placeholder="Enter Comment"
                                        value={comment}
                                        onChange={e => setcomment(e.target.value)}
                                    ></textarea>

                                    <div className="form_both_btn_div_main">
                                        <button type='submit' className="create_new">
                                            Create New
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </section>
            </LenderDashboardMain>
        </>
    )
}

export default DirectQuery
