import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'

const DirectQuery = () => {
    const navigate = useNavigate()
    const { _id } = useParams()
    console.log("ID is " ,_id)

    const [lenderId, setlenderId] = useState('')
    const [comment, setcomment] = useState('')
    const [case_no, setcase_no] = useState('')
    const [getLender, setGetLender] = useState([])
    const [singleCase, setSingleCase] = useState([])


    const [case_noList, setcase_noList] = useState('')



    // let items = {
    //     case_no, lenderId, comment
    // }

    const [nameError, setNameError] = useState('');



    // ================================
    // =======create New Query=========
    // ================================

    const createNewQuery = (e) => {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (nameError) {
            toast.error('Please fix the validation errors before submitting.', {
                autoClose: 3000
            });
            return;
        }

        var raw = JSON.stringify({
            "case_no" : singleCase?.case_no,
            "lenderId" : lenderId,
            "comment" : comment
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://bizfinn.co.in/createQuery", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.success) {
                    toast.success(result.msg, {
                        autoClose: 2000
                    })
                    setTimeout(() => {
                        navigate('/query')
                    }, 2000)
                } else {
                    if (result.error) {
                        toast.error(result.error.msg, {
                            autoClose: 2000
                        })
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
    // console.log("items", items)

    //   // ================================
    //   // =======get case number=========
    //   // ================================

    //   useEffect(() => {
    //     var requestOptions = {
    //       method: 'GET',
    //       redirect: 'follow'
    //     };

    //     fetch("https://bizfinn.co.in/getCases", requestOptions)
    //       .then(response => response.json())
    //       .then(result => {
    //         // console.log("set case_no List",result?.data)
    //         setcase_noList(result?.data)
    //       })
    //       .catch(error => console.log('error', error));

    //   }, [])

      // ================================
      // =======get Lender name=========
      // ================================

      useEffect(() => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        fetch("https://bizfinn.co.in/getLeanders", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result?.data)
            setGetLender(result?.data)
          })
          .catch(error => console.log('error', error));
      }, [])


    // =======================================
    // ========Get Single case============
    // =======================================
    const GetSingleCase = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://bizfinn.co.in/getSingleCase/${_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setSingleCase(result?.data)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        GetSingleCase()
        console.log("singleCase singleCase singleCase", singleCase)
    }, [_id])



    // lender name validations
    const validateName = () => {
        const namePattern = /^[A-Za-z]{3,}$/;

        if (!lenderId.match(namePattern)) {
            setNameError('Please enter a valid lender name with at least 3 alphabetic characters.');
        } else {
            setNameError('');
        }
    };

    return (
        <>
            <AdminDashboardMain>
                <section className="side_content_main_box">
                    <div className="page_heading_div">
                        <div className="back_btn_filter_main">
                            <div className="back_btn_filter_inner">
                                <BackBtn />
                                <h2>Add New Query</h2>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    <section className="add_new_lender_main_section">
                        <form onSubmit={createNewQuery}>
                            <div className="row">
                                <div className="col-lg-5 col-md-8 col-sm-12">
                                    <div className=''>
                                        <input
                                            type='text'
                                            className='form_input_box'
                                            placeholder='Lender Name'
                                            autoCorrect='off'
                                            autoComplete='off'
                                            value={singleCase?.case_no}
                                            readOnly
                                        />
                                    </div>

                                    {/* Lender name */}

                                    <div className="">
                                        <select
                                            name="borrower-name"
                                            className="form_input_box custom_select"
                                            value={lenderId} onChange={e => setlenderId(e.target.value)}
                                            required
                                        >
                                            <option value="">Select Lender</option>
                                            {getLender?.map(currEle => {
                                                return (
                                                    <>
                                                        <option value={currEle?._id}>{currEle?.name} </option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>



                                    <textarea
                                        rows="4"
                                        cols="50"
                                        className="form_input_box Enter_Comment"
                                        placeholder="Enter Comment"
                                        value={comment}
                                        onChange={e => setcomment(e.target.value)}
                                    ></textarea>

                                    <div className="form_both_btn_div_main">
                                        <button type='submit' className="create_new">
                                            Create New
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </section>
            </AdminDashboardMain>
        </>
    )
}

export default DirectQuery
