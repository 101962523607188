import React from 'react'
import Login from '../borrower/login/Login'

const BorrowerMain = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default BorrowerMain
