const { io } = require('socket.io-client')

const socket = io('https://bizfinn.co.in', {
  transports: ['websocket']
})


// const socket = io('http://localhost:8081', {
//   transports: ['websocket']
// })

console.log(socket, "sttaus")
socket.on('connect_error', () => {
  // revert to classic upgrade
  socket.io.opts.transports = ['polling', 'websocket']
})
export default socket