import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Filter from '../admin/Filter'
import BackBtn from '../admin/BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import dayjs from 'dayjs'
import Loader from '../admin/Loader'
import BorrowerDashboardMain from './BorrowerDashboardMain'

const Query = () => {
  const navigate = useNavigate()
  const borrowerId = localStorage.getItem('borroweruserid')
  const [isLoading, setIsLoading] = useState(false)
  const [queryList, setQueryList] = useState([])
  const [singlecase, setsinglecase] = useState([])


  // list of query
  const listofQuery = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    setIsLoading(true)
    fetch(`https://bizfinn.co.in/getQuery/${borrowerId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setQueryList(result.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      });
  }
  useEffect(() => {
    listofQuery()
    console.log('queryList ', queryList)
  }, [])


  const onDateChange=(fromDate , toDate)=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`https://bizfinn.co.in/getQuery/${borrowerId}?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setQueryList(result.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      });
  }



  return (
    <>
      <BorrowerDashboardMain>
        <section className='side_content_main_box'>
          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Query</h2>
              </div>
              <Filter onDateChange={onDateChange} />
            </div>
            {/* <div className='dashboard_add_new_btn'>
              <Link to='/lender_add_query' className=''>
                Create New
              </Link>
            </div> */}
          </div>


          <section className="tabs_main_section commaon_tabs_main_section">
            <Tabs
              defaultActiveKey="PendingList"
              id="uncontrolled-tab-example"
              className="commaon_tabs_main"
            >
              <Tab eventKey="PendingList" title="List of Pending">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Business Structure</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Status</th>
                          <th scope="col">Loan Ask</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {queryList ? (
                          queryList
                            .filter((currEle) => currEle?.status === 0)
                            .slice()
                            .reverse()
                            .map((currEle) => {
                              return (
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/borrower-query/borrower-query-details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                  </td>
                                  <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                  <td className="name">
                                    <Link to={`/borrower_profile`}> {currEle?.borrowerName}</Link>
                                  </td>
                                  <td>{currEle?.business_structure}</td>
                                  <td>{currEle?.borrowerTurnOver}</td>
                                  <td >{currEle?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                                    currEle?.status === 1 ? <span className="table_pending_btn table_approved_btn">Completed</span> : ""}</td>
                                  <td>{currEle?.loan_ask}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="6">No Data Found</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="CompletedList" title="List of Completed">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Business Structure</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Status</th>
                          <th scope="col">Loan Ask</th>
                        </tr>
                      </thead>

                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}
                        {queryList ? (
                          queryList
                            .filter((currEle) => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map((currEle) => {
                              return (
                                <tr key={currEle?._id}>
                                  <td className="name">
                                    <Link to={`/borrower-query-details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                  </td>
                                  <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                  <td className="name">
                                    <Link to={`/borrower_profile`}> {currEle?.borrowerName}</Link>
                                  </td>
                                  <td>{currEle?.business_structure}</td>
                                  <td>{currEle?.borrowerTurnOver}</td>
                                  <td >{currEle?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                                    currEle?.status === 1 ? <span className="table_pending_btn table_approved_btn">Completed</span> : ""}</td>
                                  <td>{currEle?.loan_ask}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="6">No Data Found</td>
                          </tr>
                        )}
                      </tbody>

                    </table>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </section>



        </section>
      </BorrowerDashboardMain>
    </>
  )
}

export default Query
