import React, { useEffect, useState } from 'react'
import LenderDashboardMain from './LenderDashboardMain'
import { Link, useNavigate } from 'react-router-dom'
import BackBtn from '../admin/BackBtn'
import { ToastContainer, toast } from 'react-toastify'

const AddNewQuery = () => {
  const navigate = useNavigate()
  const userId = localStorage.getItem('lenderuserid')

  const [lenderId, setlenderId] = useState(userId)
  const [comment, setcomment] = useState('')
  const [case_no, setcase_no] = useState('')
  const [getLender, setGetLender] = useState([])
  const [isSubmitDisabled, setisSubmitDisabled] = useState(false)


  const [case_noList, setcase_noList] = useState('')



  let items = {
    case_no, lenderId, comment
  }

  const [nameError, setNameError] = useState('');


  // ================================
  // =======Add remark=========
  // ================================
  const AddRemark = () => {
    console.log("remark added by Lender")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "commenterId": lenderId,
      "remark": comment,
      "type": "Lender"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/cases/${case_no}/comments`, requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }



  // ================================
  // =======create New Query=========
  // ================================

  const createNewQuery = (e) => {
    e.preventDefault();
    setisSubmitDisabled(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (nameError) {
      toast.error('Please fix the validation errors before submitting.', {
        autoClose: 3000
      });
      return;
    }

    var raw = JSON.stringify(items);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/createQuery", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          });
          setTimeout(() => {
            setisSubmitDisabled(false)
            navigate('/lender_query');
          }, 2000);
          AddRemark();
        } else {
          setisSubmitDisabled(false)
          // Display an error toast when the API call fails
          toast.error(result.msg, {
            autoClose: 2000
          });
        }
      })
      .catch(error => console.log('error', error));
  }



  // ================================
  // =======get case number=========
  // ================================

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getCases", requestOptions)
      .then(response => response.json())
      .then(result => {
        setcase_noList(result?.data)
      })
      .catch(error => console.log('error', error));

  }, [])



  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "lenderId": userId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleLender", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setGetLender(result?.data)
      })
      .catch(error => console.log('error', error));
  }, [userId])




  return (
    <>
      <LenderDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Add New Query</h2>
              </div>
            </div>
          </div>
          <ToastContainer />

          <section className="add_new_lender_main_section">
            <form onSubmit={createNewQuery}>
              <div className="row">
                <div className="col-lg-5 col-md-8 col-sm-12">
                  <div className="">
                    <select
                      name="case-no"
                      className="form_input_box custom_select"
                      value={case_no}
                      onChange={e => setcase_no(e.target.value)}
                      required
                    >
                      <option value="">Select Case Number</option>
                      {case_noList && case_noList?.map(currEle => {
                        return (
                          <>
                            <option value={currEle?.case_no}>{currEle?.case_no} ({currEle?.borrowerName})</option>
                          </>
                        )
                      })}

                    </select>
                  </div>


                  <textarea
                    rows="4"
                    cols="50"
                    className="form_input_box Enter_Comment"
                    placeholder="Enter Comment"
                    value={comment}
                    onChange={e => setcomment(e.target.value)}
                  ></textarea>

                  <div className="dashboard_add_new_btn mt-4">
                    {isSubmitDisabled == true ? (
                      <div className="form_both_btn_div_main">
                        <button type='submit' className="create_new" disabled>
                          Create New
                        </button>
                      </div>
                    ) : (
                      <div className="form_both_btn_div_main">
                        <button type='submit' className="create_new">
                          Create New
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </LenderDashboardMain>
    </>
  )
}

export default AddNewQuery
