import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import AdminDashboardMain from './AdminDashboardMain'
import { Link } from 'react-router-dom'
import Filter from './Filter'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import Loader from './Loader'
import search from '../assets/imgs/icosn/search.svg'

const Borrower = () => {
  const [show, setShow] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [selectedLenderId, setSelectedLenderId] = useState(null)

  const [borrowersList, setBorrowersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // borrower List
  const borrowersListAPI = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }
    setIsLoading(true)

    fetch('https://bizfinn.co.in/getBorrowers', requestOptions)
      .then(response => response.json())
      .then(result => {
        setBorrowersList(result)
        setIsLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    borrowersListAPI()
    console.log('borrowersList', borrowersList)
  }, [])

  // Open modal and set selected lender ID
  const openDeleteModal = borrowerId => {
    setSelectedLenderId(borrowerId)
    handleShow()
    console.log('hello', borrowerId)
  }
  // ============Delete Lender API================
  const DeleteLender = borrowerId => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    }

    fetch(
      `https://bizfinn.co.in/deleteBorrower/${borrowerId}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          const updatedBorrowerList = borrowersList.data.filter(
            lender => lender._id !== borrowerId
          )
          setBorrowersList({ ...borrowersList, data: updatedBorrowerList })
        }
      })
      .catch(error => console.log('error', error))
  }


  // =============================================
  // ============table data search================
  // =============================================
  const filteredData = borrowersList?.data
  ?.filter(currEle =>
    currEle.name && currEle.name.toLowerCase().includes(searchQuery?.toLowerCase() || '')
  )
  ?.slice()
  .reverse() || [];

  return (
    <>
      <AdminDashboardMain>
        <section className='side_content_main_box'>
          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Borrower</h2>
              </div>
              <Filter />
            </div>
            <div className='dashboard_add_new_btn'>
              <Link to='/add-borrower' className=''>
                Add New
              </Link>
            </div>
          </div>
          <ToastContainer />

          <section className='tabs_main_section commaon_tabs_main_section'>
            <div className='table_heading_search_box_div'>
              <h1 className='list_heading'>List of Borrower</h1>
              <div className="table_heading_search_box_div_inner">
                <img src={search} alt="search" className="img-fluid" />
                <input
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <section className='table_main_section lender_table'>

              <div className='table-responsive'>
                <table className='table  commaon_table'>
                  <thead className='table_head'>
                    <tr>
                      <th scope='col'>Borrower ID</th>
                      <th scope='col'>Borrower Name</th>
                      <th scope='col'>Channel Partner</th>
                      <th scope='col'>Cases Logged</th>
                      <th scope='col'>Cases Approved</th>
                      <th scope='col'>Cases Pending</th>
                      <th scope='col'>Action</th>
                    </tr>
                  </thead>
                  <tbody className=''>
                    {isLoading && (
                      <div className='pos_abs'>
                        <Loader />
                      </div>
                    )}
                    {filteredData.length === 0 ? (
                      <tr>
                        <td colSpan="7">Data not found</td>
                      </tr>
                    ) : (
                      filteredData.map(currEle => {
                        return (
                          <>
                            <tr key={currEle.borrower_id}>
                              <td>{currEle.borrower_id}</td>
                              <td className='name'>
                                <Link to={`/borrower-profile/${currEle._id}`}>
                                  {currEle.name}
                                </Link>
                              </td>
                              <td>{currEle.channel_partner}</td>
                              <td>{currEle.case_logged}</td>
                              <td>{currEle.case_approved}</td>
                              <td>{currEle.case_pending}</td>
                              <td>
                                <Link
                                  to={`/borrower-profile/${currEle._id}`}
                                  className='table_edit_delete_btn'
                                >
                                  Edit
                                </Link>
                                <Link
                                  to='#'
                                  onClick={() => openDeleteModal(currEle._id)}
                                  className='table_edit_delete_btn'
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

            </section>
          </section>
        </section>
      </AdminDashboardMain>

      {/*delete modal */}

      <Modal show={show} onHide={handleClose} className='modal_main'>
        <Modal.Body className='moadal_body'>
          <p> Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='table_edit_delete_btn' onClick={handleClose}>
            Close
          </button>
          <button
            className='table_edit_delete_btn'
            onClick={() => {
              DeleteLender(selectedLenderId)
              handleClose()
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Borrower
