import React, { useState } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const Filter = ({onDateChange}) => {
  const [dateValue, setDateValue] = useState('');

  const handleDateChange = (event, picker) => {
    // The selected date range can be accessed using the picker.startDate and picker.endDate properties.
    const selectedStartDate = picker.startDate;
    const selectedEndDate = picker.endDate;

    // Do something with the selected date range if needed.
    console.log(`${selectedStartDate.format('MM/DD/YYYY')} - ${selectedEndDate.format('MM/DD/YYYY')}`);

    // Update the state with the formatted date range string if required.
    setDateValue(`${selectedStartDate.format('MM/DD/YYYY')} - ${selectedEndDate.format('MM/DD/YYYY')}`);

    onDateChange(selectedStartDate.format('MM/DD/YYYY') , selectedEndDate.format('MM/DD/YYYY'))
  };

  return (
    <div className="date_filter_box">
      <DateRangePicker onApply={handleDateChange}>
        <input type="text" className="form-control" value={dateValue} />
      </DateRangePicker>
    </div>
  );
};

export default Filter;
