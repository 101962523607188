import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import { Link, useNavigate } from 'react-router-dom'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'

const AddNewQuery = () => {
  const navigate = useNavigate()

  const [lenderId, setlenderId] = useState('')
  const [comment, setcomment] = useState('')
  const [case_no, setcase_no] = useState('')
  const [getLender, setGetLender] = useState([])
  const [isSubmitDisabled, setisSubmitDisabled] = useState(false)


  const [case_noList, setcase_noList] = useState('')



  let items = {
    case_no, lenderId, comment
  }

  const [nameError, setNameError] = useState('');


  // ================================
  // =======Add remark=========
  // ================================
  const AddRemark = () => {
    console.log("remark added by admin")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "commenterId": lenderId,
      "remark": comment,
      "type": "Admin"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/cases/${case_no}/comments`, requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  // ================================
  // =======create New Query=========
  // ================================

  const createNewQuery = (e) => {
    e.preventDefault()
    setisSubmitDisabled(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (nameError) {
      toast.error('Please fix the validation errors before submitting.', {
        autoClose: 3000
      });
      return;
    }

    var raw = JSON.stringify(items);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/createQuery", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            setisSubmitDisabled(false)
            navigate('/query')
          }, 2000)
          AddRemark()
        } else {
          if (result.error) {
            setisSubmitDisabled(false)
            toast.error(result.error.msg, {
              autoClose: 2000
            })
          }
        }
      })
      .catch(error => console.log('error', error));
  }
  console.log("items", items)

  // ================================
  // =======get case number=========
  // ================================

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getCases", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log("set case_no List",result?.data)
        setcase_noList(result?.data)

      })
      .catch(error => console.log('error', error));

  }, [])

  // ================================
  // =======get Lender name=========
  // ================================

  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getLeanders", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result?.data)
        setGetLender(result?.data)
      })
      .catch(error => console.log('error', error));
  }, [])





  // lender name validations
  const validateName = () => {
    const namePattern = /^[A-Za-z]{3,}$/;

    if (!lenderId.match(namePattern)) {
      setNameError('Please enter a valid lender name with at least 3 alphabetic characters.');
    } else {
      setNameError('');
    }
  };

  return (
    <>
      <AdminDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Add New Query</h2>
              </div>
            </div>
          </div>
          <ToastContainer />

          <section className="add_new_lender_main_section">
            <form onSubmit={createNewQuery}>
              <div className="row">
                <div className="col-lg-5 col-md-8 col-sm-12">
                  <div className="">
                    <select
                      name="case-no"
                      className="form_input_box custom_select"
                      value={case_no}
                      onChange={e => setcase_no(e.target.value)}
                      required
                    >
                      <option value="">Select Case Number</option>
                      {case_noList && case_noList?.map(currEle => {
                        return (
                          <>
                            {/* <option value={currEle?.case_no}>{currEle?.case_no}</option> */}
                            <option value={currEle?.case_no}>{currEle?.case_no} ({currEle?.borrowerName})</option>
                          </>
                        )
                      })}

                    </select>


                    {/* Lender name */}

                    <div className="">
                      <select
                        name="borrower-name"
                        className="form_input_box custom_select"
                        value={lenderId} onChange={e => setlenderId(e.target.value)}
                        required
                      >
                        <option value="">Select Lender</option>
                        {getLender?.map(currEle => {
                          return (
                            <>
                              <option value={currEle?._id}>{currEle?.name} </option>
                            </>
                          )
                        })}
                      </select>
                    </div>
                  </div>


                  <textarea
                    rows="4"
                    cols="50"
                    className="form_input_box Enter_Comment"
                    placeholder="Enter Comment"
                    value={comment}
                    onChange={e => setcomment(e.target.value)}
                  ></textarea>

                  <div className="dashboard_add_new_btn mt-4">
                    {isSubmitDisabled == true ? (
                      <div className="form_both_btn_div_main">
                        <button type='submit' className="create_new" disabled>
                          Create New
                        </button>
                      </div>
                    ) : (
                      <div className="form_both_btn_div_main">
                        <button type='submit' className="create_new">
                          Create New
                        </button>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </form>
          </section>
        </section>
      </AdminDashboardMain>
    </>
  )
}

export default AddNewQuery
