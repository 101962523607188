import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import cross from '../assets/imgs/icosn/cross.svg'
import upload from '../assets/imgs/icosn/upload.svg'
import download from '../assets/imgs/icosn/download.png'
import check from '../assets/imgs/icosn/check.svg'
import Filter from '../admin/Filter'
import BackBtn from '../admin/BackBtn'
import dayjs from 'dayjs'
import LenderDashboardMain from './LenderDashboardMain'
import { ToastContainer, toast } from 'react-toastify'
import socket from "../Socket";
const QueryDetails = () => {
  const navigate = useNavigate()
  const userId = localStorage.getItem('lenderuserid')

  const { _id } = useParams()
  const [singleQuery, setSingleQuery] = useState('')
  const [singleCase, setSingleCase] = useState([])
  const [comment, setcomment] = useState('')
  const [lenderId, setlenderId] = useState(userId)
  const [singleBorrower, setSingleBorrower] = useState([])
  const [isSendTrue, setisSendTrue] = useState(true)

  const [case_no, setcase_no] = useState('')




  // ====================================
  // ========Get Single Query============
  // ====================================

  const GetSingleQuery = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleQuery/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setSingleQuery(result?.data)
      })
      .catch(error => console.log('error', error));
  }
  // =======================================
  // ========Get Single Borrower============
  // =======================================
  const getSingleBorrower = () => {
    if (singleCase && singleCase.borrower) {
      const requestBody = {
        borrowerId: singleCase.borrower,
      };

      fetch("https://bizfinn.co.in/getSingleBorrower", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then(response => response.json())
        .then(result => {
          setSingleBorrower(result?.data);
        })
        .catch(error => console.error('Error fetching single borrower:', error));
    }
  }
  useEffect(() => {
    if (singleCase) {
      getSingleBorrower();
    }
  }, [singleCase]);

  // ====================================
  // ========Get Single Case============
  // ====================================
  const GetSingleCase = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleCase/${singleQuery?.case}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setSingleCase(result?.data)
        console.log("singleCase", singleCase?.lenders)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleQuery();
  }, [_id]);

  useEffect(() => {
    GetSingleCase(singleQuery?.case);
  }, [singleQuery]);


  // ====================================
  // ======== Query Approved============
  // ====================================
  const QueryApproved = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/queryStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result) {
          toast.success(result?.message, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/lender_query')
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }

  // ====================================
  // ========delete Single Query============
  // ====================================
  const DeleteQuery = () => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/deleteQuery/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.success) {
          toast.success(result?.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/lender_query')
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }

  console.log("the case nu is", singleQuery?.case)
  console.log("lender id", lenderId)
  // ================================
  // =======Add remark=========
  // ================================
  const AddRemark = () => {
    setisSendTrue(false)
    console.log("remark added by Lender")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "commenterId": lenderId,
      "remark": comment,
      "type": "Lender"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/query/${singleQuery?._id}/comments`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setTimeout(() => {
          window.location.reload();
        }, 100)
      })
      .catch(error => console.log('error', error));
  }



  // chat
  const history = useNavigate();
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [LenderId, setLenderId] = useState("");
  const [admindata, setAdminData] = useState(null);

  useEffect(() => {
    const lenderId = localStorage.getItem("lenderuserid");
    const adminData = localStorage.getItem("userid");

    if (lenderId) {
      setLenderId(lenderId);
    }

    if (adminData) {
      setAdminData(adminData);
    }
  }, []);

  const sendMessage = (contentType, content) => {
    // alert("Message sent");

    const newMessage = {
      senderId: LenderId,
      receiverId: admindata,
      roomId: "",
    };

    //socket.emit("getMessages", newMessage);
    if (newMessage.roomId == "") {
      history(`/lender-chat/${newMessage.senderId}/${newMessage.receiverId}`);
    } else {
      history(
        `/lender-chat/${newMessage.roomId}/${newMessage.senderId}/${newMessage.receiverId}`
      );
    }

    // console.log(newMessage.roomId, newMessage.senderId, newMessage.receiverId);
  };

  return (
    <>
      <LenderDashboardMain>
        <div className='full_width_bg_main'>
          <section className='side_content_main_box'>
            <div className='page_heading_div'>
              <div className='back_btn_filter_main'>
                <BackBtn />
                <h2>Query</h2>
              </div>
            </div>
            <ToastContainer />

            <section className=''>
              <section className='table_main_section'>
                <div className='table-responsive'>
                  <table className='table   commaon_table'>
                    <thead className='table_head'>
                      <tr>
                        <th scope="col">Case No</th>
                        <th scope="col">Date</th>
                        <th scope="col">Borrower Name</th>
                        <th scope="col">Business Structure</th>
                        <th scope="col">Turnover</th>
                        <th scope="col">Status</th>
                        <th scope="col">Loan Ask</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='name'>
                          <Link to='#'> {singleQuery?.case_no} </Link>
                        </td>
                        <td>{dayjs(singleQuery?.createdAt).format(
                          ' DD-MM-YYYY'
                        )}</td>
                        <td className='name'>
                          <Link to='#'> {singleQuery?.borrowerName}</Link>
                        </td>
                        <td>{singleQuery?.business_structure}</td>
                        <td>{singleQuery?.borrowerTurnOver}</td>

                        <td >{singleQuery?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                          singleQuery?.status === 1 ? <span className="table_pending_btn table_approved_btn">Completed</span> : ""}</td>
                        <td>{singleQuery?.loan_ask}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>

            <section className='case_detail_content_main_section'>
              <div className='row'>
                <div className='col-xl-7 col-lg-12 col-md-12 col-sm-12 '>
                  <div className='two_col_right_gap '>
                    <div className='case_query_reject_btn_main_flex'>
                      <div>
                        <div className='case_detail_single_detail'>
                          <h2> {singleQuery?.borrowerName} is looking for</h2>
                          <p>Secured Term Loan</p>
                        </div>
                        <div className='case_detail_single_detail'>
                          <h2>Amount Required (in INR)?</h2>
                          <p>{singleQuery?.loan_ask}</p>
                        </div>
                        <div className='case_detail_single_detail'>
                          <h2>Remarks</h2>
                          <p>{singleQuery?.comment}</p>
                        </div>
                      </div>

                      {/* <div className='case_query_reject_btn'>
                        <Link to='/query'>Edit</Link>
                        <Link to='/cases'>Cancel</Link>
                      </div> */}
                    </div>

                    <div className='query_details_steps_main'>
                      {singleQuery?.comments?.slice()
                        .reverse().map((currEle) => {
                          return (
                            <div className='query_details_steps_single'>
                              <div className='steps_dots_main steps_dot_active'>
                                <span></span>
                              </div>
                              <div className='query_details_steps_inner_main'>
                                <div>
                                  <h4>{currEle?.type}</h4>
                                  <h5>{currEle?.remark}</h5>
                                </div>
                                <p>{dayjs(currEle?.createdAt).format(
                                  ' DD-MM-YYYY'
                                )}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                    {/* Lender Comment */}

                    {singleQuery.status != 1 ? (
                      <div className="case_detail_single_detail mt-5">
                        <h2>Lender Comment</h2>

                        <div className="finanicial_details_single mt-2">
                          <textarea
                            rows="4"
                            cols="50"
                            className="form_input_box Enter_Comment"
                            placeholder="Enter Comment"
                            value={comment}
                            onChange={e => setcomment(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    ) : (<> </>)}


                    {singleQuery.extraDocs?.map((doc, index) => (
                      <div className="finanicial_details_single">
                        <div className="upload-btn-wrapper">
                          <a href={doc.url} download="file_name.extension" target='_blank'>
                            <button className="file_upload_btn form_input_box">
                              <span>
                                {doc.url.substring(doc.url.lastIndexOf('/') + 11)}
                              </span>
                              <img
                                src={download}
                                alt="upload"
                                className="img-fluid"
                              />
                            </button>
                          </a>
                          {/* <input type="file" className="form_input_box" readOnly /> */}
                        </div>

                      </div>
                    ))}


                    <div className='query_details_Loan_status_div'>
                      <h3>
                        Status :<span>  {singleQuery?.status === 0 ? <span className="">Pending</span> :
                          singleQuery?.status === 1 ? <span style={{ color: "white" }} className=" table_approved_btn">Completed</span> : ""}</span>
                      </h3>
                    </div>

                    {singleQuery.status != 1 ? (
                      <div className='loan_approve_message_btn'>
                        <div className='login_register_btn_div'>

                          {isSendTrue ? (
                            <Link
                              to='#'
                              onClick={AddRemark}
                              className='blue_btn login_register_btn'
                            >
                            Send
                            </Link>

                          ) : (
                            <Link
                              to='#'
                              className='blue_btn login_register_btn'
                            >
                              <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </Link>
                          )}

                          <button
                            onClick={sendMessage}
                            className='login_register_btn register_btn simple_btn_hover'
                          >
                            Message
                          </button>
                        </div>
                      </div>
                    ) : (<></>)}


                  </div>
                </div>

                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 profile_details_main_section">
                  <h3>
                    Financial Details <span> (Upload when month change)</span>
                  </h3>
                  <div>
                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.financial_stat?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Audited Financial statements for last 3 years
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.gst_filling?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download GST Filing for last 12 months</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.bank_statment_24?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download Bank Statement of last 12 months</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box"  /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.provisional_sheet?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download provisional balance sheet for the current
                              year
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.itr_acknowledgement?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download ITR acknowledgement form for last 2 years
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.debt_service?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Debt serviced during the current FY
                              (Principal + interest repayment during the year)
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.mis_additional?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>Download MIS</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </LenderDashboardMain>
    </>
  )
}

export default QueryDetails
