import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Filter from './Filter'
import back_btn from '../assets/imgs/icosn/back-btn.svg'
const BackBtn = () => {
  const navigate = useNavigate()

  return (
    <div>
      <Link to="#" onClick={() => navigate(-1)}>
        <img src={back_btn} className="img-fluid" />
      </Link>
    </div>
  )
}

export default BackBtn
