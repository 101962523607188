// import React, { useState, useEffect,useRef  } from "react";
// import "../Chat/chat.css";
// import { RxHamburgerMenu } from "react-icons/rx";
// import { CiMenuBurger, CiImageOn } from "react-icons/ci";

// import { IoMdClose } from "react-icons/io";
// import search_icon from "../../assets/imgs/icosn/chat-search.svg";
// import user1 from "../../assets/imgs/icosn/user1.png";
// import user2 from "../../assets/imgs/icosn/user2.png";
// import send from "../../assets/imgs/icosn/send-msg.svg";
// import chatstart from "../../assets/imgs/icosn/chat.gif";

// import attachment from "../../assets/imgs/icosn/attachment.svg";
// import AdminDashboardMain from "../AdminDashboardMain";
// import BackBtn from "../BackBtn";

// import socket from "../../Socket";


// const Chat = () => {

//   const messageContainerRef = useRef(null);

//   // Function to scroll to the bottom of the message container
//   const scrollToBottom = () => {
//     if (messageContainerRef.current) {
//       messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
//     }
//   };

//   const [isActive, setIsActive] = useState(false); 

//   const toggleClass = () => {
//     setIsActive(!isActive); // Toggle the state value
//   };

//   // CHAT SECTION START

//   const [receiver, setReceiver] = useState(null);
//   const [currentMessage, setCurrentMessage] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [adminId, setAdminId] = useState("");
//   const [user, setUser] = useState();
//   const [fileLink, setFileLink] = useState(null);

//   // Socket connection
//   const sendMessage = (contentType, content) => {
//     const newMessage = {
//       content: content,
//       contentType: contentType,
//       senderId: adminId,
//       receiverId: receiver?._id,
//     };
//     socket.emit("createMessage", newMessage);
//     setCurrentMessage("");
//     setFileLink(null);
//   };

//   const handleSendButtonClick = (type) => {
//     // alert()
//     if (type == 1) {
//       console.log(currentMessage);
//       if (currentMessage.trim() !== "") {
//         sendMessage(1, currentMessage);
//       }
//     } else if (type == 2) {
//       console.log("send");
//       sendMessage(2, fileLink);
//     }
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     var formdata = new FormData();
//     formdata.append("userId", adminId);
//     formdata.append("file", file);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch("https://bizfinn.co.in/uploadfile", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result) {

//           const imageURL = result && result.fileUrl;
//           sendMessage(2, imageURL);
//         }
//       })
//       .catch((error) => ("error: ", error));
//   };

//   const receivId = (userdata) => {
//     // console.log(userdata)
//     setMessages([]);
//     if (userdata.participants[0]?._id == adminId) {
//       setReceiver(userdata.participants[1]);
//     } else if (userdata.participants[1]?._id == adminId) {
//       setReceiver(userdata.participants[0]);
//     }
//     const room = {
//       roomId: userdata?.roomId,
//     };
//     socket.emit("getMessages", room);
//   };

//   useEffect(() => {
//     let AdminId = localStorage.getItem("userid");
//     if (AdminId) {
//       setAdminId(AdminId);
//     }
//     const useid = {
//       userId: localStorage.getItem("userid"),
//     };


//     socket.emit("getChatRooms", useid);

//     socket.on("listOfRooms", (userList) => {
//       setUser(userList?.data);
//       // console.log(userList)
//     });

//     socket.on("getMessagesOfRoom", (ALLMessage) => {
//       setMessages(ALLMessage?.data);
//       // console.log(ALLMessage);
//     });
//   }, []);
//   // ...

//   // formate date
//   function formatDate(dateString) {
//     const options = {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//       hour: "2-digit",
//       minute: "2-digit",
//     };
//     return new Date(dateString).toLocaleString("en-US", options);
//   }
//   function formatTime(dateString) {
//     const options = {
//       hour: "2-digit",
//       minute: "2-digit",
//     };
//     return new Date(dateString).toLocaleTimeString("en-US", options);
//   }


//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]); 


//   return (
//     <AdminDashboardMain>
//       <section className="side_content_main_box">
//         <div className="page_heading_div">
//           <div className="back_btn_filter_main">
//             <div className="back_btn_filter_inner">
//               <BackBtn />
//               <h2>Messages</h2>
//             </div>
//           </div>
//         </div>

//         <section className="dashboard_boxes_main_section">
//           <div className="">
//             <div className="">
//               <div className="">
//                 <section className="chat_main_section_main">
//                   <div className="chat_main_section">
//                     <div
//                       className={`chat_side_list_div custom_scroll ${
//                         isActive ? "small_box" : ""
//                       }`}
//                     >
//                       <div className="">
//                         <div className="chat_box_search">
//                           <img
//                             src={search_icon}
//                             alt="img"
//                             className="img-fluid"
//                           />
//                           <input type="search" placeholder="Search ..." />
//                         </div>
//                         {user &&
//                           user.map((item) => {
//                             // {console.log(item, "data")}
//                             return (
//                               item &&
//                               item.participants &&
//                               item.participants.map((data) => {
//                                 // console.log(data);
//                                 if (
//                                   data?.userType === 2 ||
//                                   data?.userType === 3
//                                 ) {
//                                   return (
//                                     <div
//                                       className="user_single_chat_list"
//                                       onClick={() => {
//                                         receivId(item);
//                                       }}
//                                       key={data._id}
//                                     >
//                                       <div className="user_single_chat_list_inner">
//                                         <img
//                                           src={user1}
//                                           alt="img"
//                                           className="img-fluid"
//                                         />
//                                         <div>
//                                           <h3>{data.name}</h3>

//                                           <h4>Last seen</h4>
//                                         </div>
//                                       </div>
//                                       <h4 className="msg_number">
//                                         {formatTime(data.createdAt)}
//                                       </h4>
//                                     </div>
//                                   );
//                                 }
//                                 return null;
//                               })
//                             );
//                           })}

//                         {/* <div className="user_single_chat_list">
//                           <div className="user_single_chat_list_inner">
//                             <img src={user2} alt="img" className="img-fluid" />
//                             <div>
//                               <h3>Sanjeev Mehta ( Borrower)</h3>
//                               <h4 className="active">Active </h4>
//                             </div>
//                           </div>
//                           <h4 className="msg_number">4:40 PM</h4>
//                         </div> */}
//                       </div>
//                     </div>
//                     <div
//                       className={`chat_msg_side ${isActive ? "big_box" : ""}`}
//                     >
//                       {receiver != null ? (
//                         <div className="chat_msg_side_main ">
//                           <div className="chat_container_top_bar">
//                             <div className="d-flex">
//                               {!isActive ? (
//                                 <button onClick={toggleClass}>
//                                   <RxHamburgerMenu className="icon" />
//                                 </button>
//                               ) : (
//                                 <button onClick={toggleClass}>
//                                   <IoMdClose className="icon" />
//                                 </button>
//                               )}

//                               <div className="chat_side_container_main_user">
//                                 <div className="user_single_chat_list_inner">
//                                   <img
//                                     src={user1}
//                                     alt="img"
//                                     className="img-fluid"
//                                   />
//                                   <div>
//                                     <h3>
//                                       {receiver?.name}
//                                       {`(${
//                                         receiver?.userType == 1
//                                           ? "admin"
//                                           : receiver?.userType == 2
//                                           ? "lender"
//                                           : "borrower"
//                                       })`}
//                                     </h3>
//                                     <h4 className="active">Active</h4>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="chat_msg_main_div custom_scroll" ref={messageContainerRef}>
//                             {messages.map((item) => {
//                               const isUserMessage = item.senderId === adminId;
//                               const isReceiverMessage =
//                                 item.receiverId === adminId;

//                               return (
//                                 <div
//                                   className={`chat_msg ${
//                                     isUserMessage
//                                       ? "left_side_msg"
//                                       : isReceiverMessage
//                                       ? "right_side_msg"
//                                       : ""
//                                   }`}
//                                   key={item.id}
//                                 >
//                                   {item.contentType == 1 ? (
//                                     <p>{item.content}</p>
//                                   ) : (
//                                     <img
//                                       src={item.content}
//                                       className="img-fluid"
//                                     />
//                                   )}

//                                   <span className="msg_time_date">
//                                     {formatDate(item.createdAt)}
//                                   </span>
//                                   <img src={item.media} className="img-fluid" />
//                                 </div>
//                               );
//                             })}


//                           </div>

//                           <div className="msg_container_footer">
//                             <div className="msg_container_footer_inner">
//                               <div className="msg_type_input_box">
//                                 <input
//                                   onChange={(e) =>
//                                     setCurrentMessage(e.target.value)
//                                   }
//                                   value={currentMessage}
//                                   type="text"
//                                   placeholder="Type message here..."
//                                 />
//                                 <div className="attachment_file_btn">
//                                   <label htmlFor="fileInput">
//                                     <img
//                                       src={attachment}
//                                       alt="send"
//                                       className="img-fluid"
//                                     />
//                                   </label>
//                                   <input
//                                     type="file"
//                                     id="fileInput"
//                                     accept="image/*,.pdf"
//                                     onChange={handleImageChange}
//                                     style={{ display: "none" }}
//                                   />
//                                 </div>
//                               </div>
//                               <div className="send_btn">
//                                 <button
//                                   onClick={() => {
//                                     handleSendButtonClick(1);
//                                   }}
//                                 >
//                                   <img
//                                     src={send}
//                                     alt="send"
//                                     className="img-fluid"
//                                   />
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                           {/* <div className="chat_msg left_side_msg">
//                             <img src={item.content} alt="image" />

//                             <span className="msg_time_date">
//                               {formatDate(item.createdAt)}
//                             </span>
//                           </div> */}
//                         </div>
//                       ) : (
//                         <div className="char_starting_screen">
//                           <img
//                             src={chatstart}
//                             className="img-fluid"
//                             alt="chat"
//                           />
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </section>
//               </div>
//             </div>
//           </div>
//         </section>
//       </section>
//     </AdminDashboardMain>
//   );
// };

// export default Chat;





import React, { useState, useEffect } from "react";
import "../Chat/chat.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiMenuBurger, CiImageOn } from "react-icons/ci";

import { IoMdClose } from "react-icons/io";
import search_icon from "../../assets/imgs/icosn/chat-search.svg";
import user1 from "../../assets/imgs/icosn/user1.png";
import user2 from "../../assets/imgs/icosn/user2.png";
import send from "../../assets/imgs/icosn/send-msg.svg";
import chatstart from "../../assets/imgs/icosn/chat.gif";

import attachment from "../../assets/imgs/icosn/attachment.svg";
import AdminDashboardMain from "../AdminDashboardMain";
import BackBtn from "../BackBtn";

import socket from "../../Socket";


const Chat = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleClass = () => {
    setIsActive(!isActive); // Toggle the state value
  };

  // CHAT SECTION START

  const [receiver, setReceiver] = useState(null);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [adminId, setAdminId] = useState("");
  const [user, setUser] = useState();
  const [fileLink, setFileLink] = useState(null);
  const [chatRoomId, setchatRoomId] = useState(null)

  // Socket connection
  const sendMessage = (contentType, content) => {
    const newMessage = {
      content: content,
      contentType: contentType,
      senderId: adminId,
      receiverId: receiver?._id,
      roomId: ""
    };
    socket.emit("createMessage", newMessage);
    setCurrentMessage("");
    setFileLink(null);
  };

  const handleSendButtonClick = (type) => {
    // alert()
    if (type == 1) {
      console.log(currentMessage);
      if (currentMessage.trim() !== "") {
        sendMessage(1, currentMessage);
      }
    } else if (type == 2) {
      console.log("send");
      sendMessage(2, fileLink);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("userId", adminId);
    formdata.append("file", file);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {

          const imageURL = result && result.fileUrl;
          sendMessage(2, imageURL);
        }
      })
      .catch((error) => ("error: ", error));
  };

  const receivId = (userdata) => {
    console.log(userdata)
    setMessages([]);
    let room = {
      roomId: userdata?.roomId,
      name: "admin"
    };
    if (userdata.participants[0]?._id == adminId) {
      setReceiver(userdata.participants[1]);
      room = {
        roomId: userdata?.roomId,
        receiverId: userdata.participants[0]?._id
      };
    } else if (userdata.participants[1]?._id == adminId) {
      setReceiver(userdata.participants[0]);
      room = {
        roomId: userdata?.roomId,
        receiverId: userdata.participants[1]?._id
      };
    }
    console.log("getMessages", room);
    setchatRoomId(userdata?.roomId)
    localStorage.setItem('chatRoomId' , userdata?.roomId)
    if (!!room.receiverId) socket.emit("getMessages", room);
  };

  useEffect(() => {

    let AdminId = localStorage.getItem("userid");
    socket.emit("joinUser", { _id: AdminId });
    if (AdminId) {
      setAdminId(AdminId);
    }
    const useid = {
      userId: localStorage.getItem("userid"),
    };

    console.log(useid)
    socket.emit("getChatRooms", useid);

    socket.on("listOfRooms", (userList) => {
      setUser(userList?.data);
      console.log(userList)
    });

    socket.on("getMessagesOfRoom", (ALLMessage) => {
      let chatRoomId = localStorage.getItem('chatRoomId')
      if (ALLMessage?.data.some(item =>
        item.roomId == chatRoomId
      )) {
        setMessages(ALLMessage?.data);
      }
      console.log(ALLMessage , chatRoomId);
    });
  }, []);
  // ...

  // formate date
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  }
  function formatTime(dateString) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("en-US", options);
  }


  // ACTIVE STATUS API
  const [ActiveAdmin, setActiveAdmin] = useState()
  const ActiveUserId = localStorage.getItem("userid")
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "active": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/setActiveStatus/${ActiveUserId}`, requestOptions)
      .then(response => response.json())
      .then(result => setActiveAdmin(result.result.active))


      .catch(error => console.log('error', error));
  }, [])

  return (
    <AdminDashboardMain>
      <section className="side_content_main_box">
        <div className="page_heading_div">
          <div className="back_btn_filter_main">
            <div className="back_btn_filter_inner">
              <BackBtn />
              <h2>Messages</h2>
            </div>
          </div>
        </div>

        <section className="dashboard_boxes_main_section">
          <div className="">
            <div className="">
              <div className="">
                <section className="chat_main_section_main">
                  <div className="chat_main_section">
                    <div
                      className={`chat_side_list_div custom_scroll ${isActive ? "small_box" : ""
                        }`}
                    >
                      <div className="">
                        <div className="chat_box_search">
                          <img
                            src={search_icon}
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="search" placeholder="Search ..." />
                        </div>
                        {user &&
                          user.map((item) => {
                            // {console.log(item, "data")}
                            return (
                              item &&
                              item.participants &&
                              item.participants.map((data) => {
                                // console.log(data);
                                if (
                                  data?.userType === 2 ||
                                  data?.userType === 3
                                ) {
                                  return (
                                    <div
                                      className="user_single_chat_list"
                                      onClick={() => {
                                        receivId(item);
                                      }}
                                      key={data._id}
                                    >
                                      <div className="user_single_chat_list_inner">
                                        <img
                                          src={user1}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <div>
                                          <h3>{data.name}</h3>

                                          <h4 style={{ color: "#21ba62" }}>{ActiveAdmin !== 1 ? "Active" : "Offline"}</h4>
                                        </div>
                                      </div>
                                      <h4 className="msg_number">
                                        {formatTime(data.createdAt)}
                                      </h4>
                                    </div>
                                  );
                                }
                                return null;
                              })
                            );
                          })}

                        {/* <div className="user_single_chat_list">
                          <div className="user_single_chat_list_inner">
                            <img src={user2} alt="img" className="img-fluid" />
                            <div>
                              <h3>Sanjeev Mehta ( Borrower)</h3>
                              <h4 className="active">Active </h4>
                            </div>
                          </div>
                          <h4 className="msg_number">4:40 PM</h4>
                        </div> */}
                      </div>
                    </div>
                    <div
                      className={`chat_msg_side ${isActive ? "big_box" : ""}`}
                    >
                      {receiver != null ? (
                        <div className="chat_msg_side_main ">
                          <div className="chat_container_top_bar">
                            <div className="d-flex">
                              {!isActive ? (
                                <button onClick={toggleClass}>
                                  <RxHamburgerMenu className="icon" />
                                </button>
                              ) : (
                                <button onClick={toggleClass}>
                                  <IoMdClose className="icon" />
                                </button>
                              )}

                              <div className="chat_side_container_main_user">
                                <div className="user_single_chat_list_inner">
                                  <img
                                    src={user1}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <h3>
                                      {receiver?.name}
                                      {`(${receiver?.userType == 1
                                          ? "admin"
                                          : receiver?.userType == 2
                                            ? "lender"
                                            : "borrower"
                                        })`}
                                    </h3>
                                    <h4 className="active">{ActiveAdmin !== 1 ? "Active" : "Offline"}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat_msg_main_div custom_scroll">
                            {messages?.map((item) => {

                              const isUserMessage = item.senderId === adminId;
                              const isReceiverMessage =
                                item.receiverId === adminId;
                              console.log(item.roomId, chatRoomId);
                              return (
                                <div
                                  className={`chat_msg ${isUserMessage
                                      ? "left_side_msg"
                                      : isReceiverMessage
                                        ? "right_side_msg"
                                        : ""
                                    }`}
                                  key={item.id}
                                >
                                  {item.contentType == 1 ? (
                                    <p>{item.content}</p>
                                  ) : (
                                    <img
                                      src={item.content}
                                      className="img-fluid"
                                    />
                                  )}

                                  <span className="msg_time_date">
                                    {formatDate(item.createdAt)}
                                  </span>
                                  <img src={item.media} className="img-fluid" />
                                </div>
                              );
                            })}


                          </div>

                          <div className="msg_container_footer">
                            <div className="msg_container_footer_inner">
                              <div className="msg_type_input_box">
                                <input
                                  onChange={(e) =>
                                    setCurrentMessage(e.target.value)
                                  }
                                  value={currentMessage}
                                  type="text"
                                  placeholder="Type message here..."
                                />
                                <div className="attachment_file_btn">
                                  <label htmlFor="fileInput">
                                    <img
                                      src={attachment}
                                      alt="send"
                                      className="img-fluid"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*,.pdf"
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                              <div className="send_btn">
                                <button
                                  onClick={() => {
                                    handleSendButtonClick(1);
                                  }}
                                >
                                  <img
                                    src={send}
                                    alt="send"
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* <div className="chat_msg left_side_msg">
                            <img src={item.content} alt="image" />
                            
                            <span className="msg_time_date">
                              {formatDate(item.createdAt)}
                            </span>
                          </div> */}
                        </div>
                      ) : (
                        <div className="char_starting_screen">
                          <img
                            src={chatstart}
                            className="img-fluid"
                            alt="chat"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </AdminDashboardMain>
  );
};

export default Chat;