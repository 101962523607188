import React, { useState, useEffect } from 'react'
import AdminDashboardMain from './AdminDashboardMain'

import { Link } from 'react-router-dom'
import { BiEdit } from 'react-icons/bi'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai';
const Profile = () => {
  // profile upload

  const [selectedImage, setSelectedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
const[generatedUrl,setGeneratedUrl]=useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleImageChange = event => {
    const file = event.target.files[0]
    generateImgUrl(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(file)
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  // ============state for form============
  const [profileData, setprofileData] = useState(null)


  // ============Profile API============
  const userId = localStorage.getItem('userid')
  const profileAPI = () => {

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      userId: userId
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch('https://bizfinn.co.in/getProfile', requestOptions)
      .then(response => response.json())
      .then(result => {
        setprofileData(result)
      })
      .catch(error => console.log('error', error))
  }

  // ============Profile API call============
  useEffect(() => {
    profileAPI()
  }, [])

  const DataProfile = profileData?.data
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [password, setPassword] = useState()
  const [avatar, setavatar] = useState()


  // console.log(name)



  // ========update Profile image API================
  const UpdateProfileImageAPI = () => {


    var formdata = new FormData();
    formdata.append("userId", userId);
    formdata.append("image", selectedImage);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/updateProfileImage", requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
console.log(generatedUrl)

  // ========Admin Profile update API================

  const UpdateProfile = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "password": password,
      "name": name,
      "mobile": mobile,
      "avatar":generatedUrl
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateProfile/${userId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.message) {
          profileAPI()
          toast.success(result.message, {
            autoClose: 2000
            
          })
          setTimeout(() => {
            window.location.reload();
          }, 2000);
         
        }
      })
      .catch(error => console.log('error', error));

  }


  // ======== Profile Image upload API================

  const generateImgUrl=(image)=>{
    var formdata = new FormData();
    formdata.append("file", image);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result){
          setGeneratedUrl(result&&result?.fileUrl)
        }
      })
      .catch(error => console.log('error', error));
  }






  return (
    <>
      <AdminDashboardMain>
        <div className=''>
          <div className='profile_bg_main'>
            <section className='side_content_main_box '>
              <div className='page_heading_div'>
                <div className='back_btn_filter_main'>
                  <div className='back_btn_filter_inner'>
                    <BackBtn />
                    <h2>Profile</h2>
                  </div>
                </div>
                <ToastContainer />

                <div className='dashboard_add_new_btn '>
                  <div className=' custom_profile_upload_btn'>
                    <label htmlFor='profile' className='file_input_button'>
                      {selectedImage ? (
                        <div className='file_upload_flex'>
                          <BiEdit className='profile_edit_icon' />
                        </div>
                      ) : (
                        <div className='file_upload_flex'>
                          <img
                            src={
                              avatar ??
                              (profileData &&
                                profileData.data[0] &&
                                profileData.data[0].avatar)
                            }
                            alt='profile-img'
                            className='img-fluid profile__img'
                          />
                          <span onClick={UpdateProfileImageAPI}>Upload Profile Picture</span>
                        </div>
                      )}
                    </label>
                    <input
                      type='file'
                      id='profile'
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {previewImage && (
                      <>
                        <img
                          className='profile_img_preview'
                          src={previewImage}
                          alt='Preview'
                        />
                        <span className='profile_text'>Profile Picture</span>
                      </>
                    )}
                  </div>




                </div>
              </div>
              <section className=' '>
                <div className='Personal_Details_div_main'>
               
                    <h3>Personal Details </h3>

                    <div className='row'>
                      <div className='col-xl col-lg-6 col-md-6'>
                        <div>
                          <input
                            type='text'
                            className='form_input_box'
                            placeholder='Name'
                            autoCorrect='off'
                            autoComplete='off'
                            value={
                              name ??
                              (profileData &&
                                profileData.data[0] &&
                                profileData.data[0].name)
                            }
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-xl col-lg-6 col-md-6'>
                        <div>
                          <input readOnly
                            type='email'
                            className='form_input_box'
                            placeholder='Email'
                            autoCorrect='off'
                            autoComplete='off'
                            value={
                              email ??
                              (profileData &&
                                profileData.data[0] &&
                                profileData.data[0].email)
                            }
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='col-xl col-lg-6 col-md-6'>
                        <div>
                          <input
                            type='number'
                            className='form_input_box'
                            placeholder='Phone Number'
                            autoCorrect='off'
                            autoComplete='off'
                            value={
                              mobile ??
                              (profileData &&
                                profileData.data[0] &&
                                profileData.data[0].mobile)
                            }
                            onChange={e => setMobile(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className='col-xl col-lg-6 col-md-6'>
                        <div className='pass_show_hide_btn_pos_rel'>
                          <input
                            type={passwordVisible ? 'text' : 'password'}
                            className='form_input_box'
                            placeholder='Password'
                            autoCorrect='off'
                            autoComplete='off'
                            value={
                              password ??
                              (profileData &&
                                profileData.data[0] &&
                                profileData.data[0].password)
                            }
                            onChange={e => setPassword(e.target.value)}
                          />
                          <div onClick={togglePasswordVisibility} className='pass_show_hide_btn' style={{marginTop:"-10px"}}>
                            {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                          </div>
                        </div>
                      </div>
                      <div className='col-xl col-lg-6 col-md-6 '>
                        <div className='Update_Share_Details_btns_div'>
                          <Link to='#' onClick={UpdateProfile}>Update</Link>
                        </div>
                      </div>
                    </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </AdminDashboardMain>
    </>
  )
}

export default Profile