import React, { useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import { ToastContainer, toast } from 'react-toastify'

import { Link, useNavigate } from 'react-router-dom'
import BackBtn from './BackBtn'
const AddNewBorrower = () => {
  const [companyName, setcompanyName] = useState('')
  const [mobile, setmobile] = useState('')
  const [commission, setcommission] = useState('')
  const [avatar, setavatar] = useState('')
  const [annual_turn_over, setannual_turn_over] = useState('')
  const [channel_partner, setchannel_partner] = useState('')
  const [errorMessage, setErrorMessage] = useState('')


  // validation error hooks
  const [mobileError, setMobileError] = useState('');
  const [annualTurnOverError, setAnnualTurnOverError] = useState('');
  const [nameError, setNameError] = useState('');


  const navigate = useNavigate()

  let items = {
    companyName,
    mobile,
    commission,
    avatar,
    annual_turn_over,
    channel_partner
  }

  const craeteBorrower = e => {
    e.preventDefault()

    if (nameError || mobileError || annualTurnOverError) {
      toast.error('Please fix the validation errors before submitting.', {
        autoClose: 3000
      });
      return;
    }


    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify(items)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch('https://bizfinn.co.in/registerBorrower', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.success) {
          toast.success(result?.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate('/borrower')
          }, 2000)
        } else {
          if (result?.errors) {
            console.log('Error msg', result?.msg)
            setErrorMessage(result?.msg)
          } else {
            setErrorMessage(result?.msg)
          }
        }
      })
      .catch(error => console.log('error', error))
  }


  // mobile number validations
  const validateMobile = () => {
    const mobilePattern = /^\d{10}$/;

    if (!mobile.match(mobilePattern)) {
      setMobileError('Please enter a valid 10-digit mobile number.');
    } else {
      setMobileError('');
    }
  };



  // Borrower / company name validations
  // const validateName = () => {
  //   const namePattern = /^[A-Za-z0-9]{3,}$/;

  //   if (!companyName.match(namePattern)) {
  //     setNameError('Please enter a valid company name with at least 3 characters.');
  //   } else {
  //     setNameError('');
  //   }
  // };

  // Annual Turn Over
  const validateAnnualTurnOver = () => {
    if (!annual_turn_over) {
      setAnnualTurnOverError('Please select your annual turn over.');
    } else {
      setAnnualTurnOverError('');
    }
  };


  return (
    <>
      <AdminDashboardMain>
        <section className='side_content_main_box'>
          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Add New Borrower</h2>
              </div>
            </div>
          </div>
          <ToastContainer />

          <section className='add_new_lender_main_section'>
            <form onSubmit={craeteBorrower}>
              <div className='row'>
                <div className='col-lg-5 col-md-8 col-sm-12'>
                  <div className=''>
                    <input
                      type='text'
                      className='form_input_box'
                      placeholder='Enter Company Name'
                      autoCorrect='off'
                      autoComplete='off'
                      value={companyName}
                      onChange={e => setcompanyName(e.target.value)}
                      required
                    // onBlur={validateName}
                    />
                    {/* <div className='error_msg same_line_error_msg'>
                      {nameError && <div className='error'>{nameError}</div>}
                    </div> */}
                  </div>
                  <div className=''>
                    <input
                      type='text'
                      className='form_input_box'
                      placeholder='Enter Phone Number'
                      autoCorrect='off'
                      autoComplete='off'
                      value={mobile}
                      onChange={e => setmobile(e.target.value)}
                      required
                      onBlur={validateMobile}
                    />
                    <div className='error_msg same_line_error_msg'>
                      {mobileError && <div className='error'>{mobileError}</div>}
                    </div>
                  </div>
                  <div className=''>
                    <select
                      name='annual-turn'
                      className='form_input_box custom_select'
                      value={annual_turn_over}
                      onChange={event => setannual_turn_over(event.target.value)}
                      onBlur={validateAnnualTurnOver}
                    >
                      <option value=''>Select your annual turn over</option>
                      <option value="upto 1cr">upto 1 CR</option>
                      <option value="1 - 10 CR">1 - 10 CR</option>
                      <option value="10 - 50 CR">10 - 50 CR</option>
                      <option value="50cr+">50+ CR</option>
                    </select>
                    <div className='error_msg same_line_error_msg'>
                      {annualTurnOverError && <div className='error'>{annualTurnOverError}</div>}
                    </div>

                  </div>
                  <div className=''>
                    <input
                      type='text'
                      className='form_input_box'
                      placeholder='Channel partner'
                      autoCorrect='off'
                      autoComplete='off'
                      value={channel_partner}
                      onChange={e => setchannel_partner(e.target.value)}
                    />
                  </div>

                  {/* =============== Error msg=============== */}
                  <div className='error_msg'>
                    {errorMessage && (
                      <div className='error'>
                        {' '}
                        <p> {errorMessage} </p>
                      </div>
                    )}
                  </div>

                  <div className='form_both_btn_div_main'>
                    <button type='submit' className='create_new'>
                      Create New
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </AdminDashboardMain>
    </>
  )
}

export default AddNewBorrower
