import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import Modal from 'react-bootstrap/Modal'
import Filter from './Filter'
import BackBtn from './BackBtn'
import dayjs from 'dayjs'
import loader from '../assets/imgs/loader.gif'
import { MdDeleteOutline } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify'
import InfiniteScroll from 'react-infinite-scroller';
import { useContext } from 'react';
import {globalContext} from "../../App"
const Notifications = () => {
  const [notification, setNotification] = useState([])
  const {state,dispatch} =useContext(globalContext);
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const userid = localStorage.getItem('userid')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)


  // =============Unread notification counter================
  const UnReadNotifications = notification?.data?.filter(notification => notification.read === false);
  const numberOfUnReadNotifications = UnReadNotifications?.length;
  useEffect(()=>{
    dispatch({type:"NOTIFICATION_DATA",payload:numberOfUnReadNotifications})
  },[notification])
  // console.log("notidata",notidata)
  console.log(`Number of notifications: ${numberOfUnReadNotifications}`);

  // ==========notification list Api============
  const notificationApi = () => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      "userId": userid
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }
    setIsLoading(true)
    fetch('https://bizfinn.co.in/getNotifications', requestOptions)
      .then(response => response.json())
      .then(result => {
        setNotification(result)
        setIsLoading(false)
        setCurrentPage(currentPage + 1);
        setHasMore(setNotification.hasMore);
      })
      .catch(error => {
        console.log('error', error)
        setIsLoading(false)
      })
  }

  // ==========Call notification Api============
  useEffect(() => {
    notificationApi()
    console.log('notification', notification.data)
  }, [])

  // ==========delete single notification API============

  const delete_notification = (notificationId) => {
    console.log('Deleting notification with ID:', notificationId);
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/deleteNotification/${notificationId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          const updatedLenderList = notification.data.filter(
            lender => lender._id !== notificationId
          )
          setNotification({ ...notification, data: updatedLenderList })
        }
      })
      .catch(error => console.log('error', error));

  }

  // open clear All Notification popup model
  const clearAllNotification = () => {
    handleShow()
  }

  //  clear All Notification API
  const ClearAllNoti = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "userId": userid
    });


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/clearNotification", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          notificationApi()
        }
      })
      .catch(error => console.log('error', error));
  }


  return (
    <>
      <AdminDashboardMain>
        <section className='side_content_main_box'>

          <div className='page_heading_div'>
            <div className='back_btn_filter_main'>
              <div className='back_btn_filter_inner'>
                <BackBtn />
                <h2>Notifications</h2>
              </div>
            </div>

            <div className='dashboard_add_new_btn'>
              <button onClick={clearAllNotification} className=''>
                Claer All
              </button>
            </div>
          </div>
          <ToastContainer />


          <section className='tabs_main_section commaon_tabs_main_section'>
            <div className=''>
                          
              {isLoading && (
                <div className='pos_abs'>
                  <img src={loader} alt='loader' className='img-fluid' />
                </div>
              )}
              {/* <InfiniteScroll
                pageStart={1}
                loadMore={notificationApi}
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading...</div>}
              /> */}
              {notification?.data?.length > 0 ? (
                notification?.data
                  ?.slice()
                  .reverse()
                  .map(currEle => {
                    return (
                      <>
                        <div className='notification_div_single' key={currEle._id}>
                          <div>
                            <h3>{currEle.title}</h3>
                            <p>{currEle.subtitle}</p>
                          </div>
                          <div className='notification_delete_btn_div'>
                            <button className='notification_delete_btn' onClick={() => delete_notification(currEle._id)}><MdDeleteOutline className="icon" /> </button>
                            <h4 className='date'>
                              {' '}
                              {dayjs(currEle?.createdAt).format(
                                ' DD MMM, YYYY : hh:mm A'
                              )}
                            </h4>
                          </div>
                        </div>
                      </>
                    )
                  })
              ) : (
                // If no data is available
                <div className='notification_div_single'>
                  <p>No notifications available.</p>
                </div>
              )}
            </div>
            {/* <div className='notification_div'>
              <div className='Update_Share_Details_btns_div'>
                <Link to=''>Show More</Link>
              </div>
            </div> */}
          </section>
        </section>
      </AdminDashboardMain>


      {/*delete modal */}

      <Modal show={show} onHide={handleClose} className='modal_main '>
        <Modal.Body className='moadal_body'>
          <p> Are you sure you want to delete all notifications ?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='table_edit_delete_btn' onClick={handleClose}>
            Close
          </button>
          <button
            className='table_edit_delete_btn'
            onClick={() => {
              ClearAllNoti()
              handleClose()
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Notifications
