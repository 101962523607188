import React, { useEffect, useState } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import { Link, useParams, useNavigate } from 'react-router-dom'
import checks from '../assets/imgs/icosn/check.svg'
import upload from '../assets/imgs/icosn/upload.svg'
import BackBtn from './BackBtn'
import { ToastContainer, toast } from 'react-toastify'
import cross from '../assets/imgs/icosn/cross.png'
import download from '../assets/imgs/icosn/download.png'
import right from '../assets/imgs/icosn/right.png'
import { BsDownload } from 'react-icons/bs';
import { formatNumberWithPostfix } from '../utils/ConvertNumbers'


const CaseDetails = () => {
  const { _id } = useParams()
  const navigate = useNavigate();
  // const [lenderId, setlenderId] = useState('')
  const [landernamelist, setlandernamelist] = useState([])
  const [SingleBorrower, setSingleBorrower] = useState([])
  const [singleCase, setSingleCase] = useState([])


  const [selectlender, setSelectlender] = useState([])
  const [singleborrower, setsingleborrower] = useState([])
  const [singleborrowerID, setsingleborrowerID] = useState([])
  const [comments, setcomments] = useState('')
  const [shortedlender, setShortedlender] = useState([])
  const [previousState, setPreviousState] = useState([])
  const [fileName, setFileName] = useState()
  const [GSTLast, setGSTLast] = useState(null)
  const [approvedAmount, setapprovedAmount] = useState()
  const [isAnyApprovedLender, setisAnyApprovedLender] = useState(false)


  const [BankStatement, setBankStatement] = useState(null)

  const [financial_stat, setfinancial_stat] = useState(null)

  const [validGstFilling, setValidGstFilling] = useState(false)

  const [validBank24, setValidBank24] = useState(false)

  const [validProvisonalSheet, setValidProvisonalSheet] = useState(false)

  const [provisionalbalance, setprovisionalbalance] = useState(null)

  const [ITR, setITR] = useState(null)

  const [Debtserviced, setDebtserviced] = useState(null)

  const [MIS, setMIS] = useState(null)


  let fileObj = {}


  const [kycPayloadFanancial, setKycPayloadFanancial] = useState({
    financial_stat: null, // Initialize this field to null
  });
  const [zipFilesType, setzipFilesType] = useState('Financial_Details')




  const uploadFile = (file, type) => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    var formdata = new FormData();
    // formdata.append("userId", userToken);
    formdata.append("file", file);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        const fileUrl = result?.fileUrl;

        // Update the kycPayloadFanancial with the file URL
        setKycPayloadFanancial(prevState => ({
          ...prevState,
          financial_stat: fileUrl
        }));

        fileObj[type] = fileUrl;
        setPreviousState([...previousState, fileObj]);
      })
      .catch(error => console.log('error', error));
  };

  const handleFileUpload = (e, f, type) => {
    const file = e.target.files[0];
    setFileName(file)
    uploadFile(file, type);
  }

  const FinancialDetailss = e => {
    // alert()
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify(kycPayloadFanancial)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch(
      `https://bizfinn.co.in/updateBorrowerFinancialDetails/${singleCase?.borrower}/${_id}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      })
      .catch(error => console.log('error', error))
  }


  const [selectedOption, setSelectedOption] = useState("Approved");
  const [selectedReason, setSelectedReason] = useState("");
  const [ReasonerrorMessage, setReasonErrorMessage] = useState("");
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedReason(""); // Reset selected reason when changing options
    setReasonErrorMessage("");
  };

  const handleReasonClick = (reason) => {
    setSelectedReason(reason);
    setcomments(reason);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (selectedOption === "Declined" && !selectedReason) {
      setReasonErrorMessage("Choose a reason for decline the case.");
    } else {
      setReasonErrorMessage("");
      // Perform your submit logic here
    }
  };


  let singleCaseLenderList = singleCase?.lenders;

  console.log("6111", singleCaseLenderList)
  // =======================================================
  // ========Show the lender table after the lender assign ============
  // =======================================
  let lander_approved = ''
  if (singleCaseLenderList && singleCaseLenderList.length > 0) {
    singleCaseLenderList.forEach(item => {
      lander_approved = item.lander_approved;
      if (lander_approved === 0) {
        console.log("000");
      } else if (lander_approved === 1) {
        console.log("111");
      }
    });
  } else {
    // console.log("singleCaseLenderList is empty or undefined");
  }



  // =======================================================
  // ========In lender table select or unselect lender ============
  // ===================================================

  const [selectedLender, setSelectedLender] = useState([]);

  const handleCheckboxChange = (event) => {
    const selectedLanderName = event.target.value;
    console.log(selectedLanderName);
    const isIdPresent = selectedLender.some(item => item === selectedLanderName);

    if (isIdPresent) {
      const newArray = selectedLender.filter(item => item !== selectedLanderName);
      setSelectedLender(newArray);
    } else {
      // Otherwise, select the clicked checkbox
      setSelectedLender([...selectedLender, selectedLanderName]);
    }

    console.log(selectedLender);
  };

  const isCeckedLenderPresent = (changedVal, lenderApproved) => {
    console.log(changedVal, lenderApproved, selectedLender);
    return selectedLender.some(item => item === changedVal) || lenderApproved == 1
  }

  // =======================================
  // ========Get Single case============
  // =======================================
  const GetSingleCase = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/getSingleCase/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setSingleCase(result?.data)
        const hasLenderApproved = result?.data.lenders.some((lender) => lender.lander_approved === 1);
        console.log(hasLenderApproved);
        setisAnyApprovedLender(hasLenderApproved)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleCase()
  }, [_id])
  // console.log("singleCasesingleCase",singleCase?.borrower)


  // =======================================
  // ========Get Single Borrower============
  // =======================================

  const GetSingleBorrower = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "borrowerId": singleCase?.borrower
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleBorrower", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setSingleBorrower(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    GetSingleBorrower()
  }, [singleCase?.borrower])

  // =======================================
  // ========Case Approved status============
  // =======================================

  const caseApprovedStatus = () => {
    if (singleCaseLenderList.length === 0) {
      // If no lender is selected, show an error message or take appropriate action.
      toast.error("Please select a lender before approving the case.", {
        autoClose: 2000
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 1,
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate("/cases");
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========Case reject API============
  // =======================================

  const CaseRejectStatus = () => {
    console.log('case approved')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 2
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (selectedOption === "Declined" && !selectedReason) {
          setReasonErrorMessage("Choose a reason for decline the case.");
        } else {
          setReasonErrorMessage("");
          if (result.msg) {
            toast.success(result.msg, {
              autoClose: 2000
            })
            setTimeout(() => {
              navigate("/cases");
            }, 2000)
          }
        }


      })
      .catch(error => console.log('error', error));
  }

  // =======================================
  // ========case inprogress API============
  // =======================================

  const InprogressStatus = (e) => {
    e.preventDefault()
    // console.log('case Inprogress')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 3
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.msg) {
          // toast.success(result.msg, {
          //   autoClose: 2000
          // })
          // setTimeout(() => {
          //   navigate("/cases");
          // }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========get lender API============
  // =======================================
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getLeanders", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setlandernamelist(result?.data)
      })
      .catch(error => console.log('error', error));
  }, [])


  // =======================================
  // ========Get ID of lenders on select============
  // =======================================
  const [lenderId, setLenderId] = useState('');
  const [lendername, setLendername] = useState('');

  let selectedLenderId = ''; // Variable to store the selected option's ID

  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    setLenderId(selectedValue);
    setLendername(selectedValue);


    const selectedLenderId = event.target.value;
    const selectedLenderName = event.target.options[event.target.selectedIndex].text;

    setLenderId(selectedLenderId);
    setLendername(selectedLenderName);

  };

  console.log("setLenderId is", lenderId)
  console.log("setLenderId is", lendername)


  // =======================================
  // ========Assign Lender API============
  // =======================================

  const lenderIds = singleCase?.shortedLenders && singleCase?.shortedLenders.length > 0
    ? singleCase?.shortedLenders.map(lender => lender.lenderId)
    : [];

  // Ensure lenderIds is an array
  const lenderIdsArray = Array.isArray(lenderIds) ? lenderIds : [];


  console.log(lenderIdsArray)
  const AssignLender = (e) => {
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "lenderIds": lenderIdsArray
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/createCases/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setSelectlender(result?.result)
        console.log("selectssss lender", selectlender)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 1000
          })
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000)
        } else {
          toast.error(result?.message, {
            autoClose: 1000
          })
        }
      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========Lenders Shortlist API============
  // =======================================
  const LendersShortlist = (e) => {
    e.preventDefault()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "lenderId": lenderId,
      "landerName": lendername
      // "lenderId" : "64fdc5f8e61bce3fec7ac4de",
      // "landerName" : "Vikash"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/cases/${_id}/shortedLenders`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setShortedlender(result?.result)
        console.log("shorted lender is", shortedlender)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 1000
          })
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        } else {
          toast.error(result?.message, {
            autoClose: 1000
          })
        }
      })
      .catch(error => console.log('error', error));
  }


  // =======================================
  // ========Get Single borrower API============
  // =======================================

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "borrowerId": singleCase?.borrower
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleBorrower", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setsingleborrower(result?.data)
        // setsingleborrowerID()
      })
      .catch(error => console.log('error', error));
  }, [])


  // =======================================
  // ========Case Reject on declined button============
  // =======================================
  const CaseReject = () => {
    if (selectedOption === "Declined" && !selectedReason) {
      setReasonErrorMessage("Choose a reason for declining the case.");
    } else {
      setReasonErrorMessage(""); // Clear any previous error message
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "status": 2,
        "lender_remark": comments
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.msg) {
            toast.success(result.msg, {
              autoClose: 2000
            })
            setTimeout(() => {
              navigate("/cases");
            }, 2000)
          }
        })
        .catch(error => console.log('error', error));
    }
  }


  const AdminApprovedLender = () => {
    AdminApprovedAmountUpdate()
    if (!selectedLender) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "approved": 1,
      "lenderId": selectedLender
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/lenderCaseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)

        if (result.status === true) {
          toast.success("Case assigned to lender!", {
            autoClose: 2000
          });

          setTimeout(() => {
            navigate("/cases");
          }, 2000)
        } else {
          // Show error toaster message
          toast.error("Please select a Lender!", {
            autoClose: 2000
          });
        }
      })
      .catch(error => {
        console.log('error', error);
      })
      .catch(error => console.log('error', error));
  }




  const AdminApprovedAmountUpdate = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 3,
      "approved_amount": approvedAmount * 100000,
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/caseStatus/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.msg) {
          toast.success(result.msg, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate("/cases");
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }



  // =======================================
  // ========FileApprovedREject API============
  // =======================================

  const BorroweruserId = SingleBorrower?._id
  const FileApprovedREject = (status, Schema, doctype) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": status
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateCaseDocStatus/${_id}/${Schema}/${doctype}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        GetSingleCase()
      })
      .catch(error => console.log('error', error));
  }



  return (
    <>
      <AdminDashboardMain>
        <ToastContainer />

        <div className="full_width_bg_main">
          <section className="side_content_main_box">
            <div className="page_heading_div">
              <div className="back_btn_filter_main">
                <div className="back_btn_filter_inner">
                  <BackBtn />
                  <h2>Cases</h2>
                </div>
              </div>
            </div>

            <section className="">
              <section className="table_main_section">
                <div className="table-responsive">
                  <table className="table   commaon_table">
                    <thead className="table_head">
                      <tr>
                        <th scope="col">Case No</th>
                        <th scope="col">Borrower Name</th>
                        <th scope="col">Type of Loan</th>
                        <th scope="col">Turnover</th>
                        <th scope="col">Loan Ask</th>
                        <th scope="col">Loan Approved</th>
                        <th scope="col">Status</th>
                        <th scope="col">Select Lender</th>
                        <th scope="col">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {singleCase?.case_no}
                        </td>
                        <td className="name">
                          <Link to={`/borrower-profile/${singleCase?.borrower}`}> {singleCase?.borrowerName} </Link>
                        </td>
                        <td>{singleCase?.type_of_loan}</td>
                        <td>1 Cr</td>
                        <td>{formatNumberWithPostfix(singleCase?.requirement)}</td>
                        <td>
                          {formatNumberWithPostfix(singleCase?.approved_amount)}
                        </td>
                        <td >{singleCase?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                          singleCase?.status === 1 ? <span className="table_pending_btn table_approved_btn">Approved</span> :
                            singleCase?.status === 2 ? <span className="table_pending_btn">	Rejected</span> :
                              singleCase?.status === 3 ? <span className="table_pending_btn">	Progress</span> : ""}</td>
                        <td> {singleCase?.lenders?.map(currEle => {
                          return (
                            <>
                              <span>{currEle?.landerName}, </span>
                            </>
                          )
                        })}</td>
                        <td>{singleCase?.lender_remark}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>

            <section className="case_detail_content_main_section">
              <div className="row">
                <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <div className="case_query_reject_btn_main_flex">
                      <div>
                        <div className="case_detail_single_detail">
                          <h2>{singleCase?.borrowerName} is looking for</h2>
                          <p>{singleCase?.type_of_loan}</p>
                        </div>
                        <div className="case_detail_single_detail">
                          <h2>Amount Required (in INR)</h2>
                          <p>{singleCase?.requirement}</p>
                        </div>
                        <div className="case_detail_single_detail">
                          <h2>The Password for Locked Documents</h2>
                          <p>{singleCase?.doc_passwords}</p>
                        </div>
                      </div>
                      {singleCase?.status == 2 ? "" : (
                        <div className="case_query_reject_btn">
                          <Link to={`/add_query/${singleCase?._id}`} >Query</Link>
                          {/* <Link onClick={CaseRejectStatus}>Reject</Link> */}
                        </div>
                      )}
                    </div>






                    {/* <div className="loan_approve_message_btn loan_approve_message_btn_mobile">
                      <div className="login_register_btn_div">
                        <Link
                          className="blue_btn login_register_btn"
                          onClick={caseApprovedStatus}
                        >
                          Approve
                        </Link>
                        <Link
                          to="/chat"

                          className="login_register_btn register_btn simple_btn_hover"
                        >
                          Message
                        </Link>
                      </div>
                    </div> */}

                    {/* forward lender */}
                    {selectedOption === "Approved" && (
                      <>
                        {singleCase?.status == 2 ? "" : (
                          <div className="case_detail_single_detail mt-4 width_80">
                            <h2>Forward to Lender</h2>
                            {/* <form onSubmit={InprogressStatus}> */}
                            <form onSubmit={LendersShortlist}>
                              <div className="Forward_Lender_div">
                                <select
                                  name="borrower-name"
                                  className="form_input_box custom_select"
                                  value={lenderId} onChange={handleSelectChange}
                                  required
                                >
                                  <option value="">Select Lender Name</option>
                                  {landernamelist?.map(currEle => {
                                    return (
                                      <>
                                        <option value={currEle?._id}>{currEle?.name} </option>
                                      </>
                                    )
                                  })}
                                </select>
                                <button type='submit' className="add_more_btn" >Add </button>

                              </div>
                            </form>
                          </div>
                        )}

                        {/* lender name list */}
                        <div className="lender_name_main width_70">
                          {singleCase?.shortedLenders?.map(currEle => {
                            return (
                              <>
                                <div className="lender_name_single">
                                  <h3>{currEle?.landerName} </h3>
                                  <img src={checks} className="img-fluid" alt="check" />
                                </div>
                              </>
                            )
                          })}
                        </div>
                        {console.log("single CaseLender List 722", singleCase?.shortedLenders)}

                        {/* Select the lender you want to assign. */}
                        {singleCase?.lenders && (
                          <div className="case_detail_single_detail mt-4 width_80">
                            <h2>Select the lender you want to assign.</h2>
                            <form className='assigned_lender_list_table'>
                              <table>
                                {singleCase?.lenders.map((currEle, index) => {
                                  return <> {currEle.lander_approved != 0 && (
                                    <tr key={currEle.landerName}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          id={currEle.lenderId}
                                          name={currEle.lenderId}
                                          value={currEle.lenderId}
                                          onChange={handleCheckboxChange}
                                          checked={isCeckedLenderPresent(currEle.lenderId, currEle.approved)}
                                          disabled={currEle.approved === 1}
                                          multiple
                                        />
                                        <label htmlFor={currEle.lenderId}>{currEle.landerName}</label>
                                      </td>
                                      <td>Remark: {currEle.lender_remark}</td>
                                      <td>Approved Amount: {currEle.approved_amount}</td>
                                      <td>Status: {currEle.approved == 1 ? 'Approved' : 'Declined'}</td>
                                    </tr>
                                  )}
                                  </>
                                })}
                              </table>
                            </form>
                          </div>
                        )}

                      </>
                    )}

                    {/* Reupload */}
                    {/* {singleCase?.financial_details?.financial_stat?.status === 0 ||
                      singleCase?.financial_details?.gst_filling?.status === 0 ?
                      <>
                        <div className="case_detail_single_detail">
                          <h2>Re-upload these files (Rejected Files).</h2>
                        </div>



                        <form>
                          {singleCase?.financial_details?.financial_stat
                            ?.status === 1 || singleCase?.financial_details?.financial_stat === 3 ? (
                            ''
                          ) : (
                            <>
                              <div className='custom_file_upload_main'>
                                <label
                                  htmlFor='AuditedStatement'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.financial_stat ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.financial_stat ??
                                          singleCase?.financial_details?.financial_stat.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>

                                      <div className='file_upload_flex'>
                                        <span>
                                          Upload Audited Financial statements
                                          for last 3 years
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>

                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='AuditedStatement'
                                  style={{ display: 'none' }}
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setfinancial_stat,
                                      'financial_stat'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details?.gst_filling
                            ?.status === 1 || singleCase?.financial_details?.gst_filling
                              ?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div
                                className='custom_file_upload_main'
                                style={{
                                  border: validGstFilling
                                    ? '1px solid #f65555'
                                    : ''
                                }}
                              >
                                <label
                                  htmlFor='GSTLast'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.gst_filling ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.gst_filling ??
                                          singleCase?.financial_details?.gst_filling.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        Upload GST Filing for last 12 months
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='GSTLast'
                                  style={{ display: 'none' }}
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setGSTLast,
                                      'gst_filling'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details?.bank_statment_24
                            ?.status === 1 || singleCase?.financial_details?.bank_statment_24
                              ?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div
                                className='custom_file_upload_main'
                                style={{
                                  border: validBank24 ? '1px solid #f65555' : ''
                                }}
                              >
                                <label
                                  htmlFor='BankStatement'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.bank_statment_24 ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.bank_statment_24 ??
                                          singleCase?.financial_details?.bank_statment_24.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        Upload all Bank Statement of last 24
                                        months
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='BankStatement'
                                  style={{ display: 'none' }}
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setBankStatement,
                                      'bank_statment_24'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details?.provisional_sheet
                            ?.status === 1 || singleCase?.financial_details?.provisional_sheet
                              ?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div
                                className='custom_file_upload_main'
                                style={{
                                  border: validProvisonalSheet
                                    ? '1px solid #f65555'
                                    : ''
                                }}
                              >
                                <label
                                  htmlFor='provisionalbalance'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.provisional_sheet ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.provisional_sheet ??
                                          singleCase?.financial_details?.provisional_sheet.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        Upload provisional balance sheet for
                                        the current year
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='provisionalbalance'
                                  style={{ display: 'none' }}
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setprovisionalbalance,
                                      'provisional_sheet'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details
                            ?.itr_acknowledgement?.status === 1 || singleCase?.financial_details
                              ?.itr_acknowledgement?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div className='custom_file_upload_main'>
                                <label
                                  htmlFor='ITR'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.itr_acknowledgement ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.itr_acknowledgement ??
                                          singleCase?.financial_details?.itr_acknowledgement.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        Upload ITR acknowledgement form for
                                        last 2 years
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='ITR'
                                  className='hidden-input'
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  required
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setITR,
                                      'itr_acknowledgement'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details?.debt_service
                            ?.status === 1 || singleCase?.financial_details?.debt_service
                              ?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div className='custom_file_upload_main'>
                                <label
                                  htmlFor='Debtserviced'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.debt_service ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.debt_service ??
                                          singleCase?.financial_details?.debt_service.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        Upload Debt serviced during the
                                        current FY (Principal + interest)
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='Debtserviced'
                                  className='hidden-input'
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  required
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setDebtserviced,
                                      'debt_service'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}

                          {singleCase?.financial_details?.mis_additional
                            ?.status === 1 || singleCase?.financial_details?.mis_additional
                              ?.status === 3 ? (
                            ''
                          ) : (
                            <>
                              <div className='custom_file_upload_main'>
                                <label
                                  htmlFor='MIS'
                                  className='file-input-button'
                                >
                                  {kycPayloadFanancial.mis_additional ? (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        {kycPayloadFanancial.mis_additional ??
                                          singleCase?.financial_details?.mis_additional.url
                                            .split('/')
                                            .pop()}{' '}
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  ) : (
                                    <div className='file_upload_flex'>
                                      <span>
                                        {' '}
                                        Upload MIS and additional Documents
                                      </span>
                                      <img
                                        src={upload}
                                        alt='upload'
                                        className='img-fluid'
                                      />
                                    </div>
                                  )}
                                </label>
                                <input
                                  type='file'
                                  id='MIS'
                                  className='hidden-input'
                                  accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                  onChange={e =>
                                    handleFileUpload(
                                      e,
                                      setMIS,
                                      'mis_additional'
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}


                          <div className='dashboard_add_new_btn mb-5'>
                            <button
                              className=''
                              type='submit'
                              onClick={FinancialDetailss}
                            >
                              Submit
                            </button>
                          </div>
                        </form>

                      </>
                      : ''
                    }
 */}


                    {
                      singleCase.status == 0 || singleCase.status == 3 ? (
                        <div className="Loan_Approved_Status_main">
                          <div className="">
                            <p>Loan Approved Status: </p>
                            <div className="Loan_Approved_Status">
                              <div className="loan_status_label">
                                <input
                                  type="radio"
                                  id="Approved"
                                  name="loan-status"
                                  value="Approved"
                                  checked={selectedOption === "Approved"}
                                  onChange={handleOptionChange}
                                />
                                <label for="Approved">Approved</label> {' '}
                              </div>
                              <div className="loan_status_label">
                                <input
                                  type="radio"
                                  id="Declined"
                                  name="loan-status"
                                  value="Declined"
                                  checked={selectedOption === "Declined"}
                                  onChange={handleOptionChange}
                                />
                                <label for="Declined">Declined</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : ('')
                    }


                    {/*========== case cencel reasons=========== */}
                    {selectedOption === "Declined" ? (
                      <>
                        <div className="rejection_reason_main">
                          <button
                            onClick={() => handleReasonClick("Incomplete data")}
                            className={selectedReason === "Incomplete data" ? "active" : ""}
                          >
                            Incomplete data
                          </button>
                          <button
                            onClick={() => handleReasonClick("Low CIBIL")}
                            className={selectedReason === "Low CIBIL" ? "active" : ""}
                          >
                            Low CIBIL
                          </button>
                          <button
                            onClick={() => handleReasonClick("High Leverage")}
                            className={selectedReason === "High Leverage" ? "active" : ""}
                          >
                            High Leverage
                          </button>
                          <button
                            onClick={() => handleReasonClick("Low Runway")}
                            className={selectedReason === "Low Runway" ? "active" : ""}
                          >
                            Low Runway
                          </button>
                        </div>
                        <div className="leder_description width_90">
                          <textarea
                            rows="3"
                            cols="50"
                            className="form_input_box Enter_Comment"
                            placeholder="Enter other Comment"
                            value={comments}
                            onChange={e => setcomments(e.target.value)}
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <>
                        {(singleCase.status != 1 && isAnyApprovedLender) ? (
                          <div className="leder_description width_70">
                            <input
                              className="form_input_box Enter_Comment"
                              placeholder="Enter Approved Amount (INR Lakh)"
                              required
                              value={approvedAmount}
                              onChange={e => setapprovedAmount(e.target.value)}
                            ></input>
                          </div>
                        ) : (<></>)}
                      </>

                    )}

                    {ReasonerrorMessage && (
                      <div className='mb-3 error_msg same_line_error_msg'>
                        {ReasonerrorMessage}
                      </div>
                    )}

                    {(singleCase?.status != 0 && singleCase?.status != 3) ? "" : (
                      <div className="loan_approve_message_btn loan_approve_message_btn_dektop">
                        <div className="login_register_btn_div">

                          <div className="submit_button_main">
                            {selectedOption === "Approved" ? (
                              <>
                                {/* <button className='blue_btn login_register_btn' onClick={caseApprovedStatus}> Send</button> */}
                                <button className='blue_btn login_register_btn' onClick={AssignLender}> Send</button>

                              </>
                            ) : (
                              <>

                                <button className='blue_btn login_register_btn' style={{ background: "#f76157" }} onClick={CaseReject}> Declined</button>
                              </>
                            )}
                          </div>


                          {(singleCase.status != 1 && isAnyApprovedLender) ? (
                            <>
                              <button className='blue_btn login_register_btn' onClick={AdminApprovedLender}>Update Amount</button>
                              <button className='blue_btn login_register_btn' onClick={caseApprovedStatus}>Close Case</button>
                            </>
                          ) : (<></>)}

                          <Link
                            to="/chat"
                            className="login_register_btn register_btn simple_btn_hover"
                          >
                            Message
                          </Link>

                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 profile_details_main_section">
                  <div class="download_all_dropdown"><label for="dropdown">Select an option for download all files:</label>
                    <select value={zipFilesType} onChange={event => {
                      setzipFilesType(event.target.value)
                    }} id="dropdown">
                      <option value="">Select Document Type</option>
                      <option value="Financial_Details">Financial Details</option>
                      <option value="KYC_Details ">KYC Details </option>
                      <option value="Business_Details">Business Details</option>
                    </select>
                  </div>

                  <button className='btn btn-success'>
                    <a style={{ textDecoration: "none", color: "white" }} href={`https://bizfinn.co.in/download-zip/${singleCase.borrower}?fileType=${zipFilesType}`} download>
                      <span className='mx-2'>
                        Download Zip
                      </span>
                      <img
                        src={download}
                        alt="upload"
                        className="img-fluid"
                      />
                    </a>
                  </button>
                  <h3>
                    Financial Details <span> (Upload when month change)</span>
                  </h3>
                  <div>
                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.financial_stat?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Audited Financial statements for last 3 years
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.gst_filling?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download GST Filing for last 12 months</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" readOnly /> */}
                      </div>


                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.bank_statment_24?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span> Download Bank Statement of last 12 months</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box"  /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.provisional_sheet?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download provisional balance sheet for the current
                              year
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>

                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.itr_acknowledgement?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download ITR acknowledgement form for last 2 years
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.debt_service?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>
                              Download Debt serviced during the current FY
                              (Principal + interest repayment during the year)
                            </span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>
                    </div>

                    <div className="finanicial_details_single">
                      <div className="upload-btn-wrapper">
                        <a href={singleCase?.financial_details?.mis_additional?.url} download="file_name.extension" target='_blank'>
                          <button className="file_upload_btn form_input_box">
                            <span>Download MIS</span>
                            <img
                              src={download}
                              alt="upload"
                              className="img-fluid"
                            />
                          </button>
                        </a>
                        {/* <input type="file" className="form_input_box" /> */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </AdminDashboardMain>
    </>
  )
}

export default CaseDetails
