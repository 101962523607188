// import React, { useState, useEffect } from 'react'
// import LenderDashboardMain from './LenderDashboardMain'
// import upload from '../assets/imgs/icosn/upload.svg'
// import { Link, useParams, useNavigate } from 'react-router-dom'
// import BackBtn from '../admin/BackBtn'
// import { BiEdit } from 'react-icons/bi'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// const BorrowerProfile = () => {
//   const navigate = useNavigate();
//   const { _id } = useParams()

//   const [borrowerData, setBorrowerData] = useState({})
//   const [name, setName] = useState()
//   const [email, setEmail] = useState()
//   const [mobile, setMobile] = useState()
//   const [password, setPassword] = useState()

//   // file upload
//   const [AOA, setAOA] = useState(null)
//   const [MOA, setMOA] = useState(null)
//   const [COI, setCOI] = useState(null)
//   const [GST, setGST] = useState(null)
//   const [Directors, setDirectors] = useState(null)
//   const [PAN, setPAN] = useState(null)
//   const [KYCDirectors, setKYCDirectors] = useState(null)
//   const [AuditedStatement, setAuditedStatement] = useState(null)
//   const [GSTLast, setGSTLast] = useState(null)
//   const [BankStatement, setBankStatement] = useState(null)
//   const [provisionalbalance, setprovisionalbalance] = useState(null)
//   const [ITR, setITR] = useState(null)
//   const [Debtserviced, setDebtserviced] = useState(null)
//   const [MIS, setMIS] = useState(null)

//   const [PEN, setPEN] = useState(null)
//   const [aadhar, setaadhar] = useState(null)



//   const handleFileChange = (event, setterFunction) => {
//     const selectedFile = event.target.files[0]
//     setterFunction(selectedFile)
//   }

//   // profile upload
//   const [selectedImage, setSelectedImage] = useState(null)
//   const [previewImage, setPreviewImage] = useState(null)

//   const handleImageChange = (event) => {
//     const file = event.target.files[0]

//     if (file) {
//       const reader = new FileReader()
//       reader.onloadend = () => {
//         setSelectedImage(file)
//         setPreviewImage(reader.result)
//       }
//       reader.readAsDataURL(file)
//     }
//   }


//   // ==========form state for business details==============
//   const [businessDetailsData, setBusinessDetailsData] = useState({})

//   const [register_company_name, setregister_company_name] = useState('')
//   const [bussiness_structure, setbussiness_structure] = useState('')
//   const [age_og_business, setage_og_business] = useState('')
//   const [type_of_business, settype_of_business] = useState('')
//   const [annual_turn_over, setannual_turn_over] = useState('')
//   const [type_of_loan, settype_of_loan] = useState('')
//   const [loan_amount, setloan_amount] = useState('')

//   let BusinessDetailsiItems = [register_company_name, bussiness_structure, age_og_business, type_of_business, annual_turn_over, type_of_loan, loan_amount]





//   // =======================================
//   // ==========Borrower Profile===========
//   // =======================================

//   const BorrowerProfile = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       borrowerId: _id
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch("https://bizfinn.co.in/getSingleBorrower", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result)
//         setBorrowerData(result.data)
//       })
//       .catch(error => console.log('error', error));
//   }

//   useEffect(() => {
//     BorrowerProfile()
//   }, [_id])

//   // =======================================
//   // ============profile Update API=========
//   // =======================================

//   const LenderProfileUPdate = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "password": password,
//       "name": name,
//       "mobile": mobile,
//       "email": email
//     });

//     var requestOptions = {
//       method: 'PUT',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(`https://bizfinn.co.in/updateProfile/${_id}`, requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result)
//         if (result.message) {
//           toast.success(result.message, {
//             autoClose: 2000
//           })
//           setTimeout(() => {
//             navigate("/lender_cases");
//           }, 2000)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }



//   // =======================================
//   // ==========Business Details API===========
//   // =======================================

//   const BusinessDetailsAPI = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify(BusinessDetailsiItems);

//     var requestOptions = {
//       method: 'PUT',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(`https://bizfinn.co.in/updateBorrowerBusinessDetails/${_id}`, requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result)
//         setBusinessDetailsData(result.data)

//         if (result.message) {

//           toast.success(result.message, {
//             autoClose: 2000
//           })
//           setTimeout(() => {
//             // navigate("/borrower");
//           }, 2000)

//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   // borrower_Business_Details
//   const borrower_Business_Details = (e) => {
//     e.preventDefault();

//   }
//   // useEffect(()=>{
//   //   BusinessDetailsAPI()
//   //   console.log("business details ",businessDetailsData)
//   // },[])

//   return (
//     <>
//       <LenderDashboardMain>
//         <div className="">
//           <div className="profile_bg_main">
//             <section className="side_content_main_box ">
//               <div className="page_heading_div">
//                 <div className="back_btn_filter_main">
//                   <div className="back_btn_filter_inner">
//                     <BackBtn />
//                     <h2>Profile</h2>
//                   </div>
//                 </div>
//                 <div className="dashboard_add_new_btn ">
//                   <div className=" custom_profile_upload_btn">
//                     <label htmlFor="profile" className="file_input_button">
//                       {selectedImage ? (
//                         <div className="file_upload_flex">
//                           <BiEdit className="profile_edit_icon" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <img
//                             src={upload}
//                             alt="upload"
//                             className="img-fluid"
//                           />
//                           <span>Upload Profile Picture</span>
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="profile"
//                       style={{ display: 'none' }}
//                       onChange={handleImageChange}
//                     />
//                     {previewImage && (
//                       <img
//                         className="profile_img_preview"
//                         src={previewImage}
//                         alt="Preview"
//                       />
//                     )}
//                   </div>
//                 </div>
//               </div>
//               <ToastContainer />

//               {/* Personal Details */}
//               <section className=" ">
//                 <div className="Personal_Details_div_main">
//                   <h3>Personal Details </h3>
//                   <div className="row">
//                     <div className="col-xl col-lg-6 col-md-6">
//                       <div>
//                         <input
//                           type="text"
//                           className="form_input_box"
//                           placeholder="Name"
//                           autoCorrect="off"
//                           autoComplete="off"
//                           value={
//                             name ??
//                             (borrowerData &&
//                               borrowerData?.name)
//                           }
//                           onChange={e => setName(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-xl col-lg-6 col-md-6">
//                       <div>
//                         <input
//                           type="email"
//                           className="form_input_box"
//                           placeholder="Email"
//                           autoCorrect="off"
//                           autoComplete="off"
//                           value={
//                             email ??
//                             (borrowerData &&
//                               borrowerData?.email)
//                           }
//                           onChange={e => setEmail(e.target.value)}
//                           required
//                         />
//                       </div>
//                     </div>

//                     <div className="col-xl col-lg-6 col-md-6">
//                       <div>
//                         <input
//                           type="tel"
//                           className="form_input_box"
//                           placeholder="Phone Number"
//                           autoCorrect="off"
//                           autoComplete="off"
//                           value={
//                             mobile ??
//                             (borrowerData &&
//                               borrowerData?.mobile)
//                           }
//                           onChange={e => setMobile(e.target.value)}
//                         />
//                       </div>
//                     </div>

//                     <div className="col-xl col-lg-6 col-md-6">
//                       <div>
//                         <input
//                           type="password"
//                           className="form_input_box"
//                           placeholder="Password"
//                           autoCorrect="off"
//                           autoComplete="off"
//                           value={
//                             password ??
//                             (borrowerData &&
//                               borrowerData?.password)
//                           }
//                           onChange={e => setPassword(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                     <div className="col-xl col-lg-6 col-md-6 ">
//                       <div className="Update_Share_Details_btns_div">
//                         <Link onClick={LenderProfileUPdate}>Update</Link>
//                         <Link to="#">Share Details</Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </section>
//             </section>
//           </div>
//           <section className="profile_details_main_section">
//             <div className="row">
//               {/* ===========Business Details=========== */}
//               <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 profile_right_border">
//                 <form onSubmit={borrower_Business_Details}>
//                   <div className='borrower_Profile_m_height'>
//                     <h3>Business Details</h3>
//                     <div className="">
//                       <input
//                         type="text"
//                         className="form_input_box"
//                         placeholder="Name of Registered Company"
//                         autoCorrect="off"
//                         autoComplete="off"
//                         // value={register_company_name}
//                         // onChange={e => setregister_company_name(e.target.value)}
//                         value={
//                           register_company_name ??
//                           (businessDetailsData &&
//                             businessDetailsData[0] &&
//                             businessDetailsData[0]?.register_company_name)
//                         }
//                         onChange={e => setregister_company_name(e.target.value)}
//                       />
//                     </div>
//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={bussiness_structure}
//                         onChange={e => setbussiness_structure(e.target.value)}
//                       >
//                         <option value="">Type of Business Structure</option>
//                         <option value="Business Structure 1">Business Structure 1</option>
//                         <option value="Business Structure 2">Business Structure 2</option>
//                         <option value="Business Structure 3">Business Structure 3</option>
//                       </select>
//                     </div>
//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={annual_turn_over}
//                         onChange={e => setannual_turn_over(e.target.value)}
//                       >
//                         <option value="">Annual Turnover of your Business (in INR)</option>
//                         <option value="upto 1cr">upto 1 CR</option>
//                         <option value="1 - 10 CR">1 - 10 CR</option>
//                         <option value="10 - 50 CR">10 - 50 CR</option>
//                         <option value="50cr+">50+ CR</option>

//                       </select>
//                     </div>

//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={age_og_business}
//                         onChange={e => setage_og_business(e.target.value)}
//                       >
//                         <option value="">How Old is your Business</option>
//                         <option value="Less than 1 Year Old">Less than 1 Year Old</option>
//                         <option value="1 -3 Years Old">1 -3 Years Old</option>
//                         <option value="3 Years +">3 Years + </option>
//                       </select>
//                     </div>

//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={type_of_business}
//                         onChange={e => settype_of_business(e.target.value)}
//                       >
//                         <option value="">Type of Business</option>
//                         <option value="proprietorship">proprietorship</option>
//                         <option value="Partnership">Partnership</option>
//                         <option value="LLP">LLP</option>
//                         <option value="OPC">OPC</option>
//                         <option value="Private Limited Company">Private Limited Company</option>
//                         <option value="Public Limited Company">Public Limited Company</option>
//                       </select>
//                     </div>

//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={type_of_loan}
//                         onChange={e => settype_of_loan(e.target.value)}
//                       >
//                         <option value="">Type of Loan</option>
//                         <option value="Short Term">Short Term</option>
//                         <option value="Long Term">Long Term</option>
//                       </select>
//                     </div>
//                     <div className="">
//                       <select
//                         name="annual-turn"
//                         className="form_input_box custom_select"
//                         value={loan_amount}
//                         onChange={e => setloan_amount(e.target.value)}
//                       >
//                         <option value="">Loan Amount</option>
//                         <option value="1cr">1 CR</option>
//                         <option value="5cr">5 CR</option>
//                         <option value="10cr">10 CR</option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="dashboard_add_new_btn">
//                     {/* <Link to="/borrower" className="">
//                     Submit
//                   </Link> */}
//                     <button type='submit' className="">
//                       Submit
//                     </button>
//                   </div>
//                 </form>
//               </div>

//               <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 profile_right_border">
//                 <div className="borrower_Profile_m_height">
//                   <h3>KYC Details </h3>

//                   {/* proprietorship PEN card */}
//                   {/* {type_of_business === 'proprietorship' &&
//                     <div className="custom_file_upload_main">
//                       <label htmlFor="PEN" className="file-input-button">
//                         {PEN ? (
//                           <div className="file_upload_flex">
//                             <span> {PEN.name}</span>
//                             <img src={upload} alt="upload" className="img-fluid" />
//                           </div>
//                         ) : (
//                           <div className="file_upload_flex">
//                             <span>PEN Card</span>
//                             <img src={upload} alt="upload" className="img-fluid" />
//                           </div>
//                         )}
//                       </label>
//                       <input
//                         type="file"
//                         id="PEN"
//                         style={{ display: 'none' }}
//                         onChange={(e) => handleFileChange(e, setPEN)}
//                       />
//                     </div>
//                   } */}

//                      {/* proprietorship aadhar card */}
//                      {/* {type_of_business === 'proprietorship' &&
//                     <div className="custom_file_upload_main">
//                       <label htmlFor="aadhar" className="file-input-button">
//                         {aadhar ? (
//                           <div className="file_upload_flex">
//                             <span> {aadhar.name}</span>
//                             <img src={upload} alt="upload" className="img-fluid" />
//                           </div>
//                         ) : (
//                           <div className="file_upload_flex">
//                             <span>Aadhar Card</span>
//                             <img src={upload} alt="upload" className="img-fluid" />
//                           </div>
//                         )}
//                       </label>
//                       <input
//                         type="file"
//                         id="aadhar"
//                         style={{ display: 'none' }}
//                         onChange={(e) => handleFileChange(e, setaadhar)}
//                       />
//                     </div>
//                   } */}




//                   {/* AOA */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="AOA" className="file-input-button">
//                       {AOA ? (
//                         <div className="file_upload_flex">
//                           <span> {AOA.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span> Upload AOA</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="AOA"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setAOA)}
//                     />
//                   </div>

//                   {/* MOA */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="MOA" className="file-input-button">
//                       {MOA ? (
//                         <div className="file_upload_flex">
//                           <span> {MOA.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span> Upload MOA</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="MOA"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setMOA)}
//                     />
//                   </div>

//                   {/* COI */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="COI" className="file-input-button">
//                       {COI ? (
//                         <div className="file_upload_flex">
//                           <span> {COI.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span> Upload COI - Certificate of Incorporation</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="COI"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setCOI)}
//                     />
//                   </div>

//                   {/* GST */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="GST" className="file-input-button">
//                       {GST ? (
//                         <div className="file_upload_flex">
//                           <span> {GST.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span> Upload GST Certificate</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="GST"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setGST)}
//                     />
//                   </div>

//                   {/* Directors */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="Directors" className="file-input-button">
//                       {Directors ? (
//                         <div className="file_upload_flex">
//                           <span> {Directors.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>List of Directors</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="Directors"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setDirectors)}
//                     />
//                   </div>

//                   {/* PAN */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="PAN" className="file-input-button">
//                       {PAN ? (
//                         <div className="file_upload_flex">
//                           <span> {PAN.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>Upload Company PAN Certificate</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="PAN"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setPAN)}
//                     />
//                   </div>

//                   {/* KYCDirectors */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="KYCDirectors" className="file-input-button">
//                       {KYCDirectors ? (
//                         <div className="file_upload_flex">
//                           <span> {KYCDirectors.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload KYC (PAN and Aadhaar) all the directors
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="KYCDirectors"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setKYCDirectors)}
//                     />
//                   </div>
//                 </div>
//                 <div className="dashboard_add_new_btn">
//                   <Link to="/borrower" className="">
//                     Submit
//                   </Link>
//                 </div>
//               </div>

//               <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 profile_right_border">
//                 <div className="borrower_Profile_m_height">
//                   <h3>
//                     Financial Details <span> (Upload when month change)</span>{' '}
//                   </h3>

//                   {/* AuditedStatement */}
//                   <div className="custom_file_upload_main">
//                     <label
//                       htmlFor="AuditedStatement"
//                       className="file-input-button"
//                     >
//                       {AuditedStatement ? (
//                         <div className="file_upload_flex">
//                           <span> {AuditedStatement.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload Audited Financial statements for last 3 years
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="AuditedStatement"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setAuditedStatement)}
//                     />
//                   </div>

//                   {/* GSTLast */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="GSTLast" className="file-input-button">
//                       {GSTLast ? (
//                         <div className="file_upload_flex">
//                           <span> {GSTLast.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>Upload GST Filing for last 12 months</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="GSTLast"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setGSTLast)}
//                     />
//                   </div>

//                   {/* BankStatement */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="BankStatement" className="file-input-button">
//                       {BankStatement ? (
//                         <div className="file_upload_flex">
//                           <span> {BankStatement.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>Upload all Bank Statement of last 24 months</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="BankStatement"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setBankStatement)}
//                     />
//                   </div>

//                   {/* provisionalbalance */}
//                   <div className="custom_file_upload_main">
//                     <label
//                       htmlFor="provisionalbalance"
//                       className="file-input-button"
//                     >
//                       {provisionalbalance ? (
//                         <div className="file_upload_flex">
//                           <span> {provisionalbalance.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload provisional balance sheet for the current year
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="provisionalbalance"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setprovisionalbalance)}
//                     />
//                   </div>

//                   {/* ITR */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="ITR" className="file-input-button">
//                       {ITR ? (
//                         <div className="file_upload_flex">
//                           <span> {ITR.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload ITR acknowledgement form for last 2 years
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="ITR"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setITR)}
//                     />
//                   </div>

//                   {/* Debtserviced */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="Debtserviced" className="file-input-button">
//                       {Debtserviced ? (
//                         <div className="file_upload_flex">
//                           <span> {Debtserviced.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload Debt serviced during the current FY (Principal
//                             + interest) (optional)
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="Debtserviced"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setDebtserviced)}
//                     />
//                   </div>

//                   {/* MIS */}
//                   <div className="custom_file_upload_main">
//                     <label htmlFor="MIS" className="file-input-button">
//                       {MIS ? (
//                         <div className="file_upload_flex">
//                           <span> {MIS.name}</span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       ) : (
//                         <div className="file_upload_flex">
//                           <span>
//                             Upload MIS and additional Documents (optional)
//                           </span>
//                           <img src={upload} alt="upload" className="img-fluid" />
//                         </div>
//                       )}
//                     </label>
//                     <input
//                       type="file"
//                       id="MIS"
//                       style={{ display: 'none' }}
//                       onChange={(e) => handleFileChange(e, setMIS)}
//                     />
//                   </div>
//                 </div>
//                 <div className="dashboard_add_new_btn">
//                   <Link to="/borrower" className="">
//                     Submit
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </LenderDashboardMain>
//     </>
//   )
// }

// export default BorrowerProfile


import React, { useState, useEffect } from 'react'
import LenderDashboardMain from './LenderDashboardMain'
import upload from '../assets/imgs/icosn/upload.svg'
import download from '../assets/imgs/icosn/download.png'

import { Link, useParams, useNavigate } from 'react-router-dom'
import BackBtn from '../admin/BackBtn'
import { BiEdit } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const BorrowerProfile = () => {
  const navigate = useNavigate();
  const { _id } = useParams()

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [borrowerData, setBorrowerData] = useState([])
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [password, setPassword] = useState()
  const [fileInput, setfileInput] = useState()
  const [gst_number, setgst_number] = useState(null)


  // file upload
  const [AOA, setAOA] = useState(null)
  const [MOA, setMOA] = useState(null)
  const [COI, setCOI] = useState(null)
  const [GST, setGST] = useState(null)
  const [Directors, setDirectors] = useState(null)
  const [PAN, setPAN] = useState(null)
  const [KYCDirectors, setKYCDirectors] = useState(null)
  const [financial_stat, setfinancial_stat] = useState(null)
  const [GSTLast, setGSTLast] = useState(null)
  const [BankStatement, setBankStatement] = useState(null)
  const [provisionalbalance, setprovisionalbalance] = useState(null)
  const [ITR, setITR] = useState(null)
  const [Debtserviced, setDebtserviced] = useState(null)
  const [MIS, setMIS] = useState(null)

  const [PEN, setPEN] = useState(null)
  const [aadhar, setaadhar] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)
  const [kycPayload, setKycPayload] = useState({})
  const [previousState, setPreviousState] = useState([])
  let fileObj = {}



  const [kycPayloadFanancial, setKycPayloadFanancial] = useState({})


  const handleFileChange = (event, setterFunction) => {
    const selectedFile = event.target.files[0]

  }
  const [fileName, setFileName] = useState()
  // validation hook
  const [kycError, setKycError] = useState(true);
  const [financeError, setFinanceError] = useState(null);

  const [validAoa, setValidAoa] = useState(false);
  const [validMoa, setValidMoa] = useState(false);
  const [validInc, setValidInc] = useState(false);
  const [validPan, setValidPan] = useState(false);
  const [validAdh, setValidAdh] = useState(false);
  const [validGst, setValidGst] = useState(false);

  const [validFinacialStat, setValidFinancialStat] = useState(false)
  const [validGstFilling, setValidGstFilling] = useState(false)
  const [validBank24, setValidBank24] = useState(false)
  const [validProvisonalSheet, setValidProvisonalSheet] = useState(false)
  const [validItrAck, setValidItrAck] = useState(false)
  const [validDebtService, setValidDebtService] = useState(false)



  const handleFileUpload = (e, f, type) => {
    const file = e.target.files[0];
    setFileName(file)
    uploadFile(file, type);
    if (type === "aoa" && (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company")) {
      // setKycError(true)
      setValidAoa(false)
    }
    if (type == "moa" && (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company")) {
      // setKycError(true)
      setValidMoa(false)
    }
    if (type === "incorporation_certificate" && (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company")) {
      // setKycError(true)
      setValidInc(false)
    }
    if (type === "pan_certificate") {
      // setKycError(true)
      setValidPan(false)
    }
    if (type === "kyc_pan_aadhar_all") {
      // setKycError(true)
      setValidAdh(false)
    }
    if (type === "gst_certificate") {
      // setKycError(true)
      setValidGst(false)
    }
    if (type === "financial_stat") {
      setValidFinancialStat(false)
    }
    if (type === "gst_filling") {
      setValidGstFilling(false)
    }
    if (type === "bank_statment_24") {
      setValidBank24(false)
    }
    if (type === "provisional_sheet") {
      setValidProvisonalSheet(false)
    }
    if (type === "itr_acknowledgement") {
      setValidItrAck(false)
    }
    if (type === "debt_service") {
      setValidDebtService(false)
    }
  }

  useEffect(() => {
    previousState && previousState.map((val) => {
      let key = Object.keys(val)
      key.map((keys) => {
        setKycPayload({ ...kycPayload, [keys]: val[keys] })
      })
    })
  }, [fileUrl])

  useEffect(() => {
    previousState && previousState.map((val) => {
      let key = Object.keys(val)
      key.map((keys) => {
        console.log(keys, val[keys])
        setKycPayloadFanancial({ ...kycPayloadFanancial, [keys]: val[keys] })
      })
    })
  }, [fileUrl])




  // profile upload
  const [selectedImage, setSelectedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(file)
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }


  // ==========form state for business details==============
  const [getbusinessDetailsData, setgetBusinessDetailsData] = useState([])
  const [businessDetailsData, setBusinessDetailsData] = useState([])

  const [register_company_name, setregister_company_name] = useState()
  const [bussiness_structure, setbussiness_structure] = useState()
  const [age_of_business, setage_of_business] = useState()
  const [type_of_business, settype_of_business] = useState()
  const [annual_turn_over, setannual_turn_over] = useState()
  const [type_of_loan, settype_of_loan] = useState()
  const [loan_amount, setloan_amount] = useState()

  const [showDiv, setShowDiv] = useState(false);






  // ===============show data according to business type========================
  const handleSelectBusinessType = (e) => {
    const selectedValue = e.target.value;
    settype_of_business(selectedValue); // Enable the div if a value is selected
    setShowDiv(selectedValue !== '');
  };

  useEffect(() => {
    setShowDiv(type_of_business !== '');
  }, [type_of_business]);

  useEffect(() => {
    // Check if the initial value is selected (not an empty string)
    if (borrowerData?.bussiness_details?.type_of_business) {
      settype_of_business(borrowerData?.bussiness_details?.type_of_business);
    } else {
      settype_of_business(''); // Set it to an empty string initially
    }
  }, [borrowerData?.bussiness_details?.type_of_business]);

  // =======================================
  // ==========Borrower Profile===========
  // =======================================

  const BorrowerProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      borrowerId: _id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleBorrower", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setBorrowerData(result?.data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    BorrowerProfile()
  }, [_id])

  // =======================================
  // ============profile Update API=========
  // =======================================

  const LenderProfileUPdate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "password": password,
      "name": name,
      "mobile": mobile,
      "email": email
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateProfile/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.message) {
          toast.success(result.message, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate("/borrower");
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }




  // =======================================
  // ==========Business Details UPdate API===========
  // =======================================
  const borrower_Business_Details = (e) => {
    e.preventDefault()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "borrowerId": borrowerData?.borrower_id,
      "register_company_name": register_company_name,
      "bussiness_structure": bussiness_structure,
      "age_of_business": age_of_business,
      "type_of_business": type_of_business,
      "annual_turn_over": annual_turn_over,
      "type_of_loan": type_of_loan,
      "loan_amount": loan_amount,
      "gst_number": gst_number

    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateBorrowerBusinessDetails/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setBusinessDetailsData(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
          handleNext()
        }
        else {
          toast.error(result?.message, {
            autoClose: 2000
          })
        }
      })
      .catch(error => console.log('error', error));
  }




  // =======================================
  // ==========File upload API===========
  // =======================================
  const uploadFile = (file, type) => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    var formdata = new FormData();
    formdata.append("userId", _id);
    formdata.append("file", file);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setFileUrl(result?.fileUrl)
        fileObj[type] = result?.fileUrl;
        setPreviousState([...previousState, fileObj])
        // console.log(fileObj)


      })
      .catch(error => console.log('error', error));
  };


  // =======================================
  // ==========borrower KYC Details API===========
  // =======================================


  // useEffect(()=>{
  //   if(borrowerData?.kyc_details?.moa?.url,kycPayload?.incorporation_certificate || borrowerData?.kyc_details?.incorporation_certificate?.url){
  //     setValidMoa(false)
  //   }
  // },[kycPayload?.aoa,borrowerData?.kyc_details?.aoa?.url,kycPayload?.moa  ,borrowerData?.kyc_details?.moa?.url,kycPayload?.incorporation_certificate ,borrowerData?.kyc_details?.incorporation_certificate?.url])

  const kycSubmission = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      kycPayload
    );

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateBorrowerKycDetails/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
      .catch(error => console.log('error', error));
  }

  const borrower_KYC_Details = (e) => {
    e.preventDefault()
    if (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company") {
      if (!kycPayload.aoa && !borrowerData?.kyc_details?.aoa?.url) {
        setKycError(true)
        setValidAoa(true)
      }
    }
    else if (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company") {
      if (!kycPayload?.moa && !borrowerData?.kyc_details?.moa?.url) {
        setKycError(true)
        setValidMoa(true)
      }
    }
    else if (type_of_business == "Private Limited Company" || type_of_business == "Public Limited Company") {
      if (!kycPayload?.incorporation_certificate && !borrowerData?.kyc_details?.incorporation_certificate?.url) {
        setKycError(true)
        setValidInc(true)
      }
    } else if (!kycPayload?.gst_certificate && !borrowerData?.kyc_details?.gst_certificate?.url) {
      setKycError(true)
      setValidGst(true)
    } else if (!kycPayload?.pan_certificate && !borrowerData?.kyc_details?.pan_certificate?.url) {
      setKycError(true)
      setValidPan(true)
    } else if (!kycPayload?.kyc_pan_aadhar_all && !borrowerData?.kyc_details?.kyc_pan_aadhar_all?.url) {
      setKycError(true)
      setValidAdh(true)
    }
    else {
      setKycError(false)
      kycSubmission()
      handleNext()
    }
  }


  const [formValid, setFormValid] = useState(false);

  // ...

  // Update the formValid state based on form field values
  const validateForm = () => {
    if (
      register_company_name &&
      bussiness_structure &&
      age_of_business &&
      type_of_business &&
      annual_turn_over &&
      type_of_loan &&
      loan_amount
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, []);



  // =======================================
  // ==========Financial Details API===========
  // =======================================

  const financeSubmission = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(kycPayloadFanancial);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateBorrowerFinancialDetails/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
      .catch(error => console.log('error', error));
  }

  const Financial_Details = (e) => {
    e.preventDefault()
    if (!kycPayloadFanancial.financial_stat && !borrowerData?.financial_details?.financial_stat?.url) {
      setValidFinancialStat(true)
      setFinanceError(true)
    } else
      if (!kycPayloadFanancial.gst_filling && !borrowerData?.financial_details?.gst_filling?.url) {
        setValidGstFilling(true)
        setFinanceError(true)
      } else
        if (!kycPayloadFanancial.bank_statment_24 && !borrowerData?.financial_details?.bank_statment_24?.url) {
          setValidBank24(true)
          setFinanceError(true)
        } else
          if (!kycPayloadFanancial.provisional_sheet && !borrowerData?.financial_details?.provisional_sheet?.url) {
            setValidProvisonalSheet(true)
            setFinanceError(true)
          } else
            if (!kycPayloadFanancial.itr_acknowledgement && !borrowerData?.financial_details?.itr_acknowledgement?.url) {
              setValidItrAck(true)
              setFinanceError(true)
            } else
              if (!kycPayloadFanancial.debt_service && !borrowerData?.financial_details?.debt_service?.url) {
                setValidDebtService(true)
                setFinanceError(true)
              } else {
                setFinanceError(false)
                financeSubmission()
                handleNext()
              }
  }

  const steps = ['Business Details', 'KYC Details', 'Financial Details'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());




  const handleNext = () => {


    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleStep = (step) => () => {
    setActiveStep(step);
  };


  const [stepData, setStepData] = useState({
    step1: 'hello 1',
    step2: 'hello 2',
    step3: 'hello 3',
  });


  return (
    <>
      <LenderDashboardMain>
        <div className="">
          <div className="profile_bg_main">
            <section className="side_content_main_box ">
              <div className="page_heading_div">
                <div className="back_btn_filter_main">
                  <div className="back_btn_filter_inner">
                    <BackBtn />
                    <h2>Profile</h2>
                  </div>
                </div>
                <div className="dashboard_add_new_btn ">
                  <div className=" custom_profile_upload_btn">
                    <label htmlFor="profile" className="file_input_button">
                      {selectedImage ? (
                        <div className="file_upload_flex">
                          <BiEdit className="profile_edit_icon" />
                        </div>
                      ) : (
                        <div className="file_upload_flex">
                          <img
                            src={upload}
                            alt="upload"
                            className="img-fluid"
                          />
                          <span>Upload Profile Picture</span>
                        </div>
                      )}
                    </label>
                    <input
                      type="file"
                      id="profile"
                      style={{ display: 'none' }}
                      accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                      required
                      onChange={handleImageChange}
                    />
                    {previewImage && (
                      <img
                        className="profile_img_preview"
                        src={previewImage}
                        alt="Preview"
                      />
                    )}
                  </div>
                </div>
              </div>
              <ToastContainer />

              {/* Personal Details */}
              <section className=" ">
                <div className="Personal_Details_div_main">
                  <h3>Personal Details </h3>
                  <div className="row">
                    <div className="col-xl col-lg-6 col-md-6">
                      <div>
                        <input
                          readOnly
                          type="text"
                          className="form_input_box"
                          placeholder="Name"
                          autoCorrect="off"
                          autoComplete="off"
                          value={
                            name ??
                            (borrowerData &&
                              borrowerData?.name)
                          }
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl col-lg-6 col-md-6">
                      <div>
                        <input
                          readOnly
                          type="email"
                          className="form_input_box"
                          placeholder="Email"
                          autoCorrect="off"
                          autoComplete="off"
                          value={
                            email ??
                            (borrowerData &&
                              borrowerData?.email)
                          }
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-xl col-lg-6 col-md-6">
                      <div>
                        <input
                          readOnly
                          type="tel"
                          className="form_input_box"
                          placeholder="Phone Number"
                          autoCorrect="off"
                          autoComplete="off"
                          value={
                            mobile ??
                            (borrowerData &&
                              borrowerData?.mobile)
                          }
                          onChange={e => setMobile(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="col-xl col-lg-6 col-md-6">
                      <div className="pass_show_hide_btn_pos_rel">
                        <input
                        readOnly
                          type={passwordVisible ? 'text' : 'password'}
                          className="form_input_box"
                          placeholder="Password"
                          autoCorrect="off"
                          autoComplete="off"
                          value={
                            password ??
                            (borrowerData &&
                              borrowerData?.password)
                          }
                          onChange={e => setPassword(e.target.value)}
                        />
                        <div onClick={togglePasswordVisibility} className='pass_show_hide_btn' style={{ marginTop: "-10px" }}>
                          {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl col-lg-6 col-md-6 ">
                      <div className="Update_Share_Details_btns_div">
                        <Link onClick={LenderProfileUPdate}>Update</Link>
                        <Link to="#">Share Details</Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              </section>
            </section>
          </div>


          <section className="profile_details_main_section">
            <Box sx={{ width: '100%' }} className="">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};


                  return (
                    <Step key={label} {...stepProps} onClick={handleStep(index)}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <React.Fragment>
                {activeStep === 0 && (
                  <section className="profile_details_main_section">
                    <div className="row">
                      {/* ===========Business Details=========== */}
                      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                        <form onSubmit={borrower_Business_Details}>
                          <div className=''>
                            <h3>Business Details</h3>

                            {/* ======Type of Business=========== */}
                            <div className="">
                              <select
                                name="annual-turn"
                                className="form_input_box custom_select"
                                value={type_of_business || (borrowerData?.bussiness_details?.type_of_business || '')}
                                // onChange={e => settype_of_business(e.target.value)}
                                // value={type_of_business}
                                onChange={handleSelectBusinessType}
                                required
                                disabled
                              >
                                <option value="">Type of Business</option>
                                <option value="proprietorship">proprietorship</option>
                                <option value="Partnership">Partnership</option>
                                <option value="LLP">LLP</option>
                                <option value="OPC">OPC</option>
                                <option value="Private Limited Company">Private Limited Company</option>
                                <option value="Public Limited Company">Public Limited Company</option>
                              </select>
                            </div>

                            {/* ========Company Name====== */}
                            <div className="">
                              <input
                                type="text"
                                className="form_input_box"
                                placeholder="Name of Registered Company"
                                autoCorrect="off"
                                autoComplete="off"
                                required
                                disabled
                                readOnly
                                value={
                                  register_company_name ??
                                  (borrowerData &&
                                    borrowerData?.bussiness_details &&
                                    borrowerData?.bussiness_details?.register_company_name)
                                }
                                onChange={e => setregister_company_name(e.target.value)}
                              />
                            </div>


                            {/* =========Business Trun Over=========== */}
                            <div className="">
                              <select
                                name="annual-turn"
                                className="form_input_box custom_select"
                                value={annual_turn_over || (borrowerData?.bussiness_details?.annual_turn_over || '')}
                                onChange={e => setannual_turn_over(e.target.value)}
                                required
                                disabled
                                readOnly
                              >
                                <option value="">Annual Turnover</option>
                                <option value="upto 1cr">upto 1 CR</option>
                                <option value="1 - 10 CR">1 - 10 CR</option>
                                <option value="10 - 50 CR">10 - 50 CR</option>
                                <option value="50cr+">50+ CR</option>
                              </select>
                            </div>

                            {/* ======Age of Business=========== */}
                            <div className="">
                              <select
                                name="annual-turn"
                                className="form_input_box custom_select"
                                value={age_of_business || (borrowerData?.bussiness_details?.age_of_business || '')}
                                onChange={e => setage_of_business(e.target.value)}
                                required
                                disabled
                                readOnly
                              >
                                <option value="">Age of Business</option>
                                <option value="Less than 1 Year Old">Less than 1 Year Old</option>
                                <option value="1 -3 Years Old">1 -3 Years Old</option>
                                <option value="3 Years +">3 Years + </option>
                              </select>
                            </div>


                            {/* ======Type of Loan========= */}
                            <div className="">
                              <select
                                name="annual-turn"
                                className="form_input_box custom_select"
                                value={type_of_loan || (borrowerData?.bussiness_details?.type_of_loan || '')}
                                onChange={e => settype_of_loan(e.target.value)}
                                required
                                disabled
                                readOnly
                              >
                                <option value=''>Type of Loan</option>
                                <option value="Unsecured Short term loan">Unsecured Short term loan</option>
                        <option value="Vendor Financing">Vendor Financing</option>
                        <option value="Sales Bill discounting">Sales Bill discounting</option>
                        <option value="EXIM Financing">EXIM Financing</option>
                        <option value="Secured Term Loan">Secured Term Loan</option>
                        <option value="Credit Line/OD">Credit Line/OD</option>
                        <option value="Other">Other</option>
                              </select>
                            </div>

                            {/* =========Loan Amount========= */}
                            <div className="">
                              <input type="text" className="form_input_box" placeholder='Enter GST Number' value={gst_number} onChange={e => setgst_number(e.target.value)} />
                            </div>
                          </div>

                          <div className="dashboard_add_new_btn">

                            {/* <button className="" type='submit'>
                                Submit
                              </button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                )}
                {activeStep === 1 && (
                  <div>
                    <section className="profile_details_main_section">
                      <div className="row">
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12"></div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                          <form onSubmit={borrower_KYC_Details}>
                            <div className="">
                              <h3>KYC Details </h3>

                              {/* AOA */}
                              <div className="custom_file_upload_main" style={{ border: validAoa ? "1px solid #f65555" : "", display: (type_of_business !== "Private Limited Company" && type_of_business !== "Public Limited Company") ? "none" : "block" }}>
                                <label htmlFor="AOA" className="file-input-button">
                                  {kycPayload.aoa ? (
                                    <div className="file_upload_flex" >
                                      <span> {kycPayload.aoa ?? borrowerData.kyc_details.aoa.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex" >
                                      {borrowerData?.kyc_details?.aoa?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayload.aoa ?? borrowerData.kyc_details.aoa.url.split('/').pop()}</span>
                                          <a href={borrowerData?.kyc_details?.aoa?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex" >
                                          <span>Upload AOA</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  name='aoa'
                                  type="file"
                                  id="AOA"
                                  className="hidden-input"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setAOA, "aoa")}
                                />
                              </div>
                              {(type_of_business === "Private Limited Company" || type_of_business === "Public Limited Company") && borrowerData?.kyc_details?.aoa?.url ? (
                                <h5 className='after_file_uploaded'> AOI Uploaded</h5>
                              ) : ""}

                              {/* MOA */}
                              <div className="custom_file_upload_main" style={{ border: validMoa ? "1px solid red" : "", display: (type_of_business !== "Private Limited Company" && type_of_business !== "Public Limited Company") ? "none" : "block" }}>
                                <label htmlFor="MOA" className="file-input-button">
                                  {kycPayload.moa ? (
                                    <div className="file_upload_flex" >
                                      <span> {kycPayload.moa.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.kyc_details?.moa?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayload.moa ?? borrowerData.kyc_details.moa.url.split('/').pop()}</span>
                                          <a href={borrowerData?.kyc_details?.moa?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload MOA</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  name='moa'
                                  type="file"
                                  id="MOA"
                                  className="hidden-input"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setMOA, "moa")}
                                />
                              </div>
                              {(type_of_business === "Private Limited Company" || type_of_business === "Public Limited Company") && borrowerData?.kyc_details?.moa?.url ? (
                                <h5 className='after_file_uploaded'> MOA Uploaded</h5>
                              ) : ""}

                              {/* COI */}
                              <div className="custom_file_upload_main" style={{ border: validInc ? "1px solid #f65555" : "", display: (type_of_business !== "Private Limited Company" && type_of_business !== "Public Limited Company") ? "none" : "block" }}>
                                <label htmlFor="COI" className="file-input-button">
                                  {kycPayload.incorporation_certificate ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayload.incorporation_certificate.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.kyc_details?.incorporation_certificate?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayload.incorporation_certificate ?? borrowerData.kyc_details.incorporation_certificate.url.split('/').pop()}</span>
                                          <a href={borrowerData?.kyc_details?.incorporation_certificate?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload COI - Certificate of Incorporation</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  name='coi'
                                  id="COI"
                                  className="hidden-input"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setCOI, "incorporation_certificate")}
                                />
                              </div>
                              {(type_of_business === "Private Limited Company" || type_of_business === "Public Limited Company") && borrowerData?.kyc_details?.incorporation_certificate?.url ? (
                                <h5 className='after_file_uploaded'> COI Uploaded</h5>
                              ) : ""}


                              {/* GST */}
                              <div className="custom_file_upload_main" style={{ border: validGst ? "1px solid #f65555" : "" }}>
                                <label htmlFor="GST" className="file-input-button">
                                  {kycPayload.gst_certificate ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayload.gst_certificate.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.kyc_details?.gst_certificate?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayload.gst_certificate ?? borrowerData.kyc_details.gst_certificate.url.split('/').pop()}</span>
                                          <a href={borrowerData?.kyc_details?.gst_certificate?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload GST Certificate</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  name='gst'
                                  type="file"
                                  id="GST"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  className="hidden-input"
                                  onChange={(e) => handleFileUpload(e, setGST, 'gst_certificate')}
                                />
                              </div>
                              {borrowerData?.kyc_details?.gst_certificate?.url ? (
                                <h5 className='after_file_uploaded'> GST Certificate Uploaded</h5>
                              ) : ""}

                              {/* Directors */}
                              <div className="custom_file_upload_main">
                                <label htmlFor="Directors" className="file-input-button">
                                  {kycPayload.list_of_directors ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayload.list_of_directors.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.kyc_details?.list_of_directors?.url ? (
                                        <>
                                          <div className="file_upload_flex">
                                            <span> {kycPayload.list_of_directors ?? borrowerData.kyc_details.list_of_directors.url.split('/').pop()}</span>
                                            <a href={borrowerData?.kyc_details?.list_of_directors?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="file_upload_flex">
                                            <span>List of Directors</span>
                                            <img src={upload} alt="upload" className="img-fluid" />
                                          </div>
                                        </>
                                      )}

                                    </div>
                                  )}
                                </label>
                                <input
                                  name='list_of_directors'
                                  type="file"
                                  id="Directors"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  className="hidden-input"
                                  onChange={(e) => handleFileUpload(e, setDirectors, 'list_of_directors')}
                                />
                              </div>
                              {borrowerData?.kyc_details?.list_of_directors?.url ? (
                                <h5 className='after_file_uploaded'> List of Directors Uploaded</h5>
                              ) : ""}

                              {/* PAN */}
                              <div className="custom_file_upload_main" style={{ border: validPan ? "1px solid #f65555" : "" }}>
                                <label htmlFor="PAN" className="file-input-button">
                                  {kycPayload.pan_certificate ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayload.pan_certificate.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <>
                                      {borrowerData?.kyc_details?.pan_certificate?.url ? (
                                        <>
                                          <div className="file_upload_flex">
                                            <span> {kycPayload.pan_certificate ?? borrowerData.kyc_details.pan_certificate.url.split('/').pop()}</span>
                                            <a href={borrowerData?.kyc_details?.pan_certificate?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="file_upload_flex">
                                            <span>Upload Company PAN Certificate</span>
                                            <img src={upload} alt="upload" className="img-fluid" />
                                          </div>
                                        </>
                                      )}
                                    </>


                                  )}
                                </label>

                                <input
                                  name='pan_certificate'
                                  type="file"
                                  id="PAN"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  className="hidden-input"
                                  onChange={(e) => handleFileUpload(e, setPAN, "pan_certificate")}
                                />
                              </div>
                              {borrowerData?.kyc_details?.pan_certificate?.url ? (
                                <h5 className='after_file_uploaded'>Company PAN Uploaded</h5>
                              ) : ""}


                              {/* KYCDirectors */}
                              <div className="custom_file_upload_main" style={{ border: validAdh ? "1px solid #f65555" : "" }}>
                                <label htmlFor="KYCDirectors" className="file-input-button">
                                  {kycPayload.kyc_pan_aadhar_all ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayload.kyc_pan_aadhar_all.split('/').pop()}</span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.kyc_details?.kyc_pan_aadhar_all?.url ? (
                                        <>
                                          <span> {kycPayload.kyc_pan_aadhar_all ?? borrowerData.kyc_details.kyc_pan_aadhar_all.url.split('/').pop()}</span>
                                          <a href={borrowerData?.kyc_details?.kyc_pan_aadhar_all?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </>
                                      ) : (
                                        <>
                                          <span> Upload KYC (PAN and Aadhaar) all the directors</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </>
                                      )}

                                    </div>
                                  )}
                                </label>
                                <input
                                  name='kyc_pan_aadhar_all'
                                  type="file"
                                  id="KYCDirectors"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  className="hidden-input"
                                  onChange={(e) => handleFileUpload(e, setKYCDirectors, 'kyc_pan_aadhar_all')}
                                />
                              </div>
                              {borrowerData?.kyc_details?.kyc_pan_aadhar_all?.url ? (
                                <h5 className='after_file_uploaded'>PAN and Aadhaar Uploaded</h5>
                              ) : ""}
                            </div>
                            <div className="dashboard_add_new_btn">

                              {/* <button className="" type='submit'>
                                  Submit
                                </button> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
                {activeStep === 2 && (
                  <section className="profile_details_main_section">
                    <div className="row">
                      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12"></div>
                      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12"></div>

                      <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 ">
                        <div >
                          {/* change me */}


                          <form onSubmit={Financial_Details}>
                            <div className="">
                              <h3>
                                Financial Details <span> (Upload when month change)</span>{' '}
                              </h3>

                              {/* AuditedStatement */}
                              <div className="custom_file_upload_main" style={{ border: validFinacialStat ? "1px solid #f65555" : "" }}>
                                <label htmlFor="AuditedStatement" className="file-input-button">
                                  {kycPayloadFanancial.financial_stat ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.financial_stat ?? borrowerData.financial_details.financial_stat.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.financial_stat?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.financial_stat ?? borrowerData?.financial_details?.financial_stat?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.financial_stat?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload Audited Financial statements for last 3 years</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="AuditedStatement"
                                  style={{ display: 'none' }}
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setfinancial_stat, "financial_stat")}
                                />
                              </div>
                              {borrowerData?.financial_details?.financial_stat?.url ? (
                                <h5 className='after_file_uploaded'> Audited Financial statements Uploaded</h5>
                              ) : ""}


                              <div className="custom_file_upload_main" style={{ border: validGstFilling ? "1px solid #f65555" : "" }}>
                                <label htmlFor="GSTLast" className="file-input-button">
                                  {kycPayloadFanancial.gst_filling ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.gst_filling ?? borrowerData.financial_details.gst_filling.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.gst_filling?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.gst_filling ?? borrowerData?.financial_details?.gst_filling?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.gst_filling?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload GST Filing for last 12 months</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="GSTLast"
                                  style={{ display: 'none' }}
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setGSTLast, "gst_filling")}
                                />
                              </div>
                              {borrowerData?.financial_details?.gst_filling?.url ? (
                                <h5 className='after_file_uploaded'> GST Filing Uploaded</h5>
                              ) : ""}


                              {/* BankStatement */}

                              <div className="custom_file_upload_main" style={{ border: validBank24 ? "1px solid #f65555" : "" }}>
                                <label htmlFor="BankStatement" className="file-input-button">
                                  {kycPayloadFanancial.bank_statment_24 ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.bank_statment_24 ?? borrowerData.financial_details.bank_statment_24.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.bank_statment_24?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.bank_statment_24 ?? borrowerData?.financial_details?.bank_statment_24?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.bank_statment_24?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>Upload all Bank Statement of last 24 months</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="BankStatement"
                                  style={{ display: 'none' }}
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setBankStatement, "bank_statment_24")}
                                />
                              </div>
                              {borrowerData?.financial_details?.bank_statment_24?.url ? (
                                <h5 className='after_file_uploaded'> All Bank Statement are Uploaded</h5>
                              ) : ""}

                              {/* provisionalbalance */}
                              <div className="custom_file_upload_main" style={{ border: validProvisonalSheet ? "1px solid #f65555" : "" }}>
                                <label htmlFor="provisionalbalance" className="file-input-button">
                                  {kycPayloadFanancial.provisional_sheet ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.provisional_sheet ?? borrowerData.financial_details.provisional_sheet.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.provisional_sheet?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.provisional_sheet ?? borrowerData?.financial_details?.provisional_sheet?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.provisional_sheet?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span> Upload provisional balance sheet for the current year</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="provisionalbalance"
                                  style={{ display: 'none' }}
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setprovisionalbalance, "provisional_sheet")}
                                />
                              </div>
                              {borrowerData?.financial_details?.provisional_sheet?.url ? (
                                <h5 className='after_file_uploaded'>Provisional balance sheet Uploaded</h5>
                              ) : ""}

                              {/* ITR */}
                              <div className="custom_file_upload_main" style={{ border: validItrAck ? "1px solid #f65555" : "" }}>
                                <label htmlFor="ITR" className="file-input-button">
                                  {kycPayloadFanancial.itr_acknowledgement ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.itr_acknowledgement ?? borrowerData.financial_details.itr_acknowledgement.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.itr_acknowledgement?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.itr_acknowledgement ?? borrowerData?.financial_details?.itr_acknowledgement?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.itr_acknowledgement?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>  Upload ITR acknowledgement form for last 2 years</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="ITR"
                                  className="hidden-input"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setITR, "itr_acknowledgement")}
                                />
                              </div>
                              {borrowerData?.financial_details?.itr_acknowledgement?.url ? (
                                <h5 className='after_file_uploaded'>ITR acknowledgement Uploaded</h5>
                              ) : ""}

                              {/* Debtserviced */}
                              <div className="custom_file_upload_main" style={{ border: validDebtService ? "1px solid #f65555" : "" }}>
                                <label htmlFor="Debtserviced" className="file-input-button">
                                  {kycPayloadFanancial.debt_service ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.debt_service ?? borrowerData.financial_details.debt_service.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.debt_service?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.debt_service ?? borrowerData?.financial_details?.debt_service?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.debt_service?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>  Upload ITR acknowledgement form for last 2 years</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="Debtserviced"
                                  className="hidden-input"
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setDebtserviced, "debt_service")}
                                />
                              </div>
                              {borrowerData?.financial_details?.debt_service?.url ? (
                                <h5 className='after_file_uploaded'>ITR acknowledgement Uploaded</h5>
                              ) : ""}

                              {/* mis_additional */}
                              <div className="custom_file_upload_main">
                                <label htmlFor="MIS" className="file-input-button">
                                  {kycPayloadFanancial.mis_additional ? (
                                    <div className="file_upload_flex">
                                      <span> {kycPayloadFanancial.mis_additional ?? borrowerData.financial_details.mis_additional.url.split('/').pop()}  </span>
                                      <img src={upload} alt="upload" className="img-fluid" />
                                    </div>
                                  ) : (
                                    <div className="file_upload_flex">
                                      {borrowerData?.financial_details?.mis_additional?.url ? (
                                        <div className="file_upload_flex">
                                          <span> {kycPayloadFanancial.mis_additional ?? borrowerData?.financial_details?.mis_additional?.url.split('/').pop()}</span>
                                          <a href={borrowerData?.financial_details?.mis_additional?.url} download="file_name.extension" target='_blank'> <img src={download} alt="upload" className="img-fluid download_img" /></a>
                                        </div>
                                      ) : (
                                        <div className="file_upload_flex">
                                          <span>  Upload MIS and additional Documents (optional)</span>
                                          <img src={upload} alt="upload" className="img-fluid" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  id="MIS"
                                  style={{ display: 'none' }}
                                  accept=".pdf, .jpg, .jpeg, .png, application/vnd.ms-excel"
                                  onChange={(e) => handleFileUpload(e, setMIS, "mis_additional")}
                                />
                              </div>
                              {borrowerData?.financial_details?.mis_additional?.url ? (
                                <h5 className='after_file_uploaded'>Upload MIS and additional Documents Uploaded</h5>
                              ) : ""}


                            </div>
                            <div className="dashboard_add_new_btn">
                              {/* <button className="" type="submit">
                                Submit
                              </button> */}

                            </div>
                          </form>
                        </div>

                      </div>

                    </div>

                  </section>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />


                  <Button onClick={handleNext} disabled={activeStep === 2} >
                    Next
                  </Button>
                </Box>
              </React.Fragment>

            </Box>
          </section>

        </div>
      </LenderDashboardMain >
    </>
  )
}

export default BorrowerProfile

