export function extractFilenameFromUrl(url) {
    console.log(url);
    // Use the URL constructor to get the pathname
    const pathname = decodeURIComponent(url?.split('/').pop());

  
    // Use decodeURIComponent to decode URL-encoded characters
    // const decodedFilename = decodeURIComponent(pathname.split('/').pop());
  
    return pathname;
  }