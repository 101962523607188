// import React, { useState, useEffect,useRef } from "react";
// // import '../Chat/chat.css'
// import { RxHamburgerMenu } from "react-icons/rx";

// import { IoMdClose } from "react-icons/io";
// import search_icon from "../../assets/imgs/icosn/chat-search.svg";
// import user1 from "../../assets/imgs/icosn/user1.png";
// import user2 from "../../assets/imgs/icosn/user2.png";
// import send from "../../assets/imgs/icosn/send-msg.svg";
// import attachment from "../../assets/imgs/icosn/attachment.svg";
// import BorrowerDashboardMain from "../../lender/BorrowerDashboardMain";
// import chatstart from "../../assets/imgs/icosn/chat.gif";
// import BackBtn from "../../admin/BackBtn";
// import socket from "../../Socket";
// // import { useReceiverId } from "../../ChatContext";

// const Chat = () => {
//   const messageContainerRef = useRef(null);

//   // Function to scroll to the bottom of the message container
//   const scrollToBottom = () => {
//     if (messageContainerRef.current) {
//       messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
//     }
//   };

//   const [isActive, setIsActive] = useState(false); // State to track the class

//   const toggleClass = () => {
//     setIsActive(!isActive); // Toggle the state value
//   };
//   // CHAT SECTION START

//   function formatDate(dateString) {
//     const options = {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//       hour: "2-digit",
//       minute: "2-digit",
//     };
//     return new Date(dateString).toLocaleString("en-US", options);
//   }
//   function formatTime(dateString) {
//     const options = {
//       hour: "2-digit",
//       minute: "2-digit",
//     };
//     return new Date(dateString).toLocaleTimeString("en-US", options);
//   }

//   const [currentMessage, setCurrentMessage] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [BorrowerId, setBorrowerId] = useState("");
//   const [user, setUser] = useState();
//   const [admindata, setAdminData] = useState(null);
//   const [fileLink, setFileLink] = useState(null);

// // FILE UPLOAD API 
// const handleImageChange = (event) => {
//   const file = event.target.files[0];
//   var formdata = new FormData();
//     formdata.append("userId", BorrowerId);
//     formdata.append("file", file);

//     var requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch("https://bizfinn.co.in/uploadfile", requestOptions)
//       .then((response) => response.json())
//       .then((result) => {

//         if (result) {
//           // Get the URL of the uploaded image
//           const imageURL = result && result.fileUrl;
//           sendMessage(2, imageURL);
//         }
//       })
//       .catch((error) => ("error: ", error));
// };

//   const sendMessage = (contentType, content) => {
//     if (
//       (contentType === 1 && content.trim() !== "") ||
//       (contentType === 2 && content)
//     ) {
//       const newMessage = {
//         content: content,
//         contentType: contentType,
//         senderId: BorrowerId,
//         receiverId: admindata?._id,
//       };
//       socket.emit("createMessage", newMessage);
//       setCurrentMessage("");
//       setFileLink(null);
//     }
//   };

//   const handleSendButtonClick = (type) => {
//     if (type == 1) {
//       console.log(currentMessage);
//       if (currentMessage.trim() !== "") {
//         sendMessage(1, currentMessage);
//       }
//     } else if (type == 2) {
//       // console.log(FileUrl);
//       sendMessage(2, fileLink);
//     }
//   };

//   // ...

//   const admin = (admin) => {
//     setMessages([]);

//     const room = {
//       roomId: admin?.roomId,
//     };

//     if(admin.participants[0]?._id== BorrowerId){
//       setAdminData(admin.participants[1]);
//     }
//      else if(admin.participants[1]?._id== BorrowerId){
//       setAdminData(admin.participants[0]);
//     }

//     socket.emit("getMessages", room);
//   };



//   useEffect(() => {
//     let lenderId = localStorage.getItem("lenderuserid");
//     if (lenderId) {
//       setBorrowerId(lenderId);
//     }
//     const BorrowerUserId = {
//       userId: localStorage.getItem("lenderuserid"),
//     };

//     socket.emit("getChatRooms", BorrowerUserId);
//     socket.on("listOfRooms", (userList) => {
//       setUser(userList?.data);
//       // console.log(userList);
//     });

//     socket.on("getMessagesOfRoom", (ALLMessage) => {
//       setMessages(ALLMessage?.data);
//       // console.log(ALLMessage)
//       let data = [];
//       data.push(ALLMessage?.data);
//       // console.log(data)
//     });
//   }, []);


//   useEffect(() => {
//     scrollToBottom();
//   }, [messages]); 


//   return (
//     <BorrowerDashboardMain>
//       <section className="side_content_main_box">
//         <div className="page_heading_div">
//           <div className="back_btn_filter_main">
//             <div className="back_btn_filter_inner">
//               <BackBtn />
//               <h2>Messages</h2>
//             </div>
//           </div>
//         </div>

//         <section className="dashboard_boxes_main_section">
//           <div className="">
//             <div className="">
//               <div className="">
//                 <section className="chat_main_section_main">
//                   <div className="chat_main_section">
//                     <div
//                       className={`chat_side_list_div custom_scroll ${
//                         isActive ? "small_box" : ""
//                       }`}
//                     >
//                       <div className="">
//                         <div className="chat_box_search">
//                           <img
//                             src={search_icon}
//                             alt="img"
//                             className="img-fluid"
//                           />
//                           <input type="search" placeholder="Search ..." />
//                         </div>
//                         {user &&
//                           user.map((item) => {
//                             return (
//                               item &&
//                               item.participants &&
//                               item.participants.map((data) => {
//                                 if (data?.userType === 1) {
//                                   return (
//                                     <div
//                                       className="user_single_chat_list"
//                                       onClick={() => {
//                                         admin(item);
//                                       }}
//                                       key={data._id}
//                                     >
//                                       <div className="user_single_chat_list_inner">
//                                         <img
//                                           src={user1}
//                                           alt="img"
//                                           className="img-fluid"
//                                         />
//                                         <div>
//                                           <h3>{data.name}</h3>

//                                           <h4>Last seen</h4>
//                                         </div>
//                                       </div>
//                                       <h4 className="msg_number">
//                                         {formatTime(data.createdAt)}
//                                       </h4>
//                                     </div>
//                                   );
//                                 }
//                                 return null;
//                               })
//                             );
//                           })}
//                       </div>
//                     </div>
//                     <div
//                       className={`chat_msg_side ${isActive ? "big_box" : ""}`}
//                     >
//                       {admindata != null ? (
//                         <div className="chat_msg_side_main ">
//                           <div className="chat_container_top_bar">
//                             <div className="d-flex">
//                               {!isActive ? (
//                                 <button onClick={toggleClass}>
//                                   <RxHamburgerMenu className="icon" />
//                                 </button>
//                               ) : (
//                                 <button onClick={toggleClass}>
//                                   <IoMdClose className="icon" />
//                                 </button>
//                               )}

//                               <div className="chat_side_container_main_user">
//                                 <div className="user_single_chat_list_inner">
//                                   <img
//                                     src={user1}
//                                     alt="img"
//                                     className="img-fluid"
//                                   />
//                                   <div>
//                                     <h3>
//                                       {admindata?.name}
//                                       {`(${
//                                         admindata?.userType == 1
//                                           ? "admin"
//                                           : admindata?.userType == 2
//                                           ? "lender"
//                                           : "borrower"
//                                       })`}
//                                     </h3>
//                                     <h4 className="active">Active</h4>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="chat_msg_main_div custom_scroll" ref={messageContainerRef}>
//                             {messages.map((item) => {
//                               const isUserMessage = item.senderId === BorrowerId;
//                               return (
//                                 <div
//                                   className={`chat_msg ${
//                                     isUserMessage
//                                       ? "left_side_msg"
//                                       : "right_side_msg"
//                                   }`}
//                                   key={item.id}
//                                 >
//                                  {item.contentType == 1 ? (<p>{item.content}</p>) : <img src={item.content}   className="img-fluid"/>}
//                                   <span className="msg_time_date">
//                                     {formatDate(item.createdAt)}
//                                   </span>
//                                 </div>
//                               );
//                             })}

//                             {/* <div className="chat_msg left_side_msg">
//                             <img
//                               src="https://cdn.pixabay.com/photo/2023/07/30/00/12/cat-8157889_640.png"
//                               className="img-fluid"
//                             />
//                             <span className="msg_time_date">
//                               Fri, Aug 4, 2023 5:12 PM
//                             </span>
//                           </div> */}
//                           </div>

//                           <div className="msg_container_footer">
//                             <div className="msg_container_footer_inner">
//                             <div className="msg_type_input_box">
//                                 <input
//                                   onChange={(e) =>
//                                     setCurrentMessage(e.target.value)
//                                   }
//                                   value={currentMessage}
//                                   type="text"
//                                   placeholder="Type message here..."
//                                 />
//                                 <div className="attachment_file_btn">
//                                   <label htmlFor="fileInput">
//                                     <img
//                                       src={attachment}
//                                       alt="send"
//                                       className="img-fluid"
//                                     />
//                                   </label>
//                                   <input
//                                     type="file"
//                                     id="fileInput"
//                                     accept="image/*,.pdf"
//                                     onChange={handleImageChange}
//                                     style={{ display: "none" }}
//                                   />
//                                 </div>
//                               </div>
//                               <div className="send_btn">
//                                 <button onClick={()=>{handleSendButtonClick(1)}}>
//                                   <img
//                                     src={send}
//                                     alt="send"
//                                     className="img-fluid"
//                                   />
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ) : (
//                         <div className="char_starting_screen">
//                           <img
//                             src={chatstart}
//                             className="img-fluid"
//                             alt="chat"
//                           />
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </section>
//               </div>
//             </div>
//           </div>
//         </section>
//       </section>
//     </BorrowerDashboardMain>
//   );
// };

// export default Chat;






import React, { useState, useEffect } from "react";
// import '../Chat/chat.css'
import { RxHamburgerMenu } from "react-icons/rx";

import { IoMdClose } from "react-icons/io";
import search_icon from "../../assets/imgs/icosn/chat-search.svg";
import user1 from "../../assets/imgs/icosn/user1.png";
import user2 from "../../assets/imgs/icosn/user2.png";
import send from "../../assets/imgs/icosn/send-msg.svg";
import attachment from "../../assets/imgs/icosn/attachment.svg";
import chatstart from "../../assets/imgs/icosn/chat.gif";
import BackBtn from "../../admin/BackBtn";
import socket from "../../Socket";
import BorrowerDashboardMain from "../BorrowerDashboardMain";

// import { useReceiverId } from "../../ChatContext";

const Chat = () => {
  const [isActive, setIsActive] = useState(false); // State to track the class

  const toggleClass = () => {
    setIsActive(!isActive); // Toggle the state value
  };
  // CHAT SECTION START

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  }
  function formatTime(dateString) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("en-US", options);
  }

  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [BorrowerId, setBorrowerId] = useState("");
  const [user, setUser] = useState(
    [{

      participants: [{
        _id: localStorage.getItem('adminId'),
        userType: 1,
        name: "Bizfinn Support"
      },
      {
        _id: localStorage.getItem('borroweruserid'),
        userType: 2, name: "Lender 123"
      }]

    }]
  );
  const [admindata, setAdminData] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  // FILE UPLOAD API 
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("userId", BorrowerId);
    formdata.append("file", file);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result) {
          // Get the URL of the uploaded image
          const imageURL = result && result.fileUrl;
          sendMessage(2, imageURL);
        }
      })
      .catch((error) => ("error: ", error));
  };

  const sendMessage = (contentType, content) => {
    if (
      (contentType === 1 && content.trim() !== "") ||
      (contentType === 2 && content)
    ) {
      const newMessage = {
        content: content,
        contentType: contentType,
        senderId: BorrowerId,
        receiverId: localStorage.getItem('adminId'),
        roomId: ""
      };
      console.log( 'Sending New message Request body', newMessage)
      socket.emit("createMessage", newMessage);
      setCurrentMessage("");
      setFileLink(null);
    }
  };

  const handleSendButtonClick = (type) => {
    if (type == 1) {
      console.log(currentMessage);
      if (currentMessage.trim() !== "") {
        sendMessage(1, currentMessage);
      }
    } else if (type == 2) {

      sendMessage(2, fileLink);
    }
  };

  // ...

  const activeAdmin = localStorage.getItem('activeAdmin');
  const admin = (admin) => {
    setMessages([]);
    console.log(BorrowerId)

    if (admin.participants[0]?._id == BorrowerId) {
      setAdminData(admin.participants[1]);
    }
    else if (admin.participants[1]?._id == BorrowerId) {
      setAdminData(admin.participants[0]);
    }
    const room = {
      roomId: admin?.roomId,
    };
    socket.emit("getMessages", room);
  };


  function markMessageSeen (lenderId){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "userId": lenderId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/markChatSeen`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    let lenderId = localStorage.getItem("borroweruserid");
    socket.emit("joinUser", {_id:lenderId});
    if (lenderId) {
      setBorrowerId(lenderId);
    }
    const BorrowerUserId = {
      userId: localStorage.getItem("borroweruserid"),
    };

    // console.log(BorrowerUserId)
    socket.emit("getChatRooms", BorrowerUserId);
    socket.on("listOfRooms", (userList) => {
      // setUser(userList?.data);
       console.log(userList, "hh");
       const conversationRoomId = userList?.data[0]?.roomId
       if(conversationRoomId){
        const room = {
          roomId: conversationRoomId,
          receiverId: BorrowerUserId.userId
        };
        localStorage.setItem('chatRoomId', conversationRoomId)
        socket.emit("getMessages", room);
       }
    });

    markMessageSeen(lenderId)

    socket.on("getMessagesOfRoom", (ALLMessage) => {
      setMessages(ALLMessage?.data);
      let chatRoomId = localStorage.getItem('chatRoomId')
      if (ALLMessage?.data.some(item =>
        item.roomId == chatRoomId
      )) {
        let data = [];
        data.push(ALLMessage?.data);
      }
      console.log(ALLMessage, "All mesage")
      
      // console.log(data)
    });
  }, []);

  // ACTIVE STATUS API
  const [ActiveLender, setActiveLender] = useState()
  const ActiveUserId = localStorage.getItem("lenderuserid")
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "active": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/setActiveStatus/${ActiveUserId}`, requestOptions)
      .then(response => response.json())
      .then(result => setActiveLender(result.result.active))


      .catch(error => console.log('error', error));
  }, [])

  return (
    <BorrowerDashboardMain>
      <section className="side_content_main_box">
        <div className="page_heading_div">
          <div className="back_btn_filter_main">
            <div className="back_btn_filter_inner">
              <BackBtn />
              <h2>Messages</h2>
            </div>
          </div>
        </div>

        <section className="dashboard_boxes_main_section">
          <div className="">
            <div className="">
              <div className="">
                <section className="chat_main_section_main">
                  <div className="chat_main_section">
                    <div
                      className={`chat_side_list_div custom_scroll ${isActive ? "small_box" : ""
                        }`}
                    >
                      <div className="">
                        {user &&
                          user.map((item) => {
                            return (
                              item &&
                              item.participants &&
                              item.participants.map((data) => {
                                if (data?.userType === 1) {
                                  return (
                                    <div
                                      className="user_single_chat_list"
                                      onClick={() => {
                                        admin(item);
                                      }}
                                      key={data._id}
                                    >
                                      <div className="user_single_chat_list_inner">
                                        <img
                                          src={user1}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <div>
                                          <h3>{data.name}</h3>

                                          <h4 style={{ color: "#21ba62" }}>{ActiveLender === 1 ? "Active" : "Offline"}</h4>
                                        </div>
                                      </div>
                                      <h4 className="msg_number">
                                        {formatTime(data.createdAt)}
                                      </h4>
                                    </div>
                                  );
                                }
                                return null;
                              })
                            );
                          })}
                      </div>
                    </div>
                    <div
                      className={`chat_msg_side ${isActive ? "big_box" : ""}`}
                    >
                      {user != null ? (
                        <div className="chat_msg_side_main ">
                          <div className="chat_container_top_bar">
                            <div className="d-flex">
                              {!isActive ? (
                                <button onClick={toggleClass}>
                                  <RxHamburgerMenu className="icon" />
                                </button>
                              ) : (
                                <button onClick={toggleClass}>
                                  <IoMdClose className="icon" />
                                </button>
                              )}

                              <div className="chat_side_container_main_user">
                                <div className="user_single_chat_list_inner">
                                  <img
                                    src={user1}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <div>
                                    <h3>
                                      {admindata?.name}
                                     Bizfinn Support
                                    </h3>
                                    <h4 className="active">{ActiveLender === 1 ? "Active " : "Offline"}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat_msg_main_div custom_scroll">
                            {messages?.map((item) => {
                              const isUserMessage = item.senderId === BorrowerId;
                              return (
                                <div
                                  className={`chat_msg ${isUserMessage
                                    ? "left_side_msg"
                                    : "right_side_msg"
                                    }`}
                                  key={item.id}
                                >
                                  {item.contentType == 1 ? (<p>{item.content}</p>) : <img src={item.content} className="img-fluid" />}
                                  <span className="msg_time_date">
                                    {formatDate(item.createdAt)}
                                  </span>
                                </div>
                              );
                            })}

                            {/* <div className="chat_msg left_side_msg">
                            <img
                              src="https://cdn.pixabay.com/photo/2023/07/30/00/12/cat-8157889_640.png"
                              className="img-fluid"
                            />
                            <span className="msg_time_date">
                              Fri, Aug 4, 2023 5:12 PM
                            </span>
                          </div> */}
                          </div>

                          <div className="msg_container_footer">
                            <div className="msg_container_footer_inner">
                              <div className="msg_type_input_box">
                                <input
                                  onChange={(e) =>
                                    setCurrentMessage(e.target.value)
                                  }
                                  value={currentMessage}
                                  type="text"
                                  placeholder="Type message here..."
                                />
                                <div className="attachment_file_btn">
                                  <label htmlFor="fileInput">
                                    <img
                                      src={attachment}
                                      alt="send"
                                      className="img-fluid"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*,.pdf"
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                              <div className="send_btn">
                                <button onClick={() => { handleSendButtonClick(1) }}>
                                  <img
                                    src={send}
                                    alt="send"
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="char_starting_screen">
                          <img
                            src={chatstart}
                            className="img-fluid"
                            alt="chat"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </BorrowerDashboardMain>
  );
};

export default Chat;