import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BorrowerDashboardMain from '../borrower/BorrowerDashboardMain'
import { Link } from 'react-router-dom'
import Filter from '../admin/Filter'
import BackBtn from '../admin/BackBtn'
import dayjs from 'dayjs'
import search from '../assets/imgs/icosn/search.svg'
import { formatNumberWithPostfix } from '../utils/ConvertNumbers'

const Cases = () => {
  const navigate = useNavigate()
  const borroweruserid = localStorage.getItem('borroweruserid')
  const [listcases, setlistcases] = useState([])
  const [searchQuery, setSearchQuery] = useState('');

  const LIstCases = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/borrowerCases/${borroweruserid}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setlistcases(result?.data)
      })
      .catch(error => console.log('error', error));
  }


  useEffect(() => {
    LIstCases()
  }, []);


  // =============================================
  // ============table data search================
  // =============================================
  const filteredData = listcases
    ?.filter((currEle) =>
      String(currEle.case_no).includes(searchQuery)
    )
    ?.slice()
    .reverse() || [];

    const onDateChange=(fromDate , toDate)=>{
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`https://bizfinn.co.in/borrowerCases/${borroweruserid}?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setlistcases(result?.data)
      })
      .catch(error => console.log('error', error));
    }
  

  return (
    <>
      <BorrowerDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Cases</h2>
              </div>
              <Filter onDateChange={onDateChange} />
            </div>
            <div className="dashboard_add_new_btn">
              <Link className="" to="/apply-new-loan">
                Apply New
              </Link>
            </div>
          </div>

          <section className="tabs_main_section commaon_tabs_main_section">
            <div className='table_heading_search_box_div'>
              <h1 className='list_heading'>List of Cases</h1>
              <div className="table_heading_search_box_div_inner">
                <img src={search} alt="search" className="img-fluid" />
                <input
                  type="search"
                  placeholder="Search by Case No"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <section className="table_main_section">
              <div className="table-responsive">
                <table className="table   commaon_table">
                  <thead className="table_head">
                    <tr>
                      <th>Case No</th>
                      <th>Date</th>
                      <th>Loan Amount</th>
                      <th>Loan Approved</th>
                      <th>Product</th>
                      <th>Status</th>
                      <th>Lender Remarks</th>
                    </tr>
                  </thead>
                  <tbody>


                    {filteredData.length === 0 ? (
                      <tr>
                        <td colSpan="6">No data found</td>
                      </tr>
                    ) : (
                      filteredData.map((currEle) => (
                        <tr key={currEle._id}>
                          <td className="name">
                            {currEle.status === 1 ? (
                              currEle.case_no
                            ) : (
                              <Link to={`/borrower_cases/borrower_cases_details/${currEle._id}`}>
                                {currEle.case_no}
                              </Link>
                            )}
                          </td>
                          <td> {dayjs(currEle.createdAt).format('DD-MM-YYYY')}</td>
                          <td>{formatNumberWithPostfix(currEle.requirement)}</td>
                           <td>{formatNumberWithPostfix(currEle?.approved_amount)}</td>
                          <td>{currEle.type_of_loan}</td>

                          <td >{currEle?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                            currEle?.status === 1 ? <span className="table_pending_btn table_approved_btn">Approved</span> :
                              currEle?.status === 2 ? <span className="table_pending_btn">	Rejected</span> :
                                currEle?.status === 3 ? <span className="table_pending_btn">	Progress</span> : ""}</td>

                          <td>{currEle.comment}</td>
                        </tr>
                      ))
                    )}


                  </tbody>
                </table>
              </div>
            </section>
          </section>
        </section>
      </BorrowerDashboardMain>
    </>
  )
}

export default Cases
