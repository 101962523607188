import React, { useState } from "react";
import logo from "../../assets/imgs/logo.png";
import loader from "../../assets/imgs/loader.gif";
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [OTPdata, setOTPdata] = useState({});
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [GetOTP, setGetOTP] = useState(false);
  const [showOTPfield, setshowOTPfield] = useState(false);
  const [showPhonefield, setshowPhonefield] = useState(true);


  const userid = localStorage.getItem('borroweruserid')
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [newpasswod, setnewpasswod] = useState(false);
  const [userType, setuserType] = useState(3);
console.log("borrowerr " , userid)

  let items = { phone, otp };
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  function generateOTP() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    return otp.toString();
  }

  function sendOTPByEmail(otp) {
    // console.log(`Sending OTP ${otp} to`);
    // In a real application, you would send an email here using an email service.
  }

  const generateAndSendOTP = () => {
    const newOTP = generateOTP();
    setOTP(newOTP);
    sendOTPByEmail(newOTP);

  };

  // ========Login API call=============
  const SendOTP = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "phone": phone,
      "otp": otp,
      "userType":userType
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/sendOtp", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          console.log(result);
          setOTPdata(result);
          // alert("OTP sent successfully!");
          setErrorMessage(result.msg)
          toast.success(result.msg, {
            autoClose: 2000
          })
          setshowOTPfield(true)
          setshowPhonefield(false)
          // alert(`Sending OTP ${otp}`);
        } else {
          console.log(result);
          setOTPdata(result);
          // alert("OTP sending failed!");
          setErrorMessage(result.msg)
        }
      })
      .catch(error => console.log('error', error));
  };


  console.log(OTPdata?.user)
  // console.log("OTPdata is ", OTPdata)
  // console.log(`Sending OTP ${otp}`);

  const verifyOTP = () => {
    if (otp === otpSent) {
      // alert("OTP matched successfully!");
      toast.success("OTP matched successfully!", {
        autoClose: 2000
      })
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        mobile:phone
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setIsLoading(true); // Show the loader
      fetch("https://bizfinn.co.in/otp-verified", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          if (result.success) {
            localStorage.setItem("borrowerusertoken", result.data.fcmToken);
            localStorage.setItem("borroweruserid", result.data._id);
            localStorage.setItem("adminId" , result.data.admin)
            navigate(`/borrower_profile`);
          } else {
            setErrorMessage(" Wrong Credentials!");
          }
          setIsLoading(false); // Hide the loader after API response
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false); // Hide the loader on API error
        });

      setIsOTPVerified(true);
      setshowOTPfield(false)
    } else {
      // OTPs do not match, show error message
      // alert("OTP does not match!");
      toast.error("OTP does not match!", {
        autoClose: 2000
      })
    }
  };


  const ChangePassword = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": OTPdata?.user,
      "new_password": newpasswod
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/changePassword", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)

        toast.success(result.msg, {
          autoClose: 2000
        })
        setTimeout(() => {
          navigate('/')
        }, 2000)
      })
      .catch(error => console.log('error', error));
  }




  return (
    <>
      {/* Show the loader conditionally */}

      <section className="auth_main_section full_container auth_banner_img pos_rel">
        {isLoading && (
          <div className="pos_abs">
            <img src={loader} alt="loader" className="img-fluid" />
          </div>
        )}
        <ToastContainer />

        <div className="container-fluid">
          <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="auth_content_col">
                <img src={logo} alt="logo" className="img-fluid logo" />
                <div className="auth_content_div">
                  <h1>
                    Get the <br />
                    <span>
                      Funding <br /> You Need
                    </span>
                  </h1>
                  <p>
                    We provide working capital solutions to help businesses like
                    yours grow and succeed. And there is no collateral required.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="auth_second_col_main_outer">
                <div className="auth_second_col_main">
                  <div className="content">
                    {showPhonefield && (
                      <form onSubmit={SendOTP}>
                        <h2>Forget Password</h2>

                        <input
                          type="number"
                          className="form_input_box"
                          placeholder="Enter Phone Number"
                          autoCorrect="off"
                          autoComplete="off"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="login_register_btn_div mb-4">
                          <button
                            onClick={generateAndSendOTP}
                            type="submit"
                            className="blue_btn login_register_btn"
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "Send OTP"}{" "}
                          </button>
                        </div>

                        <div className="error_msg">
                          {errorMessage && (
                            <div className="error">
                              {" "}
                              <p> {errorMessage} </p>
                            </div>
                          )}
                        </div>

                      </form>


                    )}

                    {showOTPfield && (
                      <>
                        <label>Enter OTP send on your registered mobile number</label>
                        <input
                          type="number"
                          className="form_input_box"
                          placeholder="Enter OTP"
                          autoCorrect="off"
                          autoComplete="off"
                          value={otpSent}
                          onChange={(e) => setOtpSent(e.target.value)}
                        />

                        <button
                          onClick={verifyOTP}
                          className="blue_btn login_register_btn"
                          disabled={isLoading || !otpSent}
                        >
                          {isLoading ? "Verifying..." : "Verify OTP"}
                        </button>


                        {/* =============== Error msg=============== */}
                        <div className="error_msg">
                          {errorMessage && (
                            <div className="error">
                              {" "}
                              <p> {errorMessage} </p>
                            </div>
                          )}
                        </div>
                      </>
                    )}






                    {/* password changed */}


                    {isOTPVerified && (
                      <form onSubmit={ChangePassword}>
                        <label>Enter new password</label>



                        <div className="pass_show_hide_btn_pos_rel">
                          <input
                            type={passwordVisible ? 'text' : 'password'}
                            className="form_input_box "
                            style={{ marginBottom: "0" }}
                            placeholder="Enter Password"
                            autoCorrect="off"
                            autoComplete="off"
                            value={newpasswod}
                            onChange={(e) => setnewpasswod(e.target.value)}
                          />
                          <div onClick={togglePasswordVisibility} className='pass_show_hide_btn'>
                            {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                          </div>
                        </div>


                        <div className="login_register_btn_div">
                          <button
                            type="submit"
                            className="blue_btn login_register_btn"
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "Submit"}{" "}
                          </button>
                        </div>

                        <div className="error_msg">
                          {/* {errorMessage && (
                              <div className="error">
                                {" "}
                                <p> {errorMessage} </p>
                              </div>
                            )} */}
                        </div>
                      </form>
                    )}




                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;