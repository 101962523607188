import React, { useEffect, useState } from 'react'
import Filter from '../admin/Filter'
import BorrowerDashboardMain from './BorrowerDashboardMain'

const BorrowerDashboard = () => {
  const [userData, setuserData] = useState([])
  const borroweruserid = localStorage.getItem('borroweruserid')
  const [dashboardCounts, setdashboardCounts] = useState({})
  // ============================
  // ====Dashboard API=======
  // ============================
  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      userId: borroweruserid
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }
    

    fetch(`https://bizfinn.co.in/getProfile`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setuserData(result?.data[0])
      })
      .catch(error => console.log('error', error));

      var requestOption2 = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }

      fetch(`https://bizfinn.co.in/borrowerDashbord/${borroweruserid}`, requestOption2)
      .then(response => response.json())
      .then(result => {
        console.log(result.data)
        setdashboardCounts({
          total: result?.data?.completeCases + result?.data?.inprogressCases + result?.data?.pendingCases + result?.data?.rejectedCases ,
          inProgress :  result?.data?.inprogressCases + result?.data?.pendingCases
        })
      })
      .catch(error => console.log('error', error));
  }, [])

  const onDateChange=(fromDate , toDate)=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`https://bizfinn.co.in/borrowerDashbord/${borroweruserid}?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result.data)
      setdashboardCounts({
        total: result?.data?.completeCases + result?.data?.inprogressCases + result?.data?.pendingCases + result?.data?.rejectedCases ,
        inProgress :  result?.data?.inprogressCases + result?.data?.pendingCases
      })
    })
    .catch(error => console.log('error', error));
  }

  return (
    <BorrowerDashboardMain>
      <section className="side_content_main_box">
        <div className="page_heading_div">
          <div className="back_btn_filter_main">
            <h2>Dashboard</h2>
            <Filter onDateChange={onDateChange} />
          </div>
        </div>
        <section className="dashboard_boxes_main_section">
          <div className='row'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              {/* ========Top Row========= */}
              <div className="row">
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_1">
                    <h2>Total Cases</h2>
                    <div className='flex'>
                      <h4>{dashboardCounts.total ? dashboardCounts.total : 0 }</h4>
                    </div>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_2">
                    <h2>In-process Cases</h2>
                    <div className='flex'>
                      <h4>{dashboardCounts.inProgress ? dashboardCounts.inProgress : 0 }</h4>
                    </div>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_1">
                    <h2>Credit Score</h2>
                    <div className='flex'>
                      <h4>Coming Soon</h4>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>

        </section>
      </section>
    </BorrowerDashboardMain>
  )
}

export default BorrowerDashboard
