import React from 'react'
import Login from '../lender/login/Login'

const LenderMain = () => {
  return (
    <div>
      <Login />
    </div>
  )
}

export default LenderMain
