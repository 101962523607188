import React, { useEffect, useState } from "react";
import logo from "../assets/imgs/logo-white.png";
import home from "../assets/imgs/icosn/home.svg";
import Cases from "../assets/imgs/icosn/Cases.svg";
import Profile from "../assets/imgs/icosn/Profile.svg";
import Query from '../assets/imgs/icosn/Query.svg'

import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [isLoggedIn, setisLoggedIn] = useState(true)
 
  const clear_ID = () => {
    localStorage.removeItem('borroweruserid');
  }
  useEffect(() => {
    const currentURL = window.location.href
    const parts = currentURL.split('/');
    const lastPart = parts[parts.length - 2];
    console.log(lastPart);
    if (lastPart == 'borrower-profile-share') {
     setisLoggedIn(false)
    }
  }, [])


  return (
    <>
      <section className="dashboard_sidebar_main_div">
        <div className="white_logo_div">
          <img src={logo} className="img-fluid white_logo" alt="logo" />
        </div>
        {isLoggedIn ? (
          <>
            <div className="mt_12">
              <nav>
                <NavLink
                  exact
                  to="/borrower-dashboard"
                  className="sidebar_link "
                  activeClassName="active_link"
                >
                  <img
                    src={home}
                    alt="icon"
                    className="img-fluid sidebar_link_icon"
                  />{" "}
                  <span> Dashboard</span>
                </NavLink>
                <NavLink
                  to="/borrower_profile"
                  className="sidebar_link "
                  activeClassName="active"
                >
                  <img
                    src={Profile}
                    alt="icon"
                    className="img-fluid sidebar_link_icon"
                  />{" "}
                  <span> Profile</span>
                </NavLink>
                <NavLink
                  to="/borrower_cases"
                  className="sidebar_link "
                  activeClassName="active_link"
                >
                  <img
                    src={Cases}
                    alt="icon"
                    className="img-fluid sidebar_link_icon"
                  />{" "}
                  <span> Cases</span>
                </NavLink>
                <NavLink
                  to='/borrower-query'
                  className='sidebar_link '
                  activeClassName='active_link'
                >
                  <img
                    src={Query}
                    alt='icon'
                    className='img-fluid sidebar_link_icon'
                  />{' '}
                  <span> Query</span>
                </NavLink>
              </nav>
            </div>
            <div className="signout_btn_div">

            <a target="_blank" href="https://www.loom.com/share/522c3640670342edbd923afb0cd1518d?sid=f5118990-ac00-4e9f-a904-53fc78ba2cbf">
              
                  How to use?
              </a>
              <NavLink to="/" onClick={clear_ID} className="">
                Sign-out
              </NavLink>
            </div></>
        ) : (
       <></>
        )}
      </section>
    </>
  );
};

export default Sidebar;
