export function formatNumberWithPostfix(number) {
    if (number >= 10000000) {
      // Convert to Crore (Cr)
      return (number / 10000000).toFixed(2) + ' Cr';
    } else if (number >= 100000) {
      // Convert to Lakh (L)
      return (number / 100000).toFixed(2) + ' L';
    } else {
      return number?.toString(); // No conversion needed
    }
  }

  export function formatNumberToCr(number) {
    if (number >= 100000) {
      // Convert to Crore (Cr)
      return (number / 10000000).toFixed(2) + ' Cr';
    } else {
      return Math.floor(number).toString(); // No conversion needed
    }
  }