import React, { useState, useEffect } from 'react'
import AdminDashboardMain from './AdminDashboardMain'
import upload from '../assets/imgs/icosn/upload.svg'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import BackBtn from './BackBtn'
import { BiEdit } from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
const LenderProfile = () => {
  const { _id } = useParams()
  // console.log('idd', _id)
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [avatar, setavatar] = useState()
  const [generatedUrl, setGeneratedUrl] = useState(null)
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [selectedLenderId, setSelectedLenderId] = useState(null)

  // profile upload
  const [selectedImage, setSelectedImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const handleImageChange = event => {
    const file = event.target.files[0]
    generateImgUrl(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImage(file)
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  const [profileData, setprofileData] = useState(null)
  const [userData, setUserData] = useState({})
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [password, setPassword] = useState()
  const [commission, setcommission] = useState()


  // getSingleLender
  const UserProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      lenderId: _id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/getSingleLender", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setUserData(result.data)
      })
      .catch(error => console.log('error', error));


  }

  useEffect(() => {
    UserProfile()
    console.log("Lender data ", userData)
  }, [_id])


  // ============profile Update API================

  const LenderProfileUPdate = () => {
    console.log('lender profile update')

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "password": password,
      "name": name,
      "mobile": mobile,
      "commission": commission,
      "avatar": generatedUrl
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateProfile/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.message) {

          toast.success(result.message, {
            autoClose: 2000
          })
          setTimeout(() => {
            navigate("/lenders");
          }, 2000)
        }
      })
      .catch(error => console.log('error', error));
  }


  // Open modal and set selected lender ID
  const openDeleteModal = lenderId => {
    setSelectedLenderId(lenderId)
    handleShow()
    // console.log('hello', lenderId)
  }
  // ============Delete Lender API================
  const DeleteLender = lenderID => {
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    }

    fetch(
      `https://bizfinn.co.in/deleteLeander/${lenderID}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          toast.success(result.msg, {
            autoClose: 2000
          })

          setTimeout(() => {
            navigate('/lenders')
          }, 2000)
        }
      })
      .catch(error => console.log('error', error))
  }

  const generateImgUrl = (image) => {
    var formdata = new FormData();
    formdata.append("file", image);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result) {
          setGeneratedUrl(result && result?.fileUrl)
        }
      })
      .catch(error => console.log('error', error));
  }

  // ========update Profile image API================
  const UpdateProfileImageAPI = () => {
    var formdata = new FormData();
    formdata.append("userId", _id);
    formdata.append("image", selectedImage);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/updateProfileImage", requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return (
    <>
      <AdminDashboardMain>
        <div className=''>
          <div className='profile_bg_main'>
            <section className='side_content_main_box '>
              <div className='page_heading_div'>
                <div className='back_btn_filter_main'>
                  <div className='back_btn_filter_inner'>
                    <BackBtn />
                    <h2>Profile</h2>
                  </div>
                </div>
                <ToastContainer />

                <div className='dashboard_add_new_btn '>
                  {/* <div className=' custom_profile_upload_btn'>
                    <label htmlFor='profile' className='file_input_button'>
                      {selectedImage ? (
                        <div className='file_upload_flex'>
                          <BiEdit className='profile_edit_icon' />
                        </div>
                      ) : (
                        <div className='file_upload_flex'>
                          <img
                            src={upload}
                            alt='upload'
                            className='img-fluid'
                          />
                          <span>Upload Profile Picture</span>
                        </div>
                      )}
                    </label>
                    <input
                      type='file'
                      id='profile'
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {previewImage && (
                      <img
                        className='profile_img_preview'
                        src={previewImage}
                        alt='Preview'
                      />
                    )}
                  </div> */}
                  <div className=' custom_profile_upload_btn'>
                    <label htmlFor='profile' className='file_input_button'>
                      {selectedImage ? (
                        <div className='file_upload_flex'>
                          <BiEdit className='profile_edit_icon' />
                        </div>
                      ) : (
                        <div className='file_upload_flex'>
                          <img
                            src={
                              avatar ??
                              (userData &&
                                userData[0] &&
                                userData[0]?.avatar)
                            }
                            alt='profile'
                            className='img-fluid profile__img'
                          />
                          <span onClick={UpdateProfileImageAPI}>Upload Profile Picture</span>
                        </div>
                      )}
                    </label>
                    <input
                      type='file'
                      id='profile'
                      style={{ display: 'none' }}
                      onChange={handleImageChange}
                    />
                    {previewImage && (
                      <>
                        <img
                          className='profile_img_preview'
                          src={previewImage}
                          alt='Preview'
                        />
                        <span className='profile_text'>Profile Picture</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <section className=' '>
                <div className='Personal_Details_div_main'>
                  <h3>Personal Details </h3>
                  <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-12'>
                      <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6'>
                          <div>
                            <input
                              type='text'
                              className='form_input_box '
                              placeholder='Name'
                              autoCorrect='off'
                              autoComplete='off'
                              value={
                                name ??
                                (userData &&
                                  userData[0] &&
                                  userData[0]?.name)
                              }
                              onChange={e => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6'>
                          <div>
                            <input readOnly
                              type='email'
                              className='form_input_box '
                              placeholder='Email'
                              autoCorrect='off'
                              autoComplete='off'
                              value={
                                email ??
                                (userData &&
                                  userData[0] &&
                                  userData[0]?.email)
                              }
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6'>
                          <div>
                            <input
                              type='tel'
                              className='form_input_box '
                              placeholder='Phone Number'
                              autoCorrect='off'
                              autoComplete='off'
                              value={
                                mobile ??
                                (userData &&
                                  userData[0] &&
                                  userData[0]?.mobile)
                              }
                              onChange={e => setMobile(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6'>
                          <div className="pass_show_hide_btn_pos_rel">
                            <input
                              type={passwordVisible ? 'text' : 'password'}
                              className='form_input_box '
                              placeholder='Password'
                              autoCorrect='off'
                              autoComplete='off'
                              value={
                                password ??
                                (userData &&
                                  userData[0] &&
                                  userData[0]?.password)
                              }
                              onChange={e => setPassword(e.target.value)}
                            />
                            <div onClick={togglePasswordVisibility} className='pass_show_hide_btn' style={{ marginTop: "-10px" }}>
                              {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6'>
                          <div>
                            <input
                              type='number'
                              className='form_input_box '
                              placeholder='Commission in Percentage(%)'
                              autoCorrect='off'
                              autoComplete='off'
                              value={
                                commission ??
                                (userData &&
                                  userData[0] &&
                                  userData[0]?.commission)
                              }
                              onChange={e => setcommission(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-12'>
                      <div className='Update_Share_Details_btns_div' style={{ alignItems: 'flex-start' }}>
                        <Link onClick={LenderProfileUPdate}>Update</Link>

                        <Link onClick={() => openDeleteModal(_id)}>
                          Delete Profile
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
              </section>
            </section>
          </div>
        </div>
      </AdminDashboardMain>

      <Modal show={show} onHide={handleClose} className='modal_main'>
        <Modal.Body className='moadal_body'>
          <p> Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className='table_edit_delete_btn' onClick={handleClose}>
            Close
          </button>
          <button className='table_edit_delete_btn' onClick={() => {
            DeleteLender(selectedLenderId)
            handleClose()
          }}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LenderProfile
