import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import checks from '../assets/imgs/icosn/check.svg'
import cross from '../assets/imgs/icosn/cross.svg'
import { ToastContainer, toast } from 'react-toastify'
import upload from '../assets/imgs/icosn/upload.svg'
import BackBtn from '../admin/BackBtn'
import BorrowerDashboardMain from './BorrowerDashboardMain'
import dayjs from 'dayjs'
import download from '../assets/imgs/icosn/download.png'
import socket from "../Socket";
import { formatNumberWithPostfix } from '../utils/ConvertNumbers'

const CaseDetails = () => {
  const { _id } = useParams()

  const userToken = localStorage.getItem('borroweruserid')
  const [comment, setcomment] = useState('')

  const [GSTLast, setGSTLast] = useState(null)
  const [BankStatement, setBankStatement] = useState(null)
  const [UploadDoc, setUploadDoc] = useState(null)
  const [singleCase, setSingleCase] = useState([])
  const [singleBorrower, setSingleBorrower] = useState([])
  const [borrowerData, setBorrowerData] = useState([])
  const [kycPayload, setKycPayload] = useState({})
  const [fileUrl, setFileUrl] = useState(null)
  const [previousState, setPreviousState] = useState([])
  let fileObj = {}
  const [financial_stat, setfinancial_stat] = useState(null)

  const [validFinacialStat, setValidFinancialStat] = useState(false)
  const [validGstFilling, setValidGstFilling] = useState(false)
  const [validBank24, setValidBank24] = useState(false)
  const [validProvisonalSheet, setValidProvisonalSheet] = useState(false)
  const [validItrAck, setValidItrAck] = useState(false)
  const [validDebtService, setValidDebtService] = useState(false)
  const [extraDocsUpload, setextraDocsUpload] = useState(null)

  // const [kycPayloadFanancial, setKycPayloadFanancial] = useState({})

  const [provisionalbalance, setprovisionalbalance] = useState(null)
  const [ITR, setITR] = useState(null)
  const [Debtserviced, setDebtserviced] = useState(null)
  const [MIS, setMIS] = useState(null)

  const [kycPayloadFanancial, setKycPayloadFanancial] = useState({
    financial_stat: null, // Initialize this field to null
  });


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    var formdata = new FormData();
    formdata.append("file", selectedFile);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        const fileUrl = result?.fileUrl;
        setextraDocsUpload(fileUrl)
      })
      .catch(error => console.log('error', error));
  }



  const [fileName, setFileName] = useState()

  const handleFileUpload = (e, f, type) => {
    const file = e.target.files[0];
    setFileName(file)
    uploadFile(file, type);
  }


  // =======================================
  // ========Get Single case============
  // =======================================
  const getSingleCase = () => {
    fetch(`https://bizfinn.co.in/getSingleCase/${_id}`)
      .then(response => response.json())
      .then(result => {
        setSingleCase(result?.data);
      })
      .catch(error => console.error('Error fetching single case:', error));
  }
  // =======================================
  // ========Get Single Borrower============
  // =======================================
  const getSingleBorrower = () => {
    if (singleCase && singleCase.borrower) {
      const requestBody = {
        borrowerId: singleCase.borrower,
      };

      fetch("https://bizfinn.co.in/getSingleBorrower", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then(response => response.json())
        .then(result => {
          setSingleBorrower(result?.data);
        })
        .catch(error => console.error('Error fetching single borrower:', error));
    }
  }

  useEffect(() => {
    if (_id) {
      getSingleCase();
    }
  }, [_id]);

  useEffect(() => {
    if (singleCase) {
      getSingleBorrower();
    }
  }, [singleCase]);
  // console.log("singleBorrower singleBorrower", singleBorrower)




  const uploadFile = (file, type) => {
    if (!file) {
      console.error('No file selected');
      return;
    }

    var formdata = new FormData();
    formdata.append("userId", userToken);
    formdata.append("file", file);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://bizfinn.co.in/uploadfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        const fileUrl = result?.fileUrl;

        // Update the kycPayloadFanancial with the file URL
        setKycPayloadFanancial(prevState => ({
          ...prevState,
          financial_stat: fileUrl
        }));

        fileObj[type] = fileUrl;
        setPreviousState([...previousState, fileObj]);
      })
      .catch(error => console.log('error', error));
  };



  // update file status


  const FileApprovedREject = (status, Schema, doctype) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "status": 1
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateCaseDocStatus/${_id}/${Schema}/${doctype}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        getSingleCase()
      })
      .catch(error => console.log('error', error));
  }

  // =======================================
  // ==========Financial Details API===========
  // =======================================
  const Financial_Details = (e) => {
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(kycPayloadFanancial);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/updateBorrowerFinancialDetails/${userToken}/${_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })

        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      })
      .catch(error => console.log('error', error));

  }

  const FinancialDetailss = e => {
    // alert()
    e.preventDefault()
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify(kycPayloadFanancial)

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    fetch(
      `https://bizfinn.co.in/updateBorrowerFinancialDetails/${singleCase?.borrower}/${_id}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.message) {
          toast.success(result?.message, {
            autoClose: 2000
          })
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000)
      })
      .catch(error => console.log('error', error))
  }


  const SendBorrowerComment = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "commenterId": userToken,
      "remark": comment,
      "type": "Borrower"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/cases/${_id}/comments`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result?.success) {
          toast.success("comment", {
            autoClose: 2000
          })


        } else {
          toast.error(result?.message, {
            autoClose: 2000
          })
        }
        setTimeout(() => {
          window.location.reload();
        }, 100)
      })
      .catch(error => console.log('error', error));

  }


  // =======================================
  // ==========chat===========
  // =======================================
  const history = useNavigate();
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [BorrowerId, setBorrowerId] = useState("");
  const [admindata, setAdminData] = useState(null);

  useEffect(() => {
    const borrowerId = localStorage.getItem("borroweruserid");
    const adminData = localStorage.getItem("adminId");

    if (borrowerId) {
      setBorrowerId(borrowerId);
    }

    if (adminData) {
      setAdminData(adminData);
    }
  }, []);
  const sendMessage = (contentType, content) => {
    // alert("Message sent borro");

    const newMessage = {
      senderId: BorrowerId,
      receiverId: admindata,
      roomId: "",
    };

    console.log(newMessage);

    //socket.emit("getMessages", newMessage);
    if (newMessage.roomId == "") {
      history(`/borrower-chat/${newMessage.senderId}/${newMessage.receiverId}`);
    } else {
      history(
        `/borrower-chat/${newMessage.roomId}/${newMessage.senderId}/${newMessage.receiverId}`
      );
    }

    // console.log(newMessage.roomId, newMessage.senderId, newMessage.receiverId);
  };


  return (
    <>
      <BorrowerDashboardMain>
        <div className="full_width_bg_main">
          <section className="side_content_main_box">
            <div className="page_heading_div">
              <div className="back_btn_filter_main">
                <BackBtn />
                <h2>Cases</h2>
              </div>
            </div>
            <ToastContainer />

            <section className="">
              <section className="table_main_section">
                <div className="table-responsive">
                  <table className="table   commaon_table">
                    <thead className="table_head">
                      <tr>
                        <th>Case No</th>
                        <th>Date</th>
                        <th>Loan Ask</th>
                        <th>Loan Approved</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {singleCase?.case_no}
                        </td>
                        <td> {dayjs(singleCase?.createdAt).format(
                          ' DD-MM-YYYY'
                        )}</td>
                        <td>{formatNumberWithPostfix(singleCase?.requirement)}</td>
                        <td>{formatNumberWithPostfix(singleCase?.lenders?.reduce((total, currLender) => total + currLender.approved_amount, 0))}</td>
                        <td>{singleCase?.type_of_loan}</td>
                        <td >{singleCase?.status === 0 ? <span className="table_pending_btn">Pending</span> :
                          singleCase?.status === 1 ? <span className="table_pending_btn table_approved_btn">Approved</span> :
                            singleCase?.status === 2 ? <span className="table_pending_btn">	Rejected</span> :
                              singleCase?.status === 3 ? <span className="table_pending_btn table_progress_btn">	Progress</span> : ""}</td>
                        <td>{singleCase?.comment}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>

            <section className="case_detail_content_main_section">
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
                  <div className="profile_details_main_section pt-3">
                    {/* <h3>Financial Details</h3> */}
                    <div>
{/* 
                      {singleCase?.financial_details?.financial_stat?.status === 0 ||
                        singleCase?.financial_details?.gst_filling?.status === 0 ?
                        <>
                          <div className="case_detail_single_detail">
                            <h2>Re-upload these files (Rejected Files).</h2>
                          </div>



                          <form>
                          {singleCase?.financial_details?.financial_stat
                              ?.status === 1 || singleCase?.financial_details?.financial_stat
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div className='custom_file_upload_main'>
                                  <label
                                    htmlFor='AuditedStatement'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.financial_stat ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.financial_stat ??
                                            borrowerData.financial_details.financial_stat.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.financial_stat?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.financial_stat ??
                                                borrowerData?.financial_details?.financial_stat?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.financial_stat?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              Upload Audited Financial statements
                                              for last 3 years
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='AuditedStatement'
                                    style={{ display: 'none' }}
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setfinancial_stat,
                                        'financial_stat'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details?.financial_stat
                                  ?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    {' '}
                                    Audited Financial statements Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details?.gst_filling
                              ?.status === 1 || singleCase?.financial_details?.gst_filling
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div
                                  className='custom_file_upload_main'
                                  style={{
                                    border: validGstFilling
                                      ? '1px solid #f65555'
                                      : ''
                                  }}
                                >
                                  <label
                                    htmlFor='GSTLast'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.gst_filling ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.gst_filling ??
                                            borrowerData.financial_details.gst_filling.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.gst_filling?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.gst_filling ??
                                                borrowerData?.financial_details?.gst_filling?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.gst_filling?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              Upload GST Filing for last 12 months
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='GSTLast'
                                    style={{ display: 'none' }}
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setGSTLast,
                                        'gst_filling'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details?.gst_filling
                                  ?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    {' '}
                                    GST Filing Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details?.bank_statment_24
                              ?.status === 1 || singleCase?.financial_details?.bank_statment_24
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div
                                  className='custom_file_upload_main'
                                  style={{
                                    border: validBank24 ? '1px solid #f65555' : ''
                                  }}
                                >
                                  <label
                                    htmlFor='BankStatement'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.bank_statment_24 ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.bank_statment_24 ??
                                            borrowerData.financial_details.bank_statment_24.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.bank_statment_24?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.bank_statment_24 ??
                                                borrowerData?.financial_details?.bank_statment_24?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.bank_statment_24?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              Upload all Bank Statement of last 24
                                              months
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='BankStatement'
                                    style={{ display: 'none' }}
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setBankStatement,
                                        'bank_statment_24'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details?.bank_statment_24
                                  ?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    {' '}
                                    All Bank Statement are Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details?.provisional_sheet
                              ?.status === 1 || singleCase?.financial_details?.provisional_sheet
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div
                                  className='custom_file_upload_main'
                                  style={{
                                    border: validProvisonalSheet
                                      ? '1px solid #f65555'
                                      : ''
                                  }}
                                >
                                  <label
                                    htmlFor='provisionalbalance'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.provisional_sheet ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.provisional_sheet ??
                                            borrowerData.financial_details.provisional_sheet.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.provisional_sheet?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.provisional_sheet ??
                                                borrowerData?.financial_details?.provisional_sheet?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.provisional_sheet?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              Upload provisional balance sheet for
                                              the current year
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='provisionalbalance'
                                    style={{ display: 'none' }}
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setprovisionalbalance,
                                        'provisional_sheet'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details
                                  ?.provisional_sheet?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    Provisional balance sheet Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details
                              ?.itr_acknowledgement?.status === 1 || singleCase?.financial_details
                              ?.itr_acknowledgement?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div className='custom_file_upload_main'>
                                  <label
                                    htmlFor='ITR'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.itr_acknowledgement ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.itr_acknowledgement ??
                                            borrowerData.financial_details.itr_acknowledgement.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.itr_acknowledgement?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.itr_acknowledgement ??
                                                borrowerData?.financial_details?.itr_acknowledgement?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.itr_acknowledgement?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              Upload ITR acknowledgement form for
                                              last 2 years
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='ITR'
                                    className='hidden-input'
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    required
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setITR,
                                        'itr_acknowledgement'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details
                                  ?.itr_acknowledgement?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    ITR acknowledgement Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details?.debt_service
                              ?.status === 1 || singleCase?.financial_details?.debt_service
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div className='custom_file_upload_main'>
                                  <label
                                    htmlFor='Debtserviced'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.debt_service ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.debt_service ??
                                            borrowerData.financial_details.debt_service.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.debt_service?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.debt_service ??
                                                borrowerData?.financial_details?.debt_service?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.debt_service?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              Upload Debt serviced during the
                                              current FY (Principal + interest)
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='Debtserviced'
                                    className='hidden-input'
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    required
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setDebtserviced,
                                        'debt_service'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details?.debt_service
                                  ?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    Debt serviced Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            {singleCase?.financial_details?.mis_additional
                              ?.status === 1 || singleCase?.financial_details?.mis_additional
                              ?.status === 3 ? (
                              ''
                            ) : (
                              <>
                                <div className='custom_file_upload_main'>
                                  <label
                                    htmlFor='MIS'
                                    className='file-input-button'
                                  >
                                    {kycPayloadFanancial.mis_additional ? (
                                      <div className='file_upload_flex'>
                                        <span>
                                          {' '}
                                          {kycPayloadFanancial.mis_additional ??
                                            borrowerData.financial_details.mis_additional.url
                                              .split('/')
                                              .pop()}{' '}
                                        </span>
                                        <img
                                          src={upload}
                                          alt='upload'
                                          className='img-fluid'
                                        />
                                      </div>
                                    ) : (
                                      <div className='file_upload_flex'>
                                        {borrowerData?.financial_details
                                          ?.mis_additional?.url ? (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              {kycPayloadFanancial.mis_additional ??
                                                borrowerData?.financial_details?.mis_additional?.url
                                                  .split('/')
                                                  .pop()}
                                            </span>
                                            <a
                                              href={
                                                borrowerData?.financial_details
                                                  ?.mis_additional?.url
                                              }
                                              download='file_name.extension'
                                              target='_blank'
                                            >
                                              {' '}
                                              <img
                                                src={download}
                                                alt='upload'
                                                className='img-fluid download_img'
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          <div className='file_upload_flex'>
                                            <span>
                                              {' '}
                                              Upload MIS and additional Documents
                                            </span>
                                            <img
                                              src={upload}
                                              alt='upload'
                                              className='img-fluid'
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </label>
                                  <input
                                    type='file'
                                    id='MIS'
                                    className='hidden-input'
                                    accept='.pdf, .jpg, .jpeg, .png, application/vnd.ms-excel'
                                    onChange={e =>
                                      handleFileUpload(
                                        e,
                                        setMIS,
                                        'mis_additional'
                                      )
                                    }
                                  />
                                </div>
                                {borrowerData?.financial_details?.mis_additional
                                  ?.url ? (
                                  <h5 className='after_file_uploaded'>
                                    Upload MIS and additional Documents Uploaded
                                  </h5>
                                ) : (
                                  ''
                                )}
                              </>
                            )}

                            <div className='dashboard_add_new_btn mb-5'>
                              <button
                                className=''
                                type='submit'
                                onClick={FinancialDetailss}
                              >
                                Submit
                              </button>
                            </div>
                          </form>

                        </>
                        : ''
                      } */}
                      {/* UploadDoc */}
                      {/* <div className="finanicial_details_single Upload_Doc_input">
                        <div className="custom_file_upload_main">
                          <label
                            htmlFor="UploadDoc"
                            className="file-input-button"
                          >
                            {!!extraDocsUpload ? (
                              <div className="file_upload_flex">
                                <span> {extraDocsUpload}</span>
                                <img
                                  src={upload}
                                  alt="upload"
                                  className="img-fluid"
                                />
                              </div>
                            ) : (
                              <div className="file_upload_flex">
                                <span>Upload Doc</span>
                                <img
                                  src={upload}
                                  alt="upload"
                                  className="img-fluid"
                                />
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="UploadDoc"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                      </div> */}

                      <div className="case_detail_single_detail">
                        <h2>The Password for Locked Documents</h2>
                        <p>{singleCase?.doc_passwords}</p>
                      </div>

                      <div className="case_detail_single_detail">
                        <h2>I am looking for</h2>
                        <p>{singleCase?.type_of_loan}</p>
                      </div>

                      <div className="case_detail_single_detail">
                        <h2>Status : <span style={{color:'green'}}>Sent to admin</span> </h2>
                      </div>

{/*      
                      <div className='query_details_steps_main'>
                        {singleCase?.comments?.slice()
                          .reverse().map((currEle) => {
                            return (
                              <div className='query_details_steps_single'>
                                <div className='steps_dots_main steps_dot_active'>
                                  <span></span>
                                </div>
                                <div className='query_details_steps_inner_main'>
                                  <div>
                                    <h4>{currEle?.type}</h4>
                                    <h5>{currEle?.remark}</h5>
                                  </div>
                                  <p>{dayjs(currEle?.createdAt).format(
                                    ' DD-MM-YYYY'
                                  )}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> */}


                      {/* borrower Comment */}
                      {/* <h4>Borrower Comment</h4>
                      <div className="finanicial_details_single">
                        <textarea
                          rows="4"
                          cols="50"
                          className="form_input_box Enter_Comment"
                          placeholder="Enter Comment"
                          value={comment}
                          onChange={e => setcomment(e.target.value)}
                        ></textarea>
                      </div> */}

                      {/* <div className="dashboard_add_new_btn mt-4 ">
                        <div className='login_register_btn_div'>
                          <button className="" onClick={SendBorrowerComment}>
                            Send
                          </button>
                          <button
                            onClick={sendMessage}
                            className='login_register_btn register_btn simple_btn_hover'
                          >
                            Message
                          </button>
                        </div>/
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </BorrowerDashboardMain>
    </>
  )
}

export default CaseDetails
