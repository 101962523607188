import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LenderDashboardMain from './LenderDashboardMain'
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Filter from '../admin/Filter'
import BackBtn from '../admin/BackBtn'
import Loader from '../admin/Loader'
import dayjs from 'dayjs'
import 'bootstrap-daterangepicker/daterangepicker.css'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { formatNumberWithPostfix } from '../utils/ConvertNumbers'

const Cases = () => {
  const navigate = useNavigate()
  const lenderuserid = localStorage.getItem('lenderuserid')

  const [isLoading, setIsLoading] = useState(false)
  const [listOfcases, setListOfCases] = useState([])
  const [singleborrower, setsingleborrower] = useState([])
  const [pendingDataCount, setpendingDataCount] = useState(false)
  const [approvedDataCount, setapprovedDataCount] = useState(false)
  const [progressDataCount, setprogressDataCount] = useState(false)
  const [rejectedDataCount, setrejectedDataCount] = useState(false)

  // const ListOfCases = () => {
  //   var requestOptions = {
  //     method: 'GET',
  //     redirect: 'follow'
  //   };

  //   fetch("https://bizfinn.co.in/getCases", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result)
  //       setListOfCases(result?.data)

  //     })
  //     .catch(error => console.log('error', error));
  // }

  const ListOfCases = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`https://bizfinn.co.in/lenderCases/${lenderuserid}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setListOfCases(result?.data)
        isPendingCaseAvailable(result.data)
        isApprovedCase(result.data)
        isProgressCase(result.data)
        isRejectedCase(result.data)
      })
      .catch(error => console.log('error', error));
  }

  const isPendingCaseAvailable = (cases) => {

    let boolValue = cases.some(item =>
      item.lenders.some(lender =>
        lender.lander_approved === 0 && lender.approved === 0 && lender.lenderId === lenderuserid
      )
    )
    console.log(boolValue);
    setpendingDataCount(boolValue)
  }

  const isApprovedCase = (cases) => {
    let boolValue = cases.some(item =>
      item.lenders.some(lender =>
        lender.approved == 1 && lender.lander_approved == 1 && lender.lenderId == lenderuserid
      )
    )
    console.log(boolValue);
    setapprovedDataCount(boolValue)
  }

  const isProgressCase = (cases) => {
    let boolValue = cases.some(item =>
      item.lenders.some(lender =>
        lender.approved == 0 && lender.lander_approved == 1 && lender.lenderId == lenderuserid
      )
    )
    console.log(boolValue);
    setprogressDataCount(boolValue)
  }

  const isRejectedCase = (cases) => {
    let boolValue = cases.some(item =>
      item.lenders.some(lender =>
        lender.approved == 3 && lender.lander_approved == 1 && lender.lenderId == lenderuserid
      )
    )
    console.log(boolValue);
    setrejectedDataCount(boolValue)
  }

  useEffect(() => {
    ListOfCases()
    console.log("listOfcases", listOfcases)
  }, []);




  return (
    <>
      <LenderDashboardMain>
        <section className="side_content_main_box">
          <div className="page_heading_div">
            <div className="back_btn_filter_main">
              <div className="back_btn_filter_inner">
                <BackBtn />
                <h2>Cases</h2>
              </div>
              <div className="date_filter_box">
                <DateRangePicker>
                  <input type="text" className="form-control" />
                </DateRangePicker>

              </div>
            </div>
            <div className="dashboard_add_new_btn">
              <Link className="" to="/lender_add_cases">
                Add Cases
              </Link>
            </div>
          </div>

          <section className="tabs_main_section commaon_tabs_main_section">
            <Tabs
              defaultActiveKey="ReceivedList"
              id="uncontrolled-tab-example"
              className="commaon_tabs_main"
            >
              <Tab eventKey="ReceivedList" title="List of Received">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}

                        {listOfcases.map((currEle, index) => {
                          return <>
                            {currEle.lenders.map((lender, index) => {
                              return <>
                                {lender.lander_approved == 0 && lender.approved == 0 && lender.lenderId == lenderuserid ? (
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}>{currEle?.borrowerName}</Link>
                                    </td>
                                    <td>{currEle?.type_of_loan}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                    <td>{formatNumberWithPostfix(lender.approved_amount)} </td>
                                    <td>
                                      <span className="table_pending_btn ">Pending</span>
                                    </td>
                                    <td>
                                      {currEle?.lenders.length}
                                    </td>
                                    <td>{lender.lender_remark}</td>
                                  </tr>
                                ) : ('')}
                              </>
                            })}

                          </>
                        })}
                        {!pendingDataCount ? (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        ) : ('')}

                        {/* {listOfcases?.filter(currEle => currEle?.status === 0).length > 0 ? (
                          listOfcases
                            ?.filter(currEle => currEle?.status === 0)
                            .slice()
                            .reverse()
                            .map(currEle => {
                              return (
                                <>
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}>{currEle?.borrowerName}</Link>
                                    </td>
                                    <td>{currEle?.business_structure}</td>
                                    <td>{currEle?.borrowerTurnOver}</td>
                                    <td>
                                      <span className="table_pending_btn table_progress_btn">Pending</span>
                                    </td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)}</td>
                                  </tr>
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )} */}



                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="ApprovedList" title="List of Approved">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}

                        {listOfcases.map((currEle, index) => {
                          return <>
                            {currEle.lenders.map((lender, index) => {
                              return <>
                                {lender.approved == 1 && lender.lander_approved == 1 && lender.lenderId == lenderuserid ? (
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}>{currEle?.borrowerName}</Link>
                                    </td>
                                    <td>{currEle?.type_of_loan}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                    <td>{formatNumberWithPostfix(lender.approved_amount)} </td>
                                    <td>
                                      <span className="table_pending_btn table_approved_btn">
                                        Approved
                                      </span>
                                    </td>
                                    <td>
                                      {currEle?.lenders.length}
                                    </td>
                                    <td>{lender.lender_remark}</td>
                                  </tr>
                                ) : (<></>)}
                              </>
                            })}

                          </>
                        })}
                        {!approvedDataCount ? (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        ) : ('')}

                        {/* {listOfcases
                          ?.filter(currEle => currEle.lenders.filter(elem => elem.approved == 1 && elem.lander_approved == 1 && elem.lenderId == lenderuserid)).length > 0 ? (
                          listOfcases
                            ?.filter(currEle => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map(currEle => {
                              return (
                                <>
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                    </td>
                                    <td>{currEle?.business_structure}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>
                                      <span className="table_pending_btn table_approved_btn">
                                        Approved
                                      </span>
                                    </td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  </tr>
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )} */}

                        {/* {listOfcases
                          ?.filter(currEle => currEle?.status === 1)
                          .slice()
                          .reverse()
                          .map(currEle => {
                            return (
                              <> */}
                        {/* {listOfcases?.filter(currEle => currEle?.status === 1).length > 0 ? (
                          listOfcases
                            ?.filter(currEle => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map(currEle => {
                              return (
                                <>
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                    </td>
                                    <td>{currEle?.business_structure}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>
                                      <span className="table_pending_btn table_approved_btn">
                                        Approved
                                      </span>
                                    </td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  </tr>
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )} */}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="RejectedList" title="List of Rejected">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}

                        {listOfcases.map((currEle, index) => {
                          return <>
                            {currEle.lenders.map((lender, index) => {
                              return <>
                                {lender.approved == 3 && lender.lander_approved == 1 && lender.lenderId == lenderuserid ? (
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}>{currEle?.borrowerName}</Link>
                                    </td>
                                    <td>{currEle?.type_of_loan}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                    <td>{formatNumberWithPostfix(lender.approved_amount)} </td>
                                    <td>
                                      <span className="table_pending_btn ">
                                        Rejected
                                      </span>
                                    </td>
                                    <td>
                                      {currEle?.lenders.length}
                                    </td>
                                    <td>{lender.lender_remark}</td>
                                  </tr>
                                ) : (<></>)}
                              </>
                            })}

                          </>
                        })}
                        {!rejectedDataCount ? (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        ) : ('')}

                        {/* {listOfcases
                          ?.filter(currEle => currEle.lenders.filter(elem => elem.approved == 3 && elem.lander_approved == 1 && elem.lenderId == lenderuserid)).length > 0 ? (
                          listOfcases
                            ?.filter(currEle => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map(currEle => {
                              return (
                                <>

                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                    </td>
                                    <td>{currEle?.business_structure}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>
                                      <span className="table_pending_btn ">
                                        Rejected
                                      </span>
                                    </td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  </tr>
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )} */}

                      </tbody>
                    </table>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="ProgressList" title="List of In Progress">
                <section className="table_main_section">
                  <div className="table-responsive">
                    <table className="table   commaon_table">
                      <thead className="table_head">
                        <tr>
                          <th scope="col">Case No</th>
                          <th scope="col">Date</th>
                          <th scope="col">Borrower Name</th>
                          <th scope="col">Type of Loan</th>
                          <th scope="col">Turnover</th>
                          <th scope="col">Loan Ask</th>
                          <th scope="col">Loan Approve</th>
                          <th scope="col">Status</th>
                          <th scope="col">Select Lender</th>
                          <th scope="col">Lender Remark</th>
                        </tr>
                      </thead>


                      <tbody>
                        {isLoading && (
                          <div className='pos_abs'>
                            <Loader />
                          </div>
                        )}

                        {listOfcases.map((currEle, index) => {
                          return <>
                            {currEle.lenders.map((lender, index) => {
                              return <>
                                {lender.approved == 0 && lender.lander_approved == 1 && lender.lenderId == lenderuserid ? (
                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}>{currEle?.case_no}</Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}>{currEle?.borrowerName}</Link>
                                    </td>
                                    <td>{currEle?.type_of_loan}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                    <td>{formatNumberWithPostfix(lender.approved_amount)} </td>
                                    <td>
                                      <span className="table_pending_btn table_progress_btn">
                                        Progress
                                      </span>
                                    </td>
                                    <td>
                                      {currEle?.lenders.length}
                                    </td>
                                    <td>{lender.lender_remark}</td>
                                  </tr>
                                ) : (<></>)}
                              </>
                            })}

                          </>
                        })}
                        {!progressDataCount ? (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        ) : ('')}

                        {/* {listOfcases
                          ?.filter(currEle => currEle.lenders.filter(elem => elem.approved == 0 && elem.lander_approved == 1 && elem.lenderId == lenderuserid)).length > 0 ? (
                          listOfcases
                            ?.filter(currEle => currEle?.status === 1)
                            .slice()
                            .reverse()
                            .map(currEle => {
                              return (
                                <>

                                  <tr key={currEle?._id}>
                                    <td className="name">
                                      <Link to={`/lender_cases_details/${currEle?._id}`}> {currEle?.case_no} </Link>
                                    </td>
                                    <td>{dayjs(currEle?.createdAt).format('DD-MM-YYYY')}</td>
                                    <td className="name">
                                      <Link to={`/borrower__profile/${currEle?.borrower}`}> {currEle?.borrowerName} </Link>
                                    </td>
                                    <td>{currEle?.business_structure}</td>
                                    <td> {currEle?.borrowerTurnOver}</td>
                                    <td>
                                      <span className="table_pending_btn table_progress_btn">
                                        Progress
                                      </span>
                                    </td>
                                    <td>{formatNumberWithPostfix(currEle?.requirement)} </td>
                                  </tr>
                                </>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )} */}

                      </tbody>

                    </table>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </section>
        </section>
      </LenderDashboardMain>
    </>
  )
}

export default Cases
