import React from 'react'
import Lottie from 'react-lottie';
import loader from '../assets/imgs/loader.json';

const Loader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
        </>
    )
}

export default Loader